/* eslint-disable no-unused-vars */
import React, { useCallback, useContext } from 'react';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { UserFilterRoleEnum } from 'product-types/src/domain/user/UserFilterRole';
import { Flex } from 'antd';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import { UserFilterValueModel } from '../../../types/filters/AtomicFiltersImplementation/UserFilter/UserFilterValueModel';
import { UserFilter as UserFilterModel } from '../../../types/filters/AtomicFiltersImplementation/UserFilter/UserFilter';
import { Filter } from '../../../types/filters/AtomicFilters/Filter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import { SearchPerson } from './SearchPerson';
import { RoleSelect } from './RoleSelect';
import { UserFilterPreview } from './SuggestionElements';
import './style.css';

export interface UserFilterProps {
  value: UserFilterModel;
  onChange: (v: Filter) => void;
}

export const UserFilter = (props: UserFilterProps) => {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  let initialRole = UserFilterRoleEnum.commenter;
  if (props.value.value?.users.length) {
    initialRole =
      props.value.value.users[props.value.value.users.length - 1].role;
  }
  const [role, setRole] = React.useState<UserFilterRoleEnum>(initialRole);
  const [user, setUser] = React.useState<UserLightModel | null>(null);

  const selectUser = useCallback(
    (us: UserLightModel) => {
      if (
        props.value.value.users.find(
          (u) => u.user.id === us.id && u.role === role,
        )
      ) {
        return;
      }
      setUser(user);
      props.onChange(
        new UserFilterModel({
          ...props.value,
          value: props.value.value.addUser(
            new UserFilterValueModel({
              user: us,
              role,
            }),
          ),
        }),
      );
      setUser(() => null);
    },
    [props.value, role, user],
  );

  const onRemove = useCallback(
    (us: UserFilterValueModel) => {
      props.onChange(
        new UserFilterModel({
          ...props.value,
          value: props.value.value.removeUser(us),
        }),
      );
    },
    [props.value],
  );

  const optionRemovalCriteria = useCallback(
    (opt) =>
      !props.value.value.users.find(
        (u) => u.user.id === opt.id && u.role === role,
      ),
    [props.value, role],
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          width: 400,
          padding: '1rem 1.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <Flex>
          <RoleSelect
            options={props.value.value.options}
            value={role}
            onChange={setRole}
          />
          {context.users && (
            <SearchPerson
              style={{ flex: 1, borderRadius: '50px' }}
              value={props.value.value.users}
              onSelect={selectUser}
              users={context.users || []}
              optionRemovalCriteria={optionRemovalCriteria}
            />
          )}
        </Flex>
        <div
          data-testid="user-preview-list"
          style={{
            maxHeight: '200px',
            overflowY: 'auto',
            display: props.value.value.users.length ? 'block' : 'none',
          }}
        >
          {props.value.value.users.map((us) => (
            <UserFilterPreview key={us.user.id} user={us} onRemove={onRemove} />
          ))}
        </div>
      </Flex>
    ),
    [context.users, props.value.value, role, user],
  );
  return (
    <FilterWithMenuWrapper
      text="Users"
      count={props.value.displayingFilterValue.length}
      dataTestId="role-users"
      renderer={renderer}
    />
  );
};
