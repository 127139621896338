/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { memo, ReactElement, useCallback, useState } from 'react';
import { Select } from 'product-ui/src/components/atoms/Select/Select';
import { AsyncSelect } from 'product-ui/src/components/atoms/AsyncSelect';
import { Flex, SelectProps } from 'antd';
import NaveeIcon from 'ui/src/components/atoms/NaveeIcon/NaveeIcon';
import styled from 'styled-components';
import { OptionWithCount } from './OptionWithCount';

const AsyncSelectStyled = styled(AsyncSelect)<{
  showSelectAll?: boolean;
}>`
  .ant-select-selection-overflow {
    max-width: ${(props) => (props.showSelectAll ? '90%' : '100%')} !important;
  }

  .ant-select-arrow {
    pointer-events: initial !important;
  }
`;

type Props = {
  hasGroup?: boolean;
  concat?: boolean;
  async?: boolean;
  isMulti?: boolean;
  showSelectAll?: boolean;
  debounceTime?: number;
  selected_website_sider: Array<any>;
  websitesSiderData: Array<any>;
  placeholder: string;
  hint?: ReactElement | null;
  fieldNames?: SelectProps['fieldNames'];
  noOptionsMessage?:
    | string
    | ((search: string) => ReactElement | string)
    | ReactElement;
  onChange: (newOptions: Array<any>, newValue: Array<any>) => void;
  onSelectAll: (search: string) => void;
  onDropdownVisibleChange: (open: boolean) => void;
  loadOptions: (search: string) => Promise<Array<any>>;
  filterOption?: boolean;
  filterSort?: (a: any, b: any) => number;
  maxTagCount: number | 'responsive' | undefined;
  maxTagPlaceholder: SelectProps['maxTagPlaceholder'];
};

function WebsiteFilter({
  hasGroup = false,
  noOptionsMessage,
  selected_website_sider,
  websitesSiderData = [],
  onChange,
  placeholder,
  concat = true,
  filterSort = undefined,
  async = false,
  loadOptions = () => Promise.resolve([]),
  isMulti = true,
  showSelectAll = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSelectAll = (search) => {},
  debounceTime = 700,
  fieldNames = { label: 'label', value: 'value' },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDropdownVisibleChange = (open) => {},
  hint = null,
  filterOption = true,
  maxTagCount = undefined,
  maxTagPlaceholder = undefined,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  let options = websitesSiderData;

  if (concat) {
    options = websitesSiderData.length
      ? websitesSiderData[0].options.concat(websitesSiderData[1].options)
      : [];
  }

  let selectedWebsites = selected_website_sider;
  if (fieldNames.value !== 'value') {
    selectedWebsites = options.filter((option) =>
      selected_website_sider.some(
        (o) => o[fieldNames.value!] === option[fieldNames.value!],
      ),
    );
  } else {
    selectedWebsites = options.filter((option) =>
      selected_website_sider
        .map((o) => o[fieldNames.value!])
        .includes(option[fieldNames.value!]),
    );
  }

  const [open, setOpen] = useState(false);
  const toggleDropdown = () => {
    setOpen((prevState) => !prevState);
  };

  const onDropdownOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    onDropdownVisibleChange(isOpen);
  };

  const Component = async ? AsyncSelectStyled : Select;

  const suffixIcon = (
    <Flex gap="0.5rem">
      {showSelectAll ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelectAll(inputValue);
          }}
          title="You can add 30 elements at once and maximum 100"
          style={{
            position: 'absolute',
            width: '70px',
            visibility:
              isFocused && inputValue.length >= 3 ? 'visible' : 'hidden',
            transition: 'visibility 0.2s',
            top: 0,
            right: '1rem',
            color:
              (selected_website_sider || []).length < 100
                ? 'var(--link-default)'
                : 'var(--grey-grey-500)',
            cursor:
              (selected_website_sider || []).length < 100
                ? 'pointer'
                : 'forbidden',
            zIndex: 100,
          }}
        >
          Select all
        </div>
      ) : null}
      <NaveeIcon.ChevronDown onClick={toggleDropdown} width={20} height={20} />
    </Flex>
  );

  let filteredOptions = websitesSiderData;
  if (hasGroup) {
    filteredOptions = filteredOptions.map((group) => ({
      ...group,
      options: group.options
        .filter(
          (opt) =>
            !selectedWebsites.find(
              (o) => o[fieldNames.value!] === opt[fieldNames.value!],
            ),
        )
        .filter((opt) => {
          const normalizedSearch = inputValue.toLowerCase().trim();
          if (normalizedSearch) {
            return opt[fieldNames.label!]
              .toLowerCase()
              .includes(normalizedSearch);
          }
          return true;
        }),
    }));
  }

  const handleChange = useCallback(
    (newValue, newOptions) => {
      if (hasGroup && !async) {
        const allOptions = websitesSiderData.flatMap((o) => o.options);
        const result = allOptions.filter((o) =>
          newValue.includes(o[fieldNames.value!]),
        );
        onChange(result, newValue);
        return;
      }
      onChange(newOptions, newValue);
    },
    [hasGroup, async, websitesSiderData, fieldNames, onChange],
  );

  return (
    <Component
      open={open}
      maxCount={1000}
      filterOption={filterOption ?? true}
      minAllowedTextLength={0}
      showSelectAll={showSelectAll}
      mode={isMulti ? 'multiple' : undefined}
      style={{ width: '100%' }}
      searchValue={async ? undefined : inputValue}
      onSearch={(query) => setInputValue(query)}
      value={selectedWebsites}
      fieldNames={fieldNames}
      suffixIcon={suffixIcon}
      allowClear={false}
      placeholder={placeholder ?? ''}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      filterSort={filterSort}
      options={async ? undefined : filteredOptions}
      optionRender={(option) => (
        <OptionWithCount label={option.label} count={option.count} />
      )}
      loadOptions={loadOptions}
      notFoundContent={noOptionsMessage}
      onChange={handleChange}
      debounceTime={debounceTime}
      onDropdownVisibleChange={onDropdownOpenChange}
      hint={async ? hint : undefined}
      removeInsertedFromOptions
      maxTagCount={maxTagCount}
      maxTagPlaceholder={maxTagPlaceholder}
    />
  );
}

export default memo(WebsiteFilter);
