import { ModerationReason } from 'product-types/src/domain/moderationReason/ModerationReason';
import { makeArrayUniqueByValue } from 'product-utils/src/array';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export enum FeatureLabelTypes {
  Null = 'Null',
  None = 'none',
  Counterfeit = 'counterfeit',
  NonCounterfeit = 'non_counterfeit',
  All = 'any',
}

export const FeatureLabelTypesOptions = [
  { label: 'None', value: FeatureLabelTypes.None },
];

export const FeatureLabelTypesOptionsLabels = [
  { label: 'None', value: FeatureLabelTypes.None },
  { label: 'All Features', value: FeatureLabelTypes.All },
];

export const GetFeatureTypeFilterOptionByValue = (value) => {
  const currentFeatureLabelType = Object.entries(FeatureLabelTypes).find(
    ([, val]) => val === value,
  );
  if (!currentFeatureLabelType) {
    throw new Error(`FeatureLabelType ${value} is not supported`);
  }
  return FeatureLabelTypes[currentFeatureLabelType[1] as FeatureLabelTypes];
};

export interface readFilterFromQueryProps {
  reasons: Array<ModerationReason>;
}

export class FeatureLabelValue implements FilterValue {
  labelTypeImage: FeatureLabelTypes;

  labelTypePost: FeatureLabelTypes;

  imageReasons: Array<ModerationReason>;

  postReasons: Array<ModerationReason>;

  _imageReasonIds: Set<number>;

  _postReasonIds: Set<number>;

  constructor(
    params: Pick<
      FeatureLabelValue,
      'imageReasons' | 'labelTypeImage' | 'labelTypePost' | 'postReasons'
    >,
  ) {
    this.imageReasons = params.imageReasons;
    this.postReasons = params.postReasons;
    this.labelTypeImage = params.labelTypeImage || FeatureLabelTypes.Null;
    this.labelTypePost = params.labelTypePost || FeatureLabelTypes.Null;
    this._imageReasonIds = new Set(
      this.imageReasons.map((reason) => reason.moderationReasonId),
    );
    this._postReasonIds = new Set(
      this.postReasons.map((reason) => reason.moderationReasonId),
    );
  }

  static get defaultValue(): FeatureLabelValue {
    return new FeatureLabelValue({
      imageReasons: new Array<ModerationReason>(),
      postReasons: new Array<ModerationReason>(),
      labelTypeImage: FeatureLabelTypes.Null,
      labelTypePost: FeatureLabelTypes.Null,
    });
  }

  setImageLabelType(labelTypeImage: FeatureLabelTypes) {
    return new FeatureLabelValue({
      imageReasons: [],
      labelTypeImage,
      labelTypePost: this.labelTypePost,
      postReasons: this.postReasons,
    });
  }

  addImageReason(
    reason: ModerationReason,
    labelTypeImage = FeatureLabelTypes.Null,
  ) {
    return new FeatureLabelValue({
      imageReasons: this.imageReasons.concat(reason),
      labelTypeImage,
      labelTypePost: this.labelTypePost,
      postReasons: this.postReasons,
    });
  }

  setImageReason(
    reasons: Array<ModerationReason>,
    labelTypeImage = FeatureLabelTypes.Null,
  ) {
    return new FeatureLabelValue({
      imageReasons: reasons,
      labelTypeImage,
      labelTypePost: this.labelTypePost,
      postReasons: this.postReasons,
    });
  }

  removeImageReason(
    reason: ModerationReason,
    labelTypeImage = FeatureLabelTypes.Null,
  ) {
    return new FeatureLabelValue({
      imageReasons: this.imageReasons.filter(
        (r) => r.moderationReasonId !== reason.moderationReasonId,
      ),
      labelTypeImage,
      labelTypePost: this.labelTypePost,
      postReasons: this.postReasons,
    });
  }

  hasImageReason(reason: ModerationReason) {
    return this._imageReasonIds.has(reason.moderationReasonId);
  }

  setPostLabelType(labelTypePost: FeatureLabelTypes) {
    return new FeatureLabelValue({
      imageReasons: this.imageReasons,
      labelTypeImage: this.labelTypeImage,
      labelTypePost,
      postReasons: [],
    });
  }

  addPostReason(
    reason: ModerationReason,
    labelTypePost = FeatureLabelTypes.Null,
  ) {
    return new FeatureLabelValue({
      imageReasons: this.imageReasons,
      labelTypeImage: this.labelTypeImage,
      labelTypePost,
      postReasons: this.postReasons.concat(reason),
    });
  }

  setPostReason(
    reasons: Array<ModerationReason>,
    labelTypePost = FeatureLabelTypes.Null,
  ) {
    return new FeatureLabelValue({
      imageReasons: this.imageReasons,
      labelTypePost,
      labelTypeImage: this.labelTypeImage,
      postReasons: reasons,
    });
  }

  removePostReason(
    reason: ModerationReason,
    labelTypePost = FeatureLabelTypes.Null,
  ) {
    return new FeatureLabelValue({
      imageReasons: this.imageReasons,
      labelTypeImage: this.labelTypeImage,
      labelTypePost,
      postReasons: this.postReasons.filter(
        (r) => r.moderationReasonId !== reason.moderationReasonId,
      ),
    });
  }

  hasPostReason(reason: ModerationReason) {
    return this._postReasonIds.has(reason.moderationReasonId);
  }

  static readFilterFromQuery(
    props?: readFilterFromQueryProps,
  ): FeatureLabelValue {
    const urlParams = new URLSearchParams(window.location.search);
    const postModeationReasons = makeArrayUniqueByValue(
      urlParams.getAll('post_moderation_reasons') || [],
    );
    const imageModeationReasons = makeArrayUniqueByValue(
      urlParams.getAll('image_moderation_reasons') || [],
    );
    let resultFilter = FeatureLabelValue.defaultValue;

    if (
      postModeationReasons.length === 0 &&
      imageModeationReasons.length === 0
    ) {
      return FeatureLabelValue.defaultValue;
    }

    if (
      imageModeationReasons.length === 1 &&
      Object.values(FeatureLabelTypes).includes(imageModeationReasons[0])
    ) {
      resultFilter = resultFilter.setImageLabelType(imageModeationReasons[0]);
    } else {
      resultFilter = resultFilter.setImageReason(
        imageModeationReasons
          .map((reasonId) => parseInt(reasonId, 10))
          .filter((reasonId) => !Number.isNaN(reasonId))
          .map((reasonId) =>
            (props?.reasons || []).find(
              (reason) => reason.moderationReasonId === reasonId,
            ),
          )
          .filter((reason) => reason !== undefined) as Array<ModerationReason>,
        FeatureLabelTypes.Null,
      );
    }
    if (
      postModeationReasons.length === 1 &&
      Object.values(FeatureLabelTypes).includes(postModeationReasons[0])
    ) {
      resultFilter = resultFilter.setPostLabelType(postModeationReasons[0]);
    } else {
      resultFilter = resultFilter.setPostReason(
        postModeationReasons
          .map((reasonId) => parseInt(reasonId, 10))
          .filter((reasonId) => !Number.isNaN(reasonId))
          .map((reasonId) =>
            (props?.reasons || []).find(
              (reason) => reason.moderationReasonId === reasonId,
            ),
          )
          .filter((reason) => reason !== undefined) as Array<ModerationReason>,
        FeatureLabelTypes.Null,
      );
    }

    return resultFilter;
  }

  static readFromSavedFilter(
    savedFilter: any,
    props: readFilterFromQueryProps,
  ): FeatureLabelValue {
    const postModeationReasons = makeArrayUniqueByValue(
      savedFilter.post_moderation_reasons || [],
    );
    const imageModeationReasons = makeArrayUniqueByValue(
      savedFilter.image_moderation_reasons || [],
    );
    let resultFilter = FeatureLabelValue.defaultValue;

    if (
      postModeationReasons.length === 0 &&
      imageModeationReasons.length === 0
    ) {
      return FeatureLabelValue.defaultValue;
    }

    if (
      imageModeationReasons.length === 1 &&
      Object.values(FeatureLabelTypes).includes(imageModeationReasons[0])
    ) {
      resultFilter = resultFilter.setImageLabelType(imageModeationReasons[0]);
    } else {
      resultFilter = resultFilter.setImageReason(
        imageModeationReasons
          .map((reasonId) => parseInt(reasonId, 10))
          .filter((reasonId) => !Number.isNaN(reasonId))
          .map((reasonId) =>
            (props?.reasons || []).find(
              (reason) => reason.moderationReasonId === reasonId,
            ),
          )
          .filter((reason) => reason !== undefined) as Array<ModerationReason>,
        FeatureLabelTypes.Null,
      );
    }
    if (
      postModeationReasons.length === 1 &&
      Object.values(FeatureLabelTypes).includes(postModeationReasons[0])
    ) {
      resultFilter = resultFilter.setPostLabelType(postModeationReasons[0]);
    } else {
      resultFilter = resultFilter.setPostReason(
        postModeationReasons
          .map((reasonId) => parseInt(reasonId, 10))
          .filter((reasonId) => !Number.isNaN(reasonId))
          .map((reasonId) =>
            (props?.reasons || []).find(
              (reason) => reason.moderationReasonId === reasonId,
            ),
          )
          .filter((reason) => reason !== undefined) as Array<ModerationReason>,
        FeatureLabelTypes.Null,
      );
    }

    return resultFilter;
  }
}
