import React, { forwardRef } from 'react';
import { InputProps } from 'antd/lib/input';
import { Input as AntdInput, InputRef } from 'antd';
import AntdTextArea, {
  TextAreaRef,
  TextAreaProps,
} from 'antd/lib/input/TextArea';

export const Input = forwardRef<InputRef, InputProps>((props, ref) => (
  <AntdInput ref={ref} {...props} />
));

export const TextArea = forwardRef<TextAreaRef, TextAreaProps>((props, ref) => (
  <AntdTextArea ref={ref} {...props} />
));
