import React from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import NaveeIcon from 'ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';

export const NewEstimatedGeoFilterValue: DisplayFilterComponent = ({
  filterValue: { uuid, name, value },
  onClickRemove,
}: DisplayFilterComponentProps) => (
  <Tag
    key={`${uuid}|${value}`}
    tag={{
      id: (value as Domain.Geographic.Geographic).value,
      name: (value as Domain.Geographic.Geographic).label,
    }}
    iconPrefix={<NaveeIcon.WorldWww width={12} height={12} />}
    onClickRemove={onClickRemove}
    excluded={name === FilterTypeEnum.geographyToExclude}
  />
);
