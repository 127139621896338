import dayjs from 'dayjs';

export interface TakedownRawModel {
  consecutive_down_results: number;
  last_take_down_check_at: string | null;
  taken_down: boolean;
}
export class TakedownModel {
  lastTakeDownCheckAt: null | dayjs.Dayjs;

  consecutiveDownResults: number;

  takenDown: boolean;

  constructor(
    props: Pick<
      TakedownModel,
      'consecutiveDownResults' | 'lastTakeDownCheckAt' | 'takenDown'
    >,
  ) {
    this.consecutiveDownResults = props.consecutiveDownResults;
    this.lastTakeDownCheckAt = props.lastTakeDownCheckAt;
    this.takenDown = props.takenDown;
  }

  static createFromTakedownRawModel(props: TakedownRawModel) {
    return new TakedownModel({
      consecutiveDownResults: props.consecutive_down_results,
      lastTakeDownCheckAt: dayjs(props.last_take_down_check_at).isValid()
        ? dayjs(props.last_take_down_check_at)
        : null,
      takenDown: props.taken_down,
    });
  }
}
