import { MeModel } from 'product-types/src/domain/me/me';
import { GlobalData } from 'product-types/src/domain/Domain';
import { MessageModel } from './Message';

export interface GetMainInfoAndMePayload {
  data:
    | {
        type: 'request';
        orgUid: string;
      }
    | {
        type: 'response';
        mainInfo: GlobalData;
        me: MeModel;
      };
}
export const GetMainInfoAndMeType = 'GetMainInfoAndMeType';

export class GetMainInfoAndMe extends MessageModel {
  type = GetMainInfoAndMeType;

  payload?: GetMainInfoAndMePayload;

  constructor(data: Omit<GetMainInfoAndMe, 'type'>) {
    super({
      type: GetMainInfoAndMeType,
      payload: data.payload,
    });
  }

  toString(): string {
    return super.toString();
  }
}
