import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { ModerationReason } from 'product-types/src/domain/moderationReason/ModerationReason';
import { NewFeatureLabelFilterValue } from '../../../../components/Filters/NewFeatureLabelFilter/NewFeatureLabelFilterValue';
import { NewFeatureLabelFilter } from '../../../../components/Filters/NewFeatureLabelFilter/index';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import {
  FeatureLabelTypes,
  FeatureLabelTypesOptionsLabels,
  FeatureLabelValue,
  readFilterFromQueryProps,
} from './FeatureLabelValue';

export interface FeatureLabelQueryValue extends QueryValue {
  post_moderation_reasons:
    | Array<ModerationReason>
    | [{ moderationReasonId: FeatureLabelTypes }];
  image_moderation_reasons:
    | Array<ModerationReason>
    | [{ moderationReasonId: FeatureLabelTypes }];
}

export class FeatureLabelFilter implements Filter {
  uuid: string;

  label: string;

  value: FeatureLabelValue;

  constructor(
    params?: Partial<Pick<FeatureLabelFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || FeatureLabelValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    const result: Array<DisplayingFilterValue> = [];
    if (this.value.labelTypeImage !== FeatureLabelTypes.Null) {
      result.push({
        name: FilterTypeEnum.featureLabelImage,
        value: {
          name: `${FeatureLabelTypesOptionsLabels.find(
            (a) => a.value === this.value.labelTypeImage,
          )?.label} - Image`,
        },
        uuid: this.uuid,
        key:
          this.uuid +
          FilterTypeEnum.featureLabelImage +
          this.value.labelTypeImage,
      });
    } else {
      result.push(
        ...this.value.imageReasons.map((reason) => ({
          name: FilterTypeEnum.featureLabelImage,
          value: reason,
          uuid: this.uuid,
          key:
            this.uuid +
            FilterTypeEnum.featureLabelImage +
            reason.moderationReasonId,
        })),
      );
    }
    if (this.value.labelTypePost !== FeatureLabelTypes.Null) {
      result.push({
        name: FilterTypeEnum.featureLabelPost,
        value: {
          name: `${FeatureLabelTypesOptionsLabels.find(
            (a) => a.value === this.value.labelTypePost,
          )?.label} - Post`,
        },
        uuid: this.uuid,
        key:
          this.uuid +
          FilterTypeEnum.featureLabelPost +
          this.value.labelTypePost,
      });
    } else {
      result.push(
        ...this.value.postReasons.map((reason) => ({
          name: FilterTypeEnum.featureLabelPost,
          value: reason,
          uuid: this.uuid,
          key:
            this.uuid +
            FilterTypeEnum.featureLabelPost +
            reason.moderationReasonId,
        })),
      );
    }
    return result;
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    switch (removingFilter.name) {
      case FilterTypeEnum.featureLabelImage: {
        if (
          FeatureLabelTypesOptionsLabels.some(
            (e) => `${e.label} - Image` === removingFilter.value.name,
          )
        ) {
          this.value = this.value.setImageLabelType(FeatureLabelTypes.Null);
        } else {
          this.value = this.value.removeImageReason(
            removingFilter.value as ModerationReason,
          );
        }
        break;
      }
      case FilterTypeEnum.featureLabelPost: {
        if (
          FeatureLabelTypesOptionsLabels.some(
            (e) => `${e.label} - Post` === removingFilter.value.name,
          )
        ) {
          this.value = this.value.setPostLabelType(FeatureLabelTypes.Null);
        } else {
          this.value = this.value.removePostReason(
            removingFilter.value as ModerationReason,
          );
        }
        break;
      }
      default: {
        break;
      }
    }
    return new FeatureLabelFilter(this);
  }

  get component() {
    return NewFeatureLabelFilter;
  }

  get displayFilterComponent() {
    return NewFeatureLabelFilterValue;
  }

  get queryFilterValue(): FeatureLabelQueryValue {
    return {
      post_moderation_reasons:
        this.value.labelTypePost === FeatureLabelTypes.Null
          ? this.value.postReasons
          : [{ moderationReasonId: this.value.labelTypePost }],
      image_moderation_reasons:
        this.value.labelTypeImage === FeatureLabelTypes.Null
          ? this.value.imageReasons
          : [{ moderationReasonId: this.value.labelTypeImage }],
    };
  }

  static readFilterFromQuery(
    props?: readFilterFromQueryProps,
  ): FeatureLabelFilter {
    return new FeatureLabelFilter({
      value: FeatureLabelValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: any,
    props: readFilterFromQueryProps,
  ): FeatureLabelFilter {
    return new FeatureLabelFilter({
      value: FeatureLabelValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
