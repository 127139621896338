import styled from 'styled-components';

const StyledBadge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &.-small {
    padding: 0.25em;
    min-width: 20px;
    min-height: 20px;
  }

  &.-medium {
    padding: 0.25em 0.5em;
    min-width: 1.5em;
    min-height: 28px;
  }

  &.-rounded {
    border-radius: 50%;
  }

  &.-primary {
    background: var(--primary);
  }

  &.-mint-green {
    background: var(--mint-green-lighter);
  }

  &.-salmon {
    background: var(--salmon-salmon-4);
    color: var(--primary-black);
    font-size: 10px;
    font-weight: 700;
    line-height: 20px;
    padding: 0.25rem 0.5rem;
  }

  &.-light-grey {
    background: var(--light-gray);
    color: var(--primary-black);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    padding: 0.25rem 0.5rem;
  }

  &.-grey {
    background: var(--neutral-grey-200);
    color: var(--neutral-grey-500);

    &:hover {
      background-color: var(--neutral-grey-300);
    }
  }
`;

export { StyledBadge };
