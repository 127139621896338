import { LoginOrganisationRaw } from 'product-types/src/domain/organisation/LoginOrganisation';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface TokenRequestModel extends EndpointRequests {
  data: {
    email: string;
    password: string;
  };
}

export interface TokenResponseModel {
  can_create_organisation: true;
  user_name: 'string';
  user_profile_picture_link: 'string';
  user_organisations: Array<LoginOrganisationRaw>;
}

const getToken = new Endpoint<TokenRequestModel, TokenResponseModel>({
  url: '/token',
  method: 'POST',
  headers: {},
});

export default getToken;
