/*
 *
 * Moderation reducer
 *
 */
import { produce } from 'immer';
import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { Pagination } from 'product-types/src/common/Pagination/Pagination';
import {
  Sorting,
  SortingDirectionEnum,
} from 'product-types/src/common/Sorting/Sorting';
import { PostFeedDataModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { PostModerationModel } from 'product-types/src/domain/post/PostModerationModel';
import { AssociatedImage } from 'product-types/src/domain/image/AssociatedImage';
import { ImageModerationModel } from 'product-types/src/domain/image/ImageModerationModel';
import {
  UPDATE_ASSOCIATED_PICTURES,
  UPDATE_DUPLICATE_GROUP_FOR_MODERATION,
} from 'containers/ImageModerationContainer/constants';
import {
  UPDATE_POST_FOR_MODERATE,
  SELECT_FILTER_MODERATION,
  LOAD_NEXT_POST_TO_MODERATE,
  LOAD_PREV_POST_TO_MODERATE,
  RESET_STATE,
  UPDATE_FILTERS_RELATED_POSTS,
  UPDATE_RELATED_POSTS,
} from './constants';

export const createInitialState = () => ({
  moderationPost: {
    currentPost: FetchableData.default as FetchableData<PostModerationModel>,
    number_of_posts_to_moderate: 0,
    post_moderation_index: 0,
    total_number_of_posts: 0,
  },
  moderationDuplicatedGroup: {
    currentDuplicatedGroup:
      FetchableData.default as FetchableData<ImageModerationModel>,
    number_of_duplicated_groups_to_moderate: 0,
    duplicated_group_moderation_index: 0,
  },
  relatedPosts: {
    loading: false,
    filters: new TableParams({
      pagination: new Pagination({
        offset: 0,
        page: 1,
        perpage: 10,
      }),
      sorting: new Sorting({
        key: '',
        order: SortingDirectionEnum.desc,
      }),
    }),
    data: FetchableData.default as FetchableData<PostFeedDataModel>,
  },
  timestamp: new Date().getTime(),
  reverse: true,
  sort_by: 'id',
  offset: 0,
  users: [],
  image_grid_loading: false,
  associated_images: FetchableData.default as FetchableData<
    Array<AssociatedImage>
  >,
  perpage: 10,
});

export type ModerationState = ReturnType<typeof createInitialState>;

/* eslint-disable default-case, no-param-reassign */
const moderationReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case SELECT_FILTER_MODERATION:
        draft[action.field] = action.value;
        draft.timestamp = new Date().getTime();
        break;

      case UPDATE_POST_FOR_MODERATE:
        draft.moderationPost.currentPost = action.post;
        draft.relatedPosts.filters = new TableParams({
          pagination: new Pagination({
            offset: 0,
            page: 1,
            perpage: 10,
          }),
          sorting: new Sorting({
            key: '',
            order: SortingDirectionEnum.desc,
          }),
        });
        if (typeof action.number_of_posts_to_moderate === 'number') {
          draft.moderationPost.number_of_posts_to_moderate =
            action.number_of_posts_to_moderate;
        }
        if (typeof action.post_moderation_index === 'number') {
          draft.moderationPost.post_moderation_index =
            action.post_moderation_index;
        }
        if (typeof action.total_number_of_posts === 'number') {
          draft.moderationPost.total_number_of_posts =
            action.total_number_of_posts;
        }
        break;
      case UPDATE_DUPLICATE_GROUP_FOR_MODERATION:
        draft.moderationDuplicatedGroup.currentDuplicatedGroup =
          action.duplicatedGroup;
        if (typeof action.duplicated_group_moderation_index === 'number') {
          draft.moderationDuplicatedGroup.duplicated_group_moderation_index =
            action.duplicated_group_moderation_index;
        }
        if (
          typeof action.number_of_duplicated_groups_to_moderate === 'number'
        ) {
          draft.moderationDuplicatedGroup.number_of_duplicated_groups_to_moderate =
            action.number_of_duplicated_groups_to_moderate;
        }
        break;
      case UPDATE_FILTERS_RELATED_POSTS:
        draft.relatedPosts.filters = action.payload;
        break;
      case UPDATE_RELATED_POSTS:
        draft.relatedPosts.data = action.postsData;
        break;
      case UPDATE_ASSOCIATED_PICTURES:
        draft.associated_images = action.associated_images;
        break;
      case LOAD_NEXT_POST_TO_MODERATE:
      case LOAD_PREV_POST_TO_MODERATE:
        draft.offset = 0;
        draft.perpage = 10;
        break;
    }
  });

export default moderationReducer;
