import { useTextHighlight } from 'hooks/useTextHighlight';
import { useEffect, useRef } from 'react';

type KeyboardInteraction = (key: KeyboardEvent) => boolean;

type KeyboardInteractionSubscription = (key: KeyboardEvent) => void;

type subscription = {
  conditions: Array<KeyboardInteraction>;
  callback: KeyboardInteractionSubscription;
};

interface useKeyboardInteractionProps {
  observable: Array<any>;
  subscriptions: Array<subscription>;
}

const checkIfTargetIsInput = (event: KeyboardEvent) =>
  event.target &&
  ((event.target as HTMLElement).tagName?.toLowerCase() === 'textarea' ||
    ((event.target as HTMLElement).tagName?.toLowerCase() === 'input' &&
      !(event.target as HTMLElement).classList?.contains(
        'ant-checkbox-input',
      )));

const latinAlphabetLetters = new Set(
  'abcdefghijklmnopqrstuvwxyz'
    .split('')
    .map((letter) => `Key${letter.toUpperCase()}`),
);

export const enterKey: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'Enter';
export const shiftKey: KeyboardInteraction = (event: KeyboardEvent) =>
  event.shiftKey;
export const notShiftKey: KeyboardInteraction = (event: KeyboardEvent) =>
  !event.shiftKey;
export const ctrlKey: KeyboardInteraction = (event: KeyboardEvent) =>
  event.ctrlKey;
export const notCtrlKey: KeyboardInteraction = (event: KeyboardEvent) =>
  !event.ctrlKey;
export const arrowLeft: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'ArrowLeft';
export const arrowRight: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'ArrowRight';
export const escape: KeyboardInteraction = (event: KeyboardEvent) =>
  event.key === 'Escape';
export const latinAlphabetLetter: KeyboardInteraction = (
  event: KeyboardEvent,
) => latinAlphabetLetters.has(event.code);

export const useKeyboardInteraction = ({
  subscriptions,
  observable,
}: useKeyboardInteractionProps) => {
  const isTextHighlighted = useTextHighlight();
  const functionRef = useRef<(e: KeyboardEvent) => void>((event) => {
    if (checkIfTargetIsInput(event) || isTextHighlighted) {
      return;
    }
    subscriptions.forEach(
      (subscription) =>
        subscription.conditions.every((condition) => condition(event)) &&
        subscription.callback(event),
    );
  });
  useEffect(() => {
    functionRef.current = (event) => {
      if (checkIfTargetIsInput(event) || isTextHighlighted) {
        return;
      }
      subscriptions.forEach(
        (subscription) =>
          subscription.conditions.every((condition) => condition(event)) &&
          subscription.callback(event),
      );
    };
  }, [...observable, subscriptions, isTextHighlighted]);

  useEffect(() => {
    const func = (ev: KeyboardEvent) => functionRef.current(ev);
    document.addEventListener('keydown', func);
    return () => {
      document.removeEventListener('keydown', func);
    };
  }, []);
};
