/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import Typography from 'product-ui/src/components/atoms/Typography';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Flex } from 'antd';
import { SelectWithInsertedAreRemoved } from 'ui/src/components/atoms/Select/withInsertedAreRemoved';
import styled from 'styled-components';
import { UserFilterValueModel } from '../../../types/filters/AtomicFiltersImplementation/UserFilter/UserFilterValueModel';

const StyledPlaceholder = styled(Flex)`
  svg {
    fill: none !important;
  }
`;

const placeholder = (
  <StyledPlaceholder gap="0.5rem" align="center">
    <NaveeIcon.SearchIcon />
    <Typography color="var(--neutral-grey-400)">
      Search for a person...
    </Typography>
  </StyledPlaceholder>
);

interface SearchPersonProps {
  users: Array<UserLightModel>;
  value: Array<UserFilterValueModel>;
  onSelect: (user: UserLightModel) => void;
  style?: React.CSSProperties;
  optionRemovalCriteria: (opt) => boolean;
}

export const SearchPerson = (props: SearchPersonProps) => {
  const handleClick = (value: number, user: UserLightModel) => {
    if (user) {
      props.onSelect(user);
    }
  };
  const users = useMemo(
    () => props.users.filter((user) => user.isActive),
    [props.users],
  );

  return (
    <SelectWithInsertedAreRemoved
      optionRemovalCriteria={props.optionRemovalCriteria}
      dataTestId="search-person-select"
      style={{ ...(props.style || {}) }}
      showSearch
      className="search-person"
      options={users}
      suffixIcon={<></>}
      value={props.value}
      placeholder={placeholder}
      optionFilterProp="name"
      fieldNames={{
        value: 'id',
        label: 'name',
      }}
      optionLabelProp="name"
      onChange={handleClick}
    />
  );
};
