import { Label } from 'product-types/src/domain/label/Label';
import React from 'react';
import { Flex, Dropdown } from 'antd';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import { Select } from 'product-ui/src/components/atoms/Select';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import Typography from 'product-ui/src/components/atoms/Typography';
import { CheckboxGroup, newLabelContainerDefaultStyles } from './style';

interface ExclusionFilterProps {
  label: string;
  hasLabel: (type: 'account' | 'post' | 'image', label: Label) => boolean;
  type: 'account' | 'post' | 'image';
  allLabels: Array<Label>;
  selectedLabels: Array<Label>;
  handleAllCheckboxChange: (checked: boolean, type: string) => void;
  handleCheckAllInfirement: (checked: boolean, type: string) => void;
  handleCheckboxChange: (label: Label, type: string, checked: boolean) => void;
  countInfringementLabels: number;
  allInfingementChecked: number | boolean;
}

const suggestionPortalStyles = {
  ...newLabelContainerDefaultStyles,
  maxHeight: 460,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
  backgroundColor: 'white',
  overflow: 'auto',
};

const checkboxGroupStyles = { padding: '1rem 4px' };
const checkboxStyles = { padding: '4px 12px' };

const onClickDoNothing = (e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  return false;
};
export const ExclusionFilter = ({
  label,
  type,
  hasLabel,
  countInfringementLabels,
  selectedLabels,
  handleAllCheckboxChange,
  handleCheckAllInfirement,
  handleCheckboxChange,
  allInfingementChecked,
  allLabels,
}: ExclusionFilterProps) => (
  <Flex vertical>
    <Typography
      variant="xsmall"
      color="var(--neutral-grey-70)"
      fontWeight="bold"
      textTransform="uppercase"
      letterSpacing="0.24px"
    >
      {label}
    </Typography>
    <Dropdown
      dropdownRender={() => (
        <div id="suggestionPortal" style={suggestionPortalStyles}>
          <CheckboxGroup style={checkboxGroupStyles}>
            <StyledCheckbox>
              <Checkbox
                key="all"
                label="All"
                style={checkboxStyles}
                checked={
                  allLabels.length === selectedLabels.length &&
                  selectedLabels.length !== 0
                }
                indeterminate={
                  allLabels.length !== selectedLabels.length &&
                  selectedLabels.length > 0
                }
                onChange={(checked) => handleAllCheckboxChange(checked, type)}
              />
            </StyledCheckbox>
            {countInfringementLabels > 0 && (
              <StyledCheckbox>
                <Checkbox
                  style={checkboxStyles}
                  key="all infringements"
                  label="All Infringements"
                  checked={allInfingementChecked}
                  indeterminate={
                    !allInfingementChecked && selectedLabels.length > 0
                  }
                  onChange={(checked) =>
                    handleCheckAllInfirement(checked, type)
                  }
                />
              </StyledCheckbox>
            )}
            {allLabels.map((lbl) => (
              <StyledCheckbox>
                <Checkbox
                  style={checkboxStyles}
                  key={lbl.id}
                  label={lbl.label}
                  checked={hasLabel(type, lbl)}
                  onChange={(checked) =>
                    handleCheckboxChange(lbl, type, checked)
                  }
                />
              </StyledCheckbox>
            ))}
          </CheckboxGroup>
        </div>
      )}
    >
      <Select
        placeholder={selectedLabels.length > 0 ? 'Selected' : label}
        count={selectedLabels.length}
        open={false}
        onClick={onClickDoNothing}
      ></Select>
    </Dropdown>
  </Flex>
);
