export type Id = number;

// todo review different website models

export interface WebsiteSuggestion {
  count?: number;

  label: string;

  id: string | number;

  medianProductLifetime?: number;

  faviconUrl?: string;
}

export interface WebsiteDomain {
  id: number;

  domain_name: string;

  tracked: boolean;

  median_product_lifetime: number;

  favicon_url: string;
}

export class Website {
  domain: string;

  category: string;

  id: string | number;

  constructor(prop: Pick<Website, 'domain' | 'id' | 'category'>) {
    this.id = prop.id;
    this.category = prop.category;
    this.domain = prop.domain;
  }

  get onlyDomain() {
    if (!this.domain) {
      return '';
    }
    if (typeof this.domain !== 'string') {
      return '';
    }
    return this.domain
      .replace('https://', '')
      .replace('http://', '')
      .replace('www.', '')
      .replace(/^\//, '');
  }
}

export interface WebsiteRaw {
  category: null;
  country_code: string;
  domain_name: string;
  id: number;
  number_of_posts: number;
  recidivism: null;
  tracked: boolean | null;
}
