import React from 'react';
import Typography from 'ui/src/components/atoms/Typography';
import { Flex } from 'antd';

export const CreateNewOrganizationSuccess = () => (
  <Flex
    style={{ zIndex: 1, width: '100%', height: '100%' }}
    align="center"
    vertical
    gap="0.5rem"
  >
    <svg
      style={{ marginBottom: '1rem' }}
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        d="M40.0003 6.66602C21.6003 6.66602 6.66699 21.5993 6.66699 39.9994C6.66699 58.3994 21.6003 73.3327 40.0003 73.3327C58.4003 73.3327 73.3337 58.3994 73.3337 39.9994C73.3337 21.5993 58.4003 6.66602 40.0003 6.66602ZM30.967 54.2994L19.0003 42.3327C18.6917 42.0241 18.4469 41.6577 18.2799 41.2545C18.1129 40.8513 18.0269 40.4191 18.0269 39.9827C18.0269 39.5462 18.1129 39.1141 18.2799 38.7109C18.4469 38.3077 18.6917 37.9413 19.0003 37.6327C19.3089 37.3241 19.6753 37.0793 20.0785 36.9123C20.4817 36.7452 20.9139 36.6593 21.3503 36.6593C21.7868 36.6593 22.2189 36.7452 22.6221 36.9123C23.0254 37.0793 23.3917 37.3241 23.7003 37.6327L33.3337 47.2327L56.267 24.2993C56.8903 23.6761 57.7356 23.3259 58.617 23.3259C59.4984 23.3259 60.3437 23.6761 60.967 24.2993C61.5903 24.9226 61.9404 25.7679 61.9404 26.6493C61.9404 27.5308 61.5903 28.3761 60.967 28.9993L35.667 54.2994C35.3586 54.6084 34.9923 54.8535 34.5891 55.0208C34.1858 55.1881 33.7536 55.2742 33.317 55.2742C32.8804 55.2742 32.4482 55.1881 32.0449 55.0208C31.6417 54.8535 31.2754 54.6084 30.967 54.2994Z"
        fill="#6BFFB9"
      />
    </svg>
    <Typography variant="h7" color="#000" fontWeight="bold">
      You’ve created a new organization!
    </Typography>
    <Typography variant="medium" color="#000" style={{ textAlign: 'center' }}>
      Setting it up may take us a few minutes. An email will be sent to you once
      it is ready to access.
    </Typography>
  </Flex>
);
