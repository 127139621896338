import { LoginOrganisationRaw } from 'product-types/src/domain/organisation/LoginOrganisation';
import { Endpoint, EndpointRequests } from '../../../../endpoint';

interface AllOrganisationsRequestModel extends EndpointRequests {}

export interface AllOrganisationsResponseModel {
  can_create_organisation: true;
  user_name: 'string';
  user_profile_picture_link: 'string';
  user_organisations: Array<LoginOrganisationRaw>;
}

const getAllOrganisations = new Endpoint<
  AllOrganisationsRequestModel,
  AllOrganisationsResponseModel
>({
  url: '/api/user/organisations',
  method: 'GET',
  headers: {},
});

export default getAllOrganisations;
