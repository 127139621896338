export interface UserLoginModelModel {
  can_create_organisation: true;
  user_name: 'string';
  user_profile_picture_link: 'string';
}

export class UserLoginModel {
  canCreateOrganisation: boolean;

  name: string;

  profilePictureLink: string;

  constructor(
    prop: Pick<
      UserLoginModel,
      'canCreateOrganisation' | 'name' | 'profilePictureLink'
    >,
  ) {
    this.canCreateOrganisation = prop.canCreateOrganisation;
    this.name = prop.name;
    this.profilePictureLink = prop.profilePictureLink;
  }

  static createFromRawModel(rawModel: UserLoginModelModel) {
    return new UserLoginModel({
      canCreateOrganisation: rawModel.can_create_organisation,
      name: rawModel.user_name,
      profilePictureLink: rawModel.user_profile_picture_link,
    });
  }
}
