import * as Domain from 'product-types/src/domain/Domain';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export class ProductInformationValue implements FilterValue {
  price: Domain.Price.Price;

  stock: Domain.Stock.Stock;

  constructor(params?: Pick<ProductInformationValue, 'stock' | 'price'>) {
    this.price = params?.price || Domain.Price.Price.defaultValue;
    this.stock = params?.stock || Domain.Stock.Stock.defaultValue;
  }

  static get defaultValue(): ProductInformationValue {
    return new ProductInformationValue();
  }

  setPrice(price: Domain.Price.Price): ProductInformationValue {
    return new ProductInformationValue({
      stock: this.stock,
      price,
    });
  }

  setStock(stock: Domain.Stock.Stock): ProductInformationValue {
    return new ProductInformationValue({
      stock,
      price: this.price,
    });
  }

  protected static calcIntValue(value, defaultValue) {
    if (value === null || value === undefined) {
      return defaultValue;
    }
    if (Number.isNaN(parseInt(value, 10))) {
      return defaultValue;
    }
    return parseInt(value, 10);
  }

  protected static calcFloatValue(value, defaultValue) {
    if (value === null || value === undefined) {
      return defaultValue;
    }
    if (Number.isNaN(parseFloat(value))) {
      return defaultValue;
    }
    return parseFloat(value);
  }

  static get readFilterFromQuery(): ProductInformationValue {
    const maximumStock = new URLSearchParams(window.location.search).get(
      'maximum_stock',
    );
    const maximumPrice = new URLSearchParams(window.location.search).get(
      'maximum_price',
    );
    const minimumStock = new URLSearchParams(window.location.search).get(
      'minimum_stock',
    );
    const minimumPrice = new URLSearchParams(window.location.search).get(
      'minimum_price',
    );
    return new ProductInformationValue({
      stock: {
        max: this.calcIntValue(
          maximumStock,
          ProductInformationValue.defaultValue.stock.max,
        ),
        min: this.calcIntValue(
          minimumStock,
          ProductInformationValue.defaultValue.stock.min,
        ),
      },
      price: {
        max: this.calcFloatValue(
          maximumPrice,
          ProductInformationValue.defaultValue.price.max,
        ),
        min: this.calcFloatValue(
          minimumPrice,
          ProductInformationValue.defaultValue.price.min,
        ),
      },
    });
  }

  static readFromSavedFilter(props: any): ProductInformationValue {
    const maximumStock = props.maximum_stock;
    const maximumPrice = props.maximum_price;
    const minimumStock = props.minimum_stock;
    const minimumPrice = props.minimum_price;
    return new ProductInformationValue({
      stock: {
        max: this.calcIntValue(
          maximumStock,
          ProductInformationValue.defaultValue.stock.max,
        ),
        min: this.calcIntValue(
          minimumStock,
          ProductInformationValue.defaultValue.stock.min,
        ),
      },
      price: {
        max: this.calcFloatValue(
          maximumPrice,
          ProductInformationValue.defaultValue.price.max,
        ),
        min: this.calcFloatValue(
          minimumPrice,
          ProductInformationValue.defaultValue.price.min,
        ),
      },
    });
  }
}
