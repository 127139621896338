import { produce } from 'immer';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { DuplicatedGroupDataModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import {
  UPDATE_DUPLICATED_GROUPS,
  RESET_STATE,
  UPDATE_DUPLICATED_GROUPS_COUNT,
} from './constants';

const createInitialState = () => ({
  duplicated_groups:
    FetchableData.default as FetchableData<DuplicatedGroupDataModel>,
  duplicatedGroupsCount: FetchableData.default as FetchableData<number>,
});

export type ImageFeedState = ReturnType<typeof createInitialState>;

/* eslint-disable default-case, no-param-reassign */
const imageViewPageReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case UPDATE_DUPLICATED_GROUPS:
        draft.duplicated_groups = action.imageViewData;
        break;
      case UPDATE_DUPLICATED_GROUPS_COUNT:
        draft.duplicatedGroupsCount = action.count;
        break;
    }
  });

export default imageViewPageReducer;
