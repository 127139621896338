import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Button, {
  ButtonSize,
} from 'product-ui/src/components/atoms/Button/Button';
import {
  Geographic,
  GeographySelectOptions,
} from 'product-types/src/domain/geo/Geo';
import { Badge, Flex } from 'antd';
import { Select } from 'product-ui/src/components/atoms/Select';
import { EstimatedGeoFilter } from '../../../types/filters/AtomicFiltersImplementation/EstimatedGeo/EstimatedGeoFilter';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import {
  ctrlKey,
  enterKey,
  useKeyboardInteraction,
} from '../../../hooks/keyboardActions/useKeyboardInteraction';

export interface EstimatedGeoFilterProps extends FilterParams {
  value: EstimatedGeoFilter;
  onChange: (v: Filter) => void;
}

const include = 'inlcude';
const exclude = 'exclude';

const calcOptions = (
  geographySelectOptionsRaw: GeographySelectOptions | null | undefined,
  includedGeography: Array<Geographic>,
  excludedGeography: Array<Geographic>,
) => {
  const includedRegionOptions = (geographySelectOptionsRaw?.zones || []).filter(
    (geo) =>
      !excludedGeography.find((excluded) => excluded.value === geo.value),
  );
  const includedCountryOptions = (
    geographySelectOptionsRaw?.countries || []
  ).filter(
    (geo) =>
      !excludedGeography.find((excluded) => excluded.value === geo.value),
  );
  const excludedRegionOptions = (geographySelectOptionsRaw?.zones || [])
    .filter(
      (geo) =>
        !includedGeography.find((included) => included.value === geo.value),
    )
    .map((z) => ({ label: z.label, value: z.value }));
  const excludedCountryOptions = (
    geographySelectOptionsRaw?.countries || []
  ).filter(
    (geo) =>
      !includedGeography.find((included) => included.value === geo.value),
  );
  const titleWithCount = (title: string, count: number) => (
    <Flex align="center" justify="space-between">
      {title}
      <Badge style={{ color: '#17224D' }} color="#EBECF0" count={count} />
    </Flex>
  );
  return [
    [
      {
        label: titleWithCount('Regions', includedRegionOptions.length),
        title: 'Regions',
        options: includedRegionOptions,
      },
      {
        label: titleWithCount('Countries', includedCountryOptions.length),
        title: 'Countries',
        options: includedCountryOptions,
      },
    ],
    [
      {
        label: titleWithCount('Regions', excludedRegionOptions.length),
        title: 'Regions',
        options: excludedRegionOptions,
      },
      {
        label: titleWithCount('Countries', excludedCountryOptions.length),
        title: 'Countries',
        options: excludedCountryOptions,
      },
    ],
  ];
};

export const NewEstimatedGeoFilter = (props: EstimatedGeoFilterProps) => {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const [forceClose, updateForceClose] = useState({});
  const [searchInclude, setSearchInclude] = useState('');
  const [searchExclude, setSearchExclude] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const geographySelectOptionsRaw = context.geographySelectOptions;

  const [geographySelectOptions, setGeographyOptions] = useState(
    calcOptions(
      geographySelectOptionsRaw,
      props.value.value.geographyToInclude ?? [],
      props.value.value.geographyToExclude ?? [],
    ),
  );

  useEffect(() => {
    setGeographyOptions(
      calcOptions(
        geographySelectOptionsRaw,
        props.value.value.geographyToInclude ?? [],
        props.value.value.geographyToExclude ?? [],
      ),
    );
  }, [
    geographySelectOptionsRaw,
    props.value.value.geographyToInclude,
    props.value.value.geographyToExclude,
  ]);

  const applyFilters = () => {
    updateForceClose(() => ({}));
    context.applyFilters?.();
  };

  const onSelectFilter = useCallback(
    (operation, geography: Geographic) => {
      if (operation === include) {
        props.onChange(
          new EstimatedGeoFilter({
            ...props.value,
            value: props.value.value.addIncludeGeography([
              {
                value: geography.value,
                label: geography.label,
                data: geography.data,
                key: geography.key,
                emoji: geography.emoji,
              },
            ]),
          }),
        );
      } else if (operation === exclude) {
        props.onChange(
          new EstimatedGeoFilter({
            ...props.value,
            value: props.value.value.addExcludeGeography([
              {
                value: geography.value,
                label: geography.label,
                data: geography.data,
                key: geography.key,
                emoji: geography.emoji,
              },
            ]),
          }),
        );
      }
    },
    [props.value.value, props.onChange],
  );

  const onDeSelectFilter = useCallback(
    (operation, code: string) => {
      if (operation === include) {
        const geographyObject = props.value.value.geographyToInclude.find(
          (geo) => geo.value === code,
        );
        if (!geographyObject) {
          throw new Error(`Geography not found: ${code}`);
        }
        props.onChange(
          new EstimatedGeoFilter({
            ...props.value,
            value: props.value.value.removeIncludedGeography(geographyObject),
          }),
        );
      } else if (operation === exclude) {
        const geographyObject = props.value.value.geographyToExclude.find(
          (geo) => geo.value === code,
        );
        if (!geographyObject) {
          throw new Error(`Geography not found: ${code}`);
        }
        props.onChange(
          new EstimatedGeoFilter({
            ...props.value,
            value: props.value.value.removeExcludedGeography(geographyObject),
          }),
        );
      }
    },
    [props.value.value, props.onChange],
  );

  const includeOptions = useMemo(
    () =>
      [
        {
          label: 'Regions',
          options: geographySelectOptions[0][0].options
            .map((option) => ({ ...option }))
            .filter(
              (option) =>
                !props.value.value.geographyToInclude.find(
                  (geo) => option.value === geo.value,
                ),
            )
            .filter((option) =>
              option.label.toLowerCase().includes(searchInclude.toLowerCase()),
            ),
        },
        {
          label: 'Countries',
          options: geographySelectOptions[0][1].options
            .map((option) => ({ ...option }))
            .filter(
              (option) =>
                !props.value.value.geographyToInclude.find(
                  (geo) => option.value === geo.value,
                ),
            )
            .filter((option) =>
              option.label.toLowerCase().includes(searchInclude.toLowerCase()),
            ),
        },
      ].filter((option) => option.options.length > 0),
    [geographySelectOptions, searchInclude, props.value],
  );

  const excludeOptions = useMemo(
    () =>
      [
        {
          label: 'Regions',
          options: geographySelectOptions[1][0].options
            .map((option) => ({ ...option }))
            .filter(
              (option) =>
                !props.value.value.geographyToExclude.find(
                  (geo) => option.value === geo.value,
                ),
            )
            .filter((option) =>
              option.label.toLowerCase().includes(searchExclude.toLowerCase()),
            ),
        },
        {
          label: 'Countries',
          options: geographySelectOptions[1][1].options
            .map((option) => ({ ...option }))
            .filter(
              (option) =>
                !props.value.value.geographyToExclude.find(
                  (geo) => option.value === geo.value,
                ),
            )
            .filter((option) =>
              option.label.toLowerCase().includes(searchExclude.toLowerCase()),
            ),
        },
      ].filter((option) => option.options.length > 0),
    [geographySelectOptions, searchExclude],
  );

  useKeyboardInteraction({
    observable: [context.applyFilters],
    subscriptions: [
      {
        conditions: [ctrlKey, enterKey],
        callback: () => {
          if (context.applyFilters) {
            updateForceClose(() => ({}));
          }
        },
      },
    ],
  });

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          width: 344,
          minHeight: 60,
          padding: '1.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        {geographySelectOptions?.length ? (
          <Flex vertical gap={20}>
            <Select
              value={props.value.value.geographyToInclude}
              onSelect={(_, selectedWebsitesValue: Geographic) =>
                onSelectFilter(include, selectedWebsitesValue)
              }
              onDeselect={(code: string) => onDeSelectFilter(include, code)}
              onSearch={setSearchInclude}
              filterOption={() => true}
              autoClearSearchValue
              onBlur={() => setSearchInclude('')}
              options={includeOptions}
              onDropdownVisibleChange={(open) => {
                setDropdownOpen(open);
              }}
              mode="multiple"
              placeholder="Included Geography..."
            />
            <Select
              value={props.value.value.geographyToExclude}
              onSelect={(_, selectedWebsitesValue: Geographic) =>
                onSelectFilter(exclude, selectedWebsitesValue)
              }
              onDeselect={(code: string) => onDeSelectFilter(exclude, code)}
              onDropdownVisibleChange={(open) => {
                setDropdownOpen(open);
              }}
              onBlur={() => setSearchExclude('')}
              options={excludeOptions}
              autoClearSearchValue
              filterOption={() => true}
              onSearch={setSearchExclude}
              mode="multiple"
              placeholder="Excluded Geography..."
            />
          </Flex>
        ) : null}

        {context.applyFilters && context.showApplyButton ? (
          <Button
            style={{ marginTop: 20 }}
            onClick={applyFilters}
            size={ButtonSize.Small}
            label="Apply"
          />
        ) : null}
      </Flex>
    ),
    [
      props.value.value,
      onSelectFilter,
      context,
      includeOptions,
      excludeOptions,
    ],
  );

  return (
    <FilterWithMenuWrapper
      text="Estimated Geo"
      preventClosing={dropdownOpen}
      count={props.value.displayingFilterValue.length}
      forceClose={forceClose}
      renderer={renderer}
    />
  );
};
