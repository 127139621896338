import React from 'react';
import Tag from 'product-ui/src/components/atoms/Tag';
import NaveeIcon from 'ui/src/components/atoms/NaveeIcon/NaveeIcon';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
} from '../../../types/filters/AtomicFilters/Filter';

export const NewDateFilterValue: DisplayFilterComponent = ({
  filterValue: { value },
}: DisplayFilterComponentProps) => (
  <Tag
    tag={{
      name: value as string,
      id: value as string,
      tagType: 'date',
    }}
    iconPrefix={
      <NaveeIcon.CalendarTwoTone width={12} height={12} color="black" />
    }
    showRemoveIcon={false}
  />
);
