import dayjs from 'dayjs';
import {
  CrawlingDateLabelEnum,
  CrawlingDateValueEnum,
} from 'product-types/src/domain/date/CrawlingDate';
import {
  DateFilterOption,
  DateFilterValueEnum,
  GetDateFilterOptionByValue,
} from 'product-types/src/domain/date/Date';
import { CrawlingDateIcons } from 'product-ui/src/components/atoms/NaveeIcon/CrawlingDateIcons';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface CrawlingDateOption {
  label: CrawlingDateLabelEnum;
  value: CrawlingDateValueEnum;
  icon: CrawlingDateIcons;
}

export const CrawlingDateFilterOptions: CrawlingDateOption[] = Object.keys(
  CrawlingDateValueEnum,
).map((key) => ({
  label: CrawlingDateLabelEnum[key],
  value: CrawlingDateValueEnum[key],
  icon: CrawlingDateIcons[key],
}));

export const GetCrawlingDateFilterOptionByValue = (value: string | null) =>
  CrawlingDateFilterOptions.find(
    (dateOption) =>
      dateOption.value?.toLocaleLowerCase() ===
      (value || '').toLocaleLowerCase(),
  );

export class CrawlingDateFilterValue implements FilterValue {
  startDate: dayjs.Dayjs;

  endDate: dayjs.Dayjs;

  interval: DateFilterOption;

  crawlingDate: CrawlingDateOption;

  manuallyChanged: boolean;

  crawlingDateOptions: Array<CrawlingDateOption>;

  private static DefaultDate: DateFilterValueEnum =
    DateFilterValueEnum.oneMonth;

  static FeedOptions: Array<CrawlingDateOption> = [
    CrawlingDateValueEnum.crawlingDate,
    CrawlingDateValueEnum.postCrawlingDate,
    CrawlingDateValueEnum.commentingDate,
    CrawlingDateValueEnum.moderationDate,
    CrawlingDateValueEnum.checkDate,
    CrawlingDateValueEnum.validationDate,
    CrawlingDateValueEnum.lastActivity,
    CrawlingDateValueEnum.takedownDate,
    CrawlingDateValueEnum.takedownSent,
    CrawlingDateValueEnum.dateOfTagging,
  ].map(GetCrawlingDateFilterOptionByValue) as Array<CrawlingDateOption>;

  static VendorOptions: Array<CrawlingDateOption> = [
    CrawlingDateValueEnum.lastActivity,
    CrawlingDateValueEnum.commentingDate,
    CrawlingDateValueEnum.vendorCreationDate,
    CrawlingDateValueEnum.dateOfAdding,
    CrawlingDateValueEnum.vendorDateOfTagging,
  ].map(GetCrawlingDateFilterOptionByValue) as Array<CrawlingDateOption>;

  constructor(
    params: Partial<
      Pick<
        CrawlingDateFilterValue,
        | 'endDate'
        | 'interval'
        | 'startDate'
        | 'crawlingDate'
        | 'crawlingDateOptions'
        | 'manuallyChanged'
      >
    >,
  ) {
    this.startDate = params?.startDate || dayjs();
    this.endDate = params?.endDate || dayjs();
    this.interval =
      params?.interval ||
      (GetDateFilterOptionByValue(
        CrawlingDateFilterValue.DefaultDate,
      ) as DateFilterOption);
    this.crawlingDate =
      params?.crawlingDate ||
      (GetCrawlingDateFilterOptionByValue(
        CrawlingDateFilterValue.DefaultCrawlingDate,
      ) as CrawlingDateOption);
    this.crawlingDateOptions =
      params?.crawlingDateOptions || CrawlingDateFilterOptions;
    this.manuallyChanged = params?.manuallyChanged || false;
  }

  static setDefaultDate(date: DateFilterValueEnum) {
    CrawlingDateFilterValue.DefaultDate = date;
  }

  static get defaultValue(): CrawlingDateFilterValue {
    return new CrawlingDateFilterValue({
      startDate: dayjs(),
      endDate: dayjs(),
      interval: GetDateFilterOptionByValue(CrawlingDateFilterValue.DefaultDate),
    });
  }

  private static get DefaultCrawlingDate(): CrawlingDateValueEnum {
    const path = window.location.pathname.slice(1).split('/')[0];
    switch (path) {
      case 'cluster': {
        return CrawlingDateValueEnum.lastActivity;
      }
      default: {
        return CrawlingDateValueEnum.crawlingDate;
      }
    }
  }

  static readFromSavedFilter(
    props: any,
    value: CrawlingDateFilterValue,
  ): CrawlingDateFilterValue {
    const intervalQuery = props.interval;
    const startDate = props.start_date;
    const endDate = props.end_date;
    const dateFilterType = props.date_filter_type;
    const interval = GetDateFilterOptionByValue(intervalQuery);
    if (interval) {
      return new CrawlingDateFilterValue({
        startDate: dayjs(startDate),
        endDate: dayjs(endDate),
        interval,
        crawlingDateOptions: value.crawlingDateOptions,
        crawlingDate: GetCrawlingDateFilterOptionByValue(dateFilterType),
      });
    }
    return new CrawlingDateFilterValue({
      startDate: dayjs(),
      endDate: dayjs(),
      interval: GetDateFilterOptionByValue(CrawlingDateFilterValue.DefaultDate),
      crawlingDateOptions: value.crawlingDateOptions,
      crawlingDate: GetCrawlingDateFilterOptionByValue(dateFilterType),
    });
  }

  static readFilterFromQuery(
    value: CrawlingDateFilterValue,
  ): CrawlingDateFilterValue {
    const intervalQuery = new URLSearchParams(window.location.search).get(
      'interval',
    );
    const startDate = new URLSearchParams(window.location.search).get(
      'start_date',
    );
    const endDate = new URLSearchParams(window.location.search).get('end_date');
    const dateFilterType = new URLSearchParams(window.location.search).get(
      'date_filter_type',
    );
    const interval = GetDateFilterOptionByValue(intervalQuery);
    if (interval) {
      return new CrawlingDateFilterValue({
        startDate:
          interval.value === DateFilterValueEnum.exactDates
            ? dayjs(startDate)
            : dayjs(),
        endDate:
          interval.value === DateFilterValueEnum.exactDates
            ? dayjs(endDate)
            : dayjs(),
        interval,
        crawlingDateOptions: value.crawlingDateOptions,
        crawlingDate: GetCrawlingDateFilterOptionByValue(dateFilterType),
      });
    }
    return new CrawlingDateFilterValue({
      startDate: dayjs(),
      endDate: dayjs(),
      interval: GetDateFilterOptionByValue(CrawlingDateFilterValue.DefaultDate),
      crawlingDateOptions: value.crawlingDateOptions,
      crawlingDate: GetCrawlingDateFilterOptionByValue(dateFilterType),
    });
  }
}
