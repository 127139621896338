/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  SELECT_ALL_TRACKED_WEBSITES,
  SELECT_ALL_UNTRACKED_WEBSITES,
} from 'store/constants';
import * as Domain from 'product-types/src/domain/Domain';
import { CrawlingDateValueEnum } from 'product-types/src/domain/date/CrawlingDate';
import { mapTakedownValue } from 'product-types/src/domain/takedownStatus/takedownStatus';
import { removeEmptyProperties } from 'product-utils/src/object';
import { FeedFilterQueryValue } from 'types/filters/MoleculesFilterImplementation/FeedFilterModel';
import { NonCircularCategory } from 'types/filters/AtomicFiltersImplementation/Product/ProductValue';
import { FeedRequestParameter } from '../Feed/Feed';

export function formatAccountCrawlingDate(crawlingDate: CrawlingDateValueEnum) {
  if (crawlingDate === CrawlingDateValueEnum.validationDate) {
    return 'account_validation_date';
  }
  if (crawlingDate === CrawlingDateValueEnum.checkDate) {
    return 'account_check_date';
  }
  if (crawlingDate === CrawlingDateValueEnum.moderationDate) {
    return 'account_moderation_date';
  }
  if (crawlingDate === CrawlingDateValueEnum.crawlingDate) {
    return 'account_crawling_date';
  }
  return crawlingDate ?? 'account_crawling_date';
}

export function getRequestParameter(
  action: FeedFilterQueryValue,
): FeedRequestParameter {
  const websiteValuesToExclude = [
    SELECT_ALL_TRACKED_WEBSITES,
    SELECT_ALL_UNTRACKED_WEBSITES,
  ];

  const { interval } = action;

  const includeTracked =
    (action.website_id || []).filter(
      (value) => value === SELECT_ALL_TRACKED_WEBSITES,
    ).length === 1;

  const includeUntracked =
    (action.website_id || []).filter(
      (value) => value === SELECT_ALL_UNTRACKED_WEBSITES,
    ).length === 1;

  const website_id = (
    (action.website_id || []) as Domain.Website.WebsiteSuggestion[]
  )
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes(value.id.toString()),
    )
    .map((val) => val.id)
    .filter((value: any) => value !== 'NaN');

  const website_id_to_exclude = (
    (action.website_id_to_exclude || []) as Domain.Website.WebsiteSuggestion[]
  )
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes(value.id.toString()),
    )
    .map((val) => val.id);

  const website_category_id = (
    (action.website_category_id ||
      []) as Domain.WebsiteCategory.WebsiteCategory[]
  ).map((val) => val.id);
  const website_category_id_to_exclude = (
    (action.website_category_id_to_exclude ||
      []) as Domain.WebsiteCategory.WebsiteCategory[]
  ).map((val) => val.id);

  return removeEmptyProperties<FeedRequestParameter>({
    perpage: action.perpage ?? 10,
    offset: action.offset,
    start_date: action.start_date,
    post_moderation_reasons: (action.post_moderation_reasons || []).map(
      ({ moderationReasonId }) => moderationReasonId,
    ),
    image_moderation_reasons: (action.image_moderation_reasons || []).map(
      ({ moderationReasonId }) => moderationReasonId,
    ),
    end_date: action.end_date,
    timezone_offset: new Date().getTimezoneOffset(),
    category_id: ((action.categoryId || []) as NonCircularCategory[]).map(
      ({ id }) => id,
    ),
    post_moderation_status: (action.post_moderation_status || []).map(
      ({ value }) => value,
    ),
    account_moderation_status: (action.account_moderation_status || []).map(
      ({ value }) => value,
    ),
    minimum_stock: action.minimum_stock,
    maximum_stock: action.maximum_stock,
    image_moderation_status: (
      (action.image_moderation_status ||
        []) as Domain.ModerationStatus.ModerationStatus[]
    ).map(({ value }) => value),
    status_type: ((action.status_type || []) as Domain.Label.Label[]).map(
      ({ name }) => name,
    ),
    hise: ((action.hise || []) as Domain.Label.Label[]).map(({ name }) => name),
    account_status_type: (
      (action.account_status_type || []) as Domain.Label.Label[]
    ).map(({ name }) => name),
    geography_to_include: (
      (action.geography_to_include || []) as Domain.Geographic.Geographic[]
    ).map(({ value }) => value),
    geography_to_exclude: (
      (action.geography_to_exclude || []) as Domain.Geographic.Geographic[]
    ).map(({ value }) => value),
    label_type_to_include: (
      (action.label_type_to_include || []) as Domain.Insight.Insight[]
    )
      .map(({ value }) => value)
      .concat((action.contact_types || []).map(({ value }) => value)),
    sort_by: action.sort_by || 'total_posts',
    reverse: action.reverse,
    post_id: action.post_id,
    moderator_id: action.moderator_id,
    qa_checker_id: action.qa_checker_id,
    validator_id: action.validator_id,
    commenter_id: action.commenter_id,
    account_website_id: action.account_website_id,
    account_poster_id: action.account_poster_id,
    website_id,
    related_to_account_id: action.related_to_account_id,
    cluster_id: action.cluster_id,
    post_url: action.post_url,
    poster_urls: action.poster_urls,
    vendor_names: action.vendor_names,
    domain_names: action.domain_names,
    poster_names: action.poster_names,
    keywords_to_include: action.keywords_to_include,
    keywords_to_exclude: action.keywords_to_exclude,
    comments_to_include: action.comments_to_include,
    contact_info_to_include: action.contact_info_to_include,
    comments_to_exclude: action.comments_to_exclude,
    taken_down: (
      (action.taken_down || []) as Domain.TakedownStatus.TakedownStatus[]
    ).map(({ value }) => mapTakedownValue(value)),
    duplicated_group_id: action.duplicated_group_id,
    website_category_id,
    website_category_id_to_exclude,
    post_tags_to_include: (
      (action.post_tags_to_include || []) as Domain.Tag.Tag[]
    ).map(({ id }) => id),
    post_tags_to_exclude: (
      (action.post_tags_to_exclude || []) as Domain.Tag.Tag[]
    ).map(({ id }) => id),
    account_tags_to_exclude: (
      (action.account_tags_to_exclude || []) as Domain.Tag.Tag[]
    ).map(({ id }) => id),
    account_tags_to_include: (
      (action.account_tags_to_include || []) as Domain.Tag.Tag[]
    ).map(({ id }) => id),
    duplicated_group_tags_to_exclude: (
      (action.duplicated_group_tags_to_exclude || []) as Domain.Tag.Tag[]
    ).map(({ id }) => id),
    duplicated_group_tags_to_include: (
      (action.duplicated_group_tags_to_include || []) as Domain.Tag.Tag[]
    ).map(({ id }) => id),
    cluster_tags_to_exclude: (
      (action.cluster_tags_to_exclude || []) as Domain.Tag.Tag[]
    ).map(({ id }) => id),
    cluster_tags_to_include: (
      (action.cluster_tags_to_include || []) as Domain.Tag.Tag[]
    ).map(({ id }) => id),
    post_tag_name_contains_to_include: (
      (action.post_tag_name_contains_to_include || []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    account_tag_name_contains_to_include: (
      (action.account_tag_name_contains_to_include ||
        []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    image_tag_name_contains_to_include: (
      (action.image_tag_name_contains_to_include || []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    cluster_tag_name_contains_to_include: (
      (action.cluster_tag_name_contains_to_include ||
        []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    post_tag_name_contains_to_exclude: (
      (action.post_tag_name_contains_to_exclude || []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    image_tag_name_contains_to_exclude: (
      (action.image_tag_name_contains_to_exclude || []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    account_tag_name_contains_to_exclude: (
      (action.account_tag_name_contains_to_exclude ||
        []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    cluster_tag_name_contains_to_exclude: (
      (action.cluster_tag_name_contains_to_exclude ||
        []) as Array<Domain.Tag.Tag>
    ).map(({ name }) => name),
    website_id_to_exclude,
    moderation_method: action.moderation_method,
    minimum_followers_count: action.minimum_followers_count,
    maximum_followers_count: action.maximum_followers_count,
    label_types_logic: 'AND',
    maximum_price: action.maximum_price,
    minimum_price: action.minimum_price,
    interval,
    all_tracked_websites: includeTracked ? 'true' : undefined,
    all_untracked_websites: includeUntracked ? 'true' : undefined,
    date_filter_type: action.date_filter_type,
    account_type: 'poster',
  });
}
