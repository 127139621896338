import * as Domain from 'product-types/src/domain/Domain';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface readFilterFromQueryProps {
  insightOptions: Array<Domain.Insight.Insight>;
}

const makeFlatList = (
  list: Domain.Insight.Insight[],
): Domain.Insight.Insight[] => {
  const flatList: Domain.Insight.Insight[] = [];
  const nodes = list.slice();
  while (nodes.length > 0) {
    const node = nodes.pop()!;
    flatList.push(node);
    nodes.push(...node.children);
  }
  return flatList;
};

const checkIfNodeSelected = (
  node: Domain.Insight.Insight,
  searchedNode: Domain.Insight.Insight,
) => {
  if (node.value === searchedNode.value) {
    return true;
  }
  return node.children.some((n) => checkIfNodeSelected(n, searchedNode));
};

export class InsightFilterValue implements FilterValue {
  selectedInsights: Array<Domain.Insight.Insight>;

  constructor(params: Pick<InsightFilterValue, 'selectedInsights'>) {
    this.selectedInsights = params.selectedInsights;
  }

  get selectedInsightsWithChildren() {
    const selectedNodes = makeFlatList(this.selectedInsights);
    selectedNodes.sort((a, b) => b.children.length - a.children.length);
    return selectedNodes.reduce((acc, node) => {
      if (acc.some((n) => checkIfNodeSelected(n, node))) {
        return acc;
      }
      return [...acc, node];
    }, [] as Array<Domain.Insight.Insight>);
  }

  setInsight(value: Domain.Insight.Insight[]) {
    return new InsightFilterValue({
      selectedInsights: value,
    });
  }

  removeInsight(value: Domain.Insight.Insight) {
    const nodesToRemove = makeFlatList([value]);
    return new InsightFilterValue({
      selectedInsights: this.selectedInsights.filter(
        (insight) => !nodesToRemove.includes(insight),
      ),
    });
  }

  hasInsight(value: Domain.Insight.Insight) {
    return this.selectedInsights.some(
      (insight: Domain.Insight.Insight) => insight.value === value.value,
    );
  }

  static get defaultValue(): InsightFilterValue {
    return new InsightFilterValue({
      selectedInsights: new Array<Domain.Insight.Insight>(),
    });
  }

  static readFilterFromQuery(
    props: readFilterFromQueryProps,
  ): InsightFilterValue {
    const flatList = makeFlatList(props.insightOptions);
    const insightsQuery =
      new URLSearchParams(window.location.search).getAll(
        'label_type_to_include',
      ) ?? [];
    const insights = (insightsQuery
      .map((insight) => flatList.find((i) => i.value === insight))!
      .filter((v) => !!v) || []) as Domain.Insight.Insight[];
    if (insights.length) {
      return new InsightFilterValue({
        selectedInsights: insights,
      });
    }
    return InsightFilterValue.defaultValue;
  }

  static readFromSavedFilter(
    savedFilter: any,
    props: readFilterFromQueryProps,
  ): InsightFilterValue {
    const flatList = makeFlatList(props.insightOptions);
    const insights = ((savedFilter.label_type_to_include ?? [])
      .map((insight) => flatList.find((i) => i.value === insight))!
      .filter((v) => !!v) || []) as Domain.Insight.Insight[];
    if (insights.length) {
      return new InsightFilterValue({
        selectedInsights: insights,
      });
    }
    return InsightFilterValue.defaultValue;
  }
}
