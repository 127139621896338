import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { useGetClassesFromObject } from 'ui/src/components/atoms/Typography/hooks';
import formatNumber from 'product-utils/src/typography';
import { StyledBadge } from './StyledBadge';
import { Spin } from '../Spin/Spin';
import Typography from '../Typography';

export interface BadgeProps {
  children?: ReactNode;
  tooltip?: string;
  loading?: boolean;
  className?: string;
  size: 'small' | 'medium';
  color?: 'primary' | 'mint-green' | 'grey' | 'light-grey';
  rounded?: boolean;
  total?: number | null;
  style?: React.CSSProperties;
  dataTestId?: string;
}

export const Badge = ({
  tooltip,
  children,
  className = '',
  loading = false,
  size = 'medium',
  color = 'mint-green',
  total,
  rounded = false,
  style = {},
  dataTestId,
}: BadgeProps) => {
  const classes = useGetClassesFromObject({
    [color]: true,
    [size]: true,
    rounded,
  });

  const badge = (
    <Spin spinning={loading}>
      <StyledBadge
        className={`${classes()} ${className}`}
        style={{
          ...style,
        }}
      >
        {total !== undefined ? (
          <Typography
            dataTestId={dataTestId}
            variant={size === 'medium' ? 'xsmall' : 'xxsmall'}
            fontWeight="bold"
          >
            {loading ? '' : formatNumber(total as number)}
          </Typography>
        ) : (
          children
        )}
      </StyledBadge>
    </Spin>
  );

  return tooltip ? <Tooltip title={tooltip}>{badge}</Tooltip> : badge;
};
