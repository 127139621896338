import { MeRaw } from 'product-types/src/domain/me/me';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UpdateMeRequestModel extends EndpointRequests {
  data: FormData;
}

export interface UpdateMeResponse {
  message: string;
  object: MeRaw;
}

const updateMe = new Endpoint<UpdateMeRequestModel, UpdateMeResponse>({
  url: '/api/me',
  method: 'PATCH',
  headers: { 'Content-Type': 'multipart/form-data' },
});

export default updateMe;
