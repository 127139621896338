import { Avatar, Flex } from 'antd';
import { AppState } from 'store/storeAccess';
import NaveeIcon from 'ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Typography from 'ui/src/components/atoms/Typography';
import React from 'react';
import { useSelector } from 'react-redux';

interface UserMenuContentProps {
  collapsed: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
  onClick?: () => void;
}
export default function UserMenuContent({
  collapsed,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  onClick,
}: UserMenuContentProps) {
  const currentUser = useSelector(
    (state: AppState) => state.loginPage?.currentUser?.data,
  );

  return (
    <Flex
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onClick={onClick}
      align="center"
      justify="flexStart"
      gap="0.5rem"
      style={{
        maxWidth: '-webkit-fill-available',
        cursor: 'pointer',
      }}
    >
      <Flex
        align="center"
        justify="center"
        style={{
          position: 'relative',
        }}
      >
        <Avatar
          size={32}
          src={currentUser?.profilePictureLink}
          shape="square"
          style={{
            flexShrink: 0,
            borderRadius: '4px',
            border: '1px solid var(--primary-border)',
            background: 'var(--primary-white)',
            padding: '3px',
            marginLeft: '-3px',
          }}
        />
        {!collapsed && (
          <NaveeIcon.TutorialMode
            hidden={!collapsed || !currentUser?.tutorialMode}
            style={{
              position: 'absolute',
              bottom: '-0.125rem',
              right: '-0.125rem',
            }}
          />
        )}
      </Flex>

      {!collapsed && (
        <Flex vertical gap="0.13rem" style={{ minWidth: 0 }}>
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            fontWeight="medium"
            hidden={collapsed}
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            color="var(--neutral-grey-500)"
          >
            {currentUser?.name}
          </Typography>
          <Flex
            gap="0.25rem"
            align="center"
            hidden={collapsed || !currentUser?.tutorialMode}
          >
            <NaveeIcon.TutorialMode />
            <Typography
              textTransform="uppercase"
              variant="xsmall"
              fontWeight="medium"
              hidden={collapsed}
              style={{
                textOverflow: 'ellipsis',
              }}
              color="var(--neutral-grey-500)"
            >
              Tutorial Mode
            </Typography>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
