import { MeRaw } from 'product-types/src/domain/me/me';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface MeRequestModel extends EndpointRequests {
  params: {
    timezone_offset: number;
    start_date: string;
    end_date: string;
  };
}

const me = new Endpoint<MeRequestModel, MeRaw>({
  url: '/api/me',
  method: 'GET',
  headers: {},
});

export default me;
