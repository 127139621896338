import * as Domain from 'product-types/src/domain/Domain';
import { GetTakeDownStatusFilterOptionByValue } from 'product-types/src/domain/takedownStatus/takedownStatus';
import { FilterValue } from '../../AtomicFilters/FilterValue';
import { makeArrayUniqueByKey } from 'product-utils/src/array';

export class TakedownStatusValue implements FilterValue {
  takenDown: Array<Domain.TakedownStatus.TakedownStatus>;

  constructor(params: Pick<TakedownStatusValue, 'takenDown'>) {
    this.takenDown = params.takenDown;
  }

  setTakedown(value: Domain.TakedownStatus.TakedownStatus[]) {
    return new TakedownStatusValue({
      takenDown: value,
    });
  }

  addTakeDown(value: Domain.TakedownStatus.TakedownStatus) {
    return new TakedownStatusValue({
      takenDown: makeArrayUniqueByKey(this.takenDown.concat([value]), 'value'),
    });
  }

  removeTakedown(value: Domain.TakedownStatus.TakedownStatus) {
    return new TakedownStatusValue({
      takenDown: this.takenDown.filter(
        (insight) => insight.value !== value.value,
      ),
    });
  }

  static get defaultValue(): TakedownStatusValue {
    return new TakedownStatusValue({
      takenDown: new Array<Domain.TakedownStatus.TakedownStatus>(),
    });
  }

  static get readFilterFromQuery(): TakedownStatusValue {
    const takeDownQuery = new URLSearchParams(window.location.search).getAll(
      'taken_down',
    );
    const takeDown = (takeDownQuery
      ?.map((insight) => GetTakeDownStatusFilterOptionByValue(`${insight}`))
      .filter((v) => !!v) || []) as Domain.TakedownStatus.TakedownStatus[];
    if (takeDown.length) {
      return new TakedownStatusValue({
        takenDown: takeDown,
      });
    }
    return TakedownStatusValue.defaultValue;
  }

  static readFromSavedFilter(props: any): TakedownStatusValue {
    const takeDownQuery = props.taken_down;
    const takeDown = (takeDownQuery
      ?.map((insight) => GetTakeDownStatusFilterOptionByValue(`${insight}`))
      .filter((v) => !!v) || []) as Domain.TakedownStatus.TakedownStatus[];
    if (takeDown.length) {
      return new TakedownStatusValue({
        takenDown: takeDown,
      });
    }
    return TakedownStatusValue.defaultValue;
  }
}
