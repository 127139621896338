import React from 'react';
import NaveeIcon from 'ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
} from '../../../types/filters/AtomicFilters/Filter';

export const NewProductFilterValue: DisplayFilterComponent = ({
  filterValue: { uuid, value },
  onClickRemove,
}: DisplayFilterComponentProps) => (
  <Tag
    key={uuid}
    tooltipText={value.path}
    tag={{
      ...value,
      name: value.displayName,
    }}
    iconPrefix={<NaveeIcon.Box width={12} height={12} />}
    onClickRemove={onClickRemove}
  />
);
