/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
/* eslint-disable import/no-import-module-exports */

// Needed for redux-saga es6 generator support
import { Router } from 'react-router-dom';

import './assets/fonts/stylesheet.css';
import { enableMapSet } from 'immer';

// Import all the third party stuff

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Gleap from 'gleap';
import * as Sentry from '@sentry/react';
import 'sanitize.css/sanitize.css';

// Import root app
import App from 'containers/App/index';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved */
import '!file-loader?name=[name].[ext]!./assets/images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess';
import ScrollProvider from 'product-ui/src/providers/ScrollProvider';
import { AntdConfigProvider } from 'ui/src/themes/antd';
import dayjs from 'dayjs';
import { history, store } from './store';
import './workers';
import { ErrorBoundary } from './layout/ErrorBoundary';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const MOUNT_NODE = document.getElementById('app');

enableMapSet();
if (
  process.env.NODE_ENV === 'production' ||
  process.env.NODE_ENV === 'staging'
) {
  Sentry.init({
    release: process.env.RELEASE_NAME,
    environment: process.env.NODE_ENV,
    dsn: 'https://444e402965a0406e94fdd3266e16216e@o367604.ingest.sentry.io/4505476999675904',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/product-monitor.navee.co/,
        ],
      }),
      new Sentry.Replay(),
    ],
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!
    attachStacktrace: true,
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  Gleap.initialize('6GciW8elV19NwmCAEKH9nSN5KAV6dik9');
  Gleap.showFeedbackButton(false);
}

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ScrollProvider>
        <AntdConfigProvider>
          <Router history={history}>
            <ErrorBoundary style={{ height: '100vh' }}>
              <App />
            </ErrorBoundary>
          </Router>
        </AntdConfigProvider>
      </ScrollProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

declare const module: any;
if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en')]))
    .catch((err) => {
      throw err;
    });
} else {
  render();
}
