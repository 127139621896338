import { v4 as uuid } from 'uuid';
import { ModerationFilterComponent } from 'components/Filters/NewModerationFilter';
import { NewModerationFilterValue } from 'components/Filters/NewModerationFilter/NewModerationFilterValue';
import { ModerationStatus } from 'product-types/src/domain/ModrationStatus/ModrationStatus';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { ModerationMethodEnum } from 'types/src/domain/moderationMethod/ModerationMethod';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { ModerationFilterValue } from './ModerationFilterValue';

export interface ModerationFilterQueryValue extends QueryValue {
  account_moderation_status: Array<ModerationStatus>;
  image_moderation_status: Array<ModerationStatus>;
  post_moderation_status: Array<ModerationStatus>;
  method: ModerationMethodEnum;
}

export class ModerationFilter implements Filter {
  uuid: string;

  label: string;

  value: ModerationFilterValue;

  constructor(
    params?: Partial<Pick<ModerationFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || ModerationFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [
      ...this.value.post.map((status) => ({
        name: FilterTypeEnum.postModerationStatus,
        value: status,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.postModerationStatus + status.value,
      })),
      ...this.value.image.map((status) => ({
        name: FilterTypeEnum.imageModerationStatus,
        value: status,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.imageModerationStatus + status.value,
      })),
      ...this.value.account.map((status) => ({
        name: FilterTypeEnum.accountModerationStatus,
        value: status,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.accountModerationStatus + status.value,
      })),
      ...(this.value.method.value === ModerationMethodEnum.all
        ? []
        : [
            {
              name: FilterTypeEnum.moderationMethod,
              value: this.value,
              uuid: this.uuid,
              key: this.uuid + this.value.method.value,
            },
          ]),
    ];
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.postModerationStatus) {
      this.value = this.value.removePost(
        removingFilter.value as ModerationStatus,
      );
    } else if (removingFilter.name === FilterTypeEnum.imageModerationStatus) {
      this.value = this.value.removeImage(
        removingFilter.value as ModerationStatus,
      );
    } else if (removingFilter.name === FilterTypeEnum.accountModerationStatus) {
      this.value = this.value.removeAccount(
        removingFilter.value as ModerationStatus,
      );
    } else if (removingFilter.name === FilterTypeEnum.moderationMethod) {
      this.value = this.value.removeMethod();
    }
    return new ModerationFilter(this);
  }

  get component() {
    return ModerationFilterComponent;
  }

  get displayFilterComponent() {
    return NewModerationFilterValue;
  }

  get queryFilterValue(): ModerationFilterQueryValue {
    return {
      account_moderation_status: this.value.account,
      image_moderation_status: this.value.image,
      post_moderation_status: this.value.post,
      method: this.value.method.value,
    };
  }

  static readFilterFromQuery() {
    return new ModerationFilter({
      value: ModerationFilterValue.readFilterFromQuery(),
    });
  }

  static readFilterFromSavedFitler(props: any) {
    return new ModerationFilter({
      value: ModerationFilterValue.readFromSavedFilter(props),
    });
  }
}
