import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface FilterPresetsParams {
  filter_type: 'image' | 'post' | 'account';
}

interface FilterPresetsRequest extends EndpointRequests {
  params: FilterPresetsParams;
}

interface FilterPresetsResponse {}

const filterPresets = new Endpoint<FilterPresetsRequest, FilterPresetsResponse>(
  {
    url: '/api/me/filter_presets',
    method: 'GET',
    headers: {},
  },
);

export default filterPresets;
