import {
  AccountDescriptionModel,
  AccountDescriptionRaw,
} from 'product-types/src/domain/account/AccountDescription';
import { Contact, ContactRaw } from 'product-types/src/domain/contact/Contact';
import { Magnifiable } from '../../common/Magnifiable/Magnifiable';

export interface RelatedAccountRaw {
  id: string;
  domain_name: string;
  poster_name: string;
  total_posts: number;
  account_description: Array<AccountDescriptionRaw>;
  account_image_link: string;
  followers_count: number;
  contact_details: Array<ContactRaw>;
  shared_contact_details: Array<ContactRaw>;
}

export class RelatedAccount implements Magnifiable {
  _id: string;

  domainName: string;

  posterName: string;

  imageUrl: string;

  totalPosts: number;

  description: Array<AccountDescriptionModel>;

  followersCount: number;

  contacts: Array<Contact>;

  sharedContacts: Map<string, Contact>;

  constructor(
    prop: Pick<
      RelatedAccount,
      | '_id'
      | 'domainName'
      | 'posterName'
      | 'totalPosts'
      | 'description'
      | 'followersCount'
      | 'contacts'
      | 'sharedContacts'
      | 'imageUrl'
    >,
  ) {
    this._id = prop._id;
    this.domainName = prop.domainName;
    this.posterName = prop.posterName;
    this.totalPosts = prop.totalPosts;
    this.description = prop.description;
    this.followersCount = prop.followersCount;
    this.contacts = prop.contacts;
    this.sharedContacts = prop.sharedContacts;
    this.imageUrl = prop.imageUrl;
  }

  get midSizeImage() {
    return this.imageUrl;
  }

  get id() {
    return (this._id || '').slice(3);
  }

  get isPoster() {
    return this._id[0] === 'p';
  }

  get isWebsite() {
    return this._id[0] === 'w';
  }

  get linkToModerationPage() {
    return this.isPoster ? `/account/${this.id}` : `/website/${this.id}`;
  }

  static createFromRawModel(prop: RelatedAccountRaw) {
    return new RelatedAccount({
      _id: prop.id,
      domainName: prop.domain_name,
      posterName: prop.poster_name,
      totalPosts: prop.total_posts,
      imageUrl: prop.account_image_link,
      description: prop.account_description.map(
        AccountDescriptionModel.createFromRawModel,
      ),
      followersCount: prop.followers_count,
      contacts: (prop.contact_details || []).map(
        Contact.createFromRawModelInverse,
      ),
      sharedContacts: (prop.shared_contact_details || [])
        .map(Contact.createFromRawModelInverse)
        .reduce((acc, contact) => {
          acc.set(contact.id.toString(), contact);
          return acc;
        }, new Map<string, Contact>()),
    });
  }
}
