import { CanBeQueriable } from '../../network/Query/Query';
import { Sorting } from '../Sorting/Sorting';
import { Pagination } from '../Pagination/Pagination';

export class TableParams implements CanBeQueriable {
  pagination: Pagination;

  sorting: Sorting;

  constructor(prop?: Pick<TableParams, 'pagination' | 'sorting'>) {
    this.pagination = new Pagination(prop?.pagination);
    this.sorting = new Sorting(prop?.sorting);
  }

  static get defaultValue() {
    return new TableParams({
      pagination: new Pagination({
        offset: 0,
        page: 1,
        perpage: 30,
      }),
      sorting: Sorting.defaultValue,
    });
  }

  get queryFilterValue() {
    return {
      ...this.pagination.queryFilterValue,
      ...this.sorting.queryFilterValue,
    };
  }

  get filterState() {
    return JSON.stringify(this.queryFilterValue);
  }

  static readFromSavedFilter(props: any) {
    return props.table_params ?? TableParams.defaultValue;
  }
}
