import React, { forwardRef, useCallback } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Labellable } from 'ui/src/components/atoms/Interfaces/Labellable';
import { Changable } from 'ui/src/components/atoms/Interfaces/Changable';
import { Disablable } from 'ui/src/components/atoms/Interfaces/Disablable';
import HasChildren from 'ui/src/components/atoms/Interfaces/HasChildren';
import { Stylable } from 'ui/src/components/atoms/Interfaces/Stylable';
import { CheckboxRef, Checkbox as AntdChecbox } from 'antd';
import { useGetClassesFromObject } from '../Typography/hooks';

interface CheckboxProps
  extends Changable<boolean, CheckboxChangeEvent>,
    Labellable,
    Disablable,
    HasChildren,
    Stylable {
  id?: string;
  variant?: 'primary' | 'secondary';
  indeterminate?: boolean;
  checked?: boolean;
  blockEventPropagination?: boolean;
}

type CompoundedComponent = React.ForwardRefExoticComponent<
  CheckboxProps & React.RefAttributes<CheckboxRef>
> & {
  Group: typeof AntdChecbox.Group;
};

const Checkbox: CompoundedComponent = forwardRef<CheckboxRef, CheckboxProps>(
  (props, ref) => {
    const variant = props.variant ?? 'primary';

    const changeHandler = useCallback(
      (e: CheckboxChangeEvent) => {
        if (props.onChange) {
          props.onChange(e.target.checked, e);
        }
        if (props.onClick) {
          props.onClick(e);
        }
      },
      [props.onChange, props.onClick],
    );

    const clickHandler = useCallback(
      (e: React.MouseEvent) => {
        if (props.blockEventPropagination) {
          e.stopPropagation();
          e.preventDefault();
        }
      },
      [props.blockEventPropagination],
    );

    const classes = useGetClassesFromObject({
      [variant]: true,
      [props.className ?? '']: !!props.className,
    });

    return (
      <AntdChecbox
        ref={ref}
        id={props.id}
        onClick={clickHandler}
        onChange={changeHandler}
        indeterminate={props.indeterminate}
        disabled={props.disabled}
        checked={props.checked}
        style={props.style}
        className={classes()}
      >
        {props.children ?? props.label}
      </AntdChecbox>
    );
  },
) as any;

Checkbox.Group = AntdChecbox.Group;

export default Checkbox;
