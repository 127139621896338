/*
 *
 * Navbar reducer
 *
 */
import { produce } from 'immer';
import { ExportTemplateModel } from 'types/src/domain/export/ExportTemplate';
import { FetchableData } from 'types/src/common/FetchableData/FetchableData';
import { MeModel } from 'types/src/domain/me/me';
import { GoalsRaw } from 'types/src/domain/goals/goals';
import {
  CHANGE_GOAL,
  PROFILE_DATA_LOADED,
  UPDATE_CREATE_ORGANISATION,
  UPDATE_GOALS,
  UPDATE_POST_EXPORT_TEMPLATES,
} from './constants';

export const createInitialState = () => ({
  user: FetchableData.default as FetchableData<MeModel>,
  goals: {
    original: FetchableData.default as FetchableData<GoalsRaw>,
    draft: {} as GoalsRaw,
  },
  exportTemplates: {
    post: FetchableData.default as FetchableData<Array<ExportTemplateModel>>,
    image: FetchableData.default as FetchableData<Array<ExportTemplateModel>>,
    website: FetchableData.default as FetchableData<Array<ExportTemplateModel>>,
    account: FetchableData.default as FetchableData<Array<ExportTemplateModel>>,
    cluster: FetchableData.default as FetchableData<Array<ExportTemplateModel>>,
  },
  notifications_viewed: [],
  organization: {},
  organisation: {
    infringing_keywords: [],
  },
  profile_loaded: {
    role: {},
  },
  createOrganisation: FetchableData.default,
});

export type NavbarState = ReturnType<typeof createInitialState>;
/* eslint-disable default-case, no-param-reassign */
const navbarReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_GOAL:
        draft.goals.draft[action.field] = action.value;
        break;
      case UPDATE_GOALS: {
        draft.goals.original = action.goals;
        draft.goals.draft = action.goals.data ?? {};
        break;
      }
      case PROFILE_DATA_LOADED:
        draft.user = action.user;
        break;
      case UPDATE_POST_EXPORT_TEMPLATES: {
        draft.exportTemplates.post = action.post;
        break;
      }
      case UPDATE_CREATE_ORGANISATION: {
        draft.createOrganisation = action.payload;
        break;
      }
    }
  });

export default navbarReducer;
