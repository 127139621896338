import { v4 as uuid } from 'uuid';
import { UploadStatusFilterC } from 'components/Filters/uploadStatusFilter';
import { UploadStatusFilterValue } from 'components/Filters/uploadStatusFilter/UploadStatusFilterValue';
import { QueryValue } from 'product-types/src/network/Query/Query';
import * as Domain from 'product-types/src/domain/Domain';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { UploadStatusValue } from './UploadStatusValue';

export interface UploadStatusFilterQueryValue extends QueryValue {
  upload_status: Array<Domain.UploadStatus.UploadStatus>;
}

export class UploadStatusFilter implements Filter {
  uuid: string;

  label: string;

  value: UploadStatusValue;

  constructor(
    params?: Partial<Pick<UploadStatusFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || UploadStatusValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return this.value.selectedUploadStatuses.map(
      (uploadStatus: Domain.UploadStatus.UploadStatus) => ({
        name: FilterTypeEnum.labelTypeInclude,
        value: uploadStatus,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.labelTypeInclude + uploadStatus.value,
      }),
    );
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    this.value = this.value.removeUploadStatus(
      removingFilter.value as Domain.UploadStatus.UploadStatus,
    );
    return new UploadStatusFilter(this);
  }

  get component() {
    return UploadStatusFilterC;
  }

  get displayFilterComponent() {
    return UploadStatusFilterValue;
  }

  get queryFilterValue(): UploadStatusFilterQueryValue {
    return {
      upload_status: this.value.selectedUploadStatuses,
    };
  }

  static readFilterFromQuery(): UploadStatusFilter {
    return new UploadStatusFilter({
      value: UploadStatusValue.readFilterFromQuery,
    });
  }

  static readFilterFromSavedFitler(props: any): UploadStatusFilter {
    return new UploadStatusFilter({
      value: UploadStatusValue.readFromSavedFilter(props),
    });
  }
}
