import React from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';
import HasChildren from 'ui/src/components/atoms/Interfaces/HasChildren';

export const defaultThemeConfig: ThemeConfig = {
  components: {
    Input: {
      fontFamily: 'Satoshi',
      activeBorderColor: 'var(--primary-green)',
      hoverBorderColor: 'var(--neutral-grey-700)',
      paddingBlock: 12,
      controlHeightLG: 40,
      paddingInline: 12,
      activeShadow:
        '0px -1px 1px 0px rgba(218, 227, 225, 0.15) inset, 0px 2px 2px 0px rgba(218, 227, 225, 0.10)',
      errorActiveShadow: '',
    },
    InputNumber: {
      fontFamily: 'Satoshi',
      activeBorderColor: 'var(--primary-green)',
      hoverBorderColor: 'var(--neutral-grey-700)',
      paddingBlock: 12,
      paddingInline: 12,
      activeShadow:
        '0px -1px 1px 0px rgba(218, 227, 225, 0.15) inset, 0px 2px 2px 0px rgba(218, 227, 225, 0.10)',
      errorActiveShadow: '',
    },

    Checkbox: {
      colorPrimaryHover: 'var(--primary-green)',
      colorBorder: 'var(--primary-border)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryBorder: 'var(--primary-border)',
      colorWhite: 'var(--primary-black)',
      controlInteractiveSize: 24,
      borderRadius: 8,
      borderRadiusLG: 8,
      borderRadiusSM: 8,
      borderRadiusXS: 8,
      fontFamily: 'Satoshi',
      fontSize: 14,
      lineHeight: 1.428571428571429,
      paddingContentVertical: 8,
      paddingContentHorizontal: 4,
      margin: 8,
    },
    Select: {
      optionPadding: '0.75em 1em',
      optionActiveBg: 'var(--primary-hover)',
      optionSelectedBg: 'var(--primary-green)',
      paddingContentVertical: 12,
      paddingContentHorizontal: 16,
      borderRadius: 4,
      controlHeight: 48,
      controlHeightLG: 48,
      controlHeightSM: 48,
      colorBorder: 'var(--primary-border)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryHover: 'var(--primary-black)',
      multipleSelectorBgDisabled: 'var(--neutral-grey-100)',
      colorTextDisabled: 'var(--neutral-grey-400)',
      colorIcon: 'var(--neutral-grey-500)',
      controlPaddingHorizontalSM: 12,
      controlPaddingHorizontal: 12,
    },
    DatePicker: {
      controlHeight: 48,
      cellWidth: 37,
      cellHeight: 32,
      colorTextLightSolid: 'black',
      colorPrimary: 'var(--primary-green)',
      activeBorderColor: 'var(--primary-green)',
      hoverBorderColor: 'var(--primary-black)',
      cellActiveWithRangeBg: 'var(--mint-green-lighter10)',
    },
    Form: {
      itemMarginBottom: 0,
    },
    Tree: {
      nodeHoverBg: 'var(--primary-hover)',
      nodeSelectedBg: 'var(--primary-green)',
      titleHeight: 40,
    },
    Tabs: {
      inkBarColor: 'var(--primary-green)',
      itemColor: 'var(--grey-grey-500)',
      itemHoverColor: 'var(--custom-green)',
      itemSelectedColor: 'var(--grey-grey-800)',
      fontFamily: 'Satoshi',
      fontWeightStrong: 700,
      horizontalItemPadding: '20px',
      horizontalItemGutter: 0,
    },
    Menu: {
      itemActiveBg: 'var(--primary-hover)',
      itemSelectedBg: 'var(--primary-hover)',
      itemSelectedColor: 'var(--grey-grey-800)',
    },
    Spin: {
      colorPrimary: '#00f380',
    },
    Table: {
      fontSize: 12,
      fontSizeSM: 12,
      fontSizeLG: 12,
      fontSizeXL: 12,
      fontWeightStrong: 700,
      fontFamily: 'Satoshi',
      colorTextHeading: 'var(--neutral-grey-600)',
      lineHeightLG: 1.5,
      lineHeightSM: 1.5,
      lineHeight: 1.5,
      rowExpandedBg: 'var(--primary-bg)',
      rowHoverBg: 'var(--green-tints-green-1)',
      padding: 16,
    },
    Layout: {
      bodyBg: 'var(--primary-bg)',
    },
    Upload: {
      borderRadiusLG: 10,
      colorBorder: 'var(--green-tints-green-5)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryHover: 'var(--green-tints-green-7)',
    },
    Tooltip: {
      colorBgSpotlight: 'var(--neutral-grey-800)',
      colorText: 'var(--white)',
      colorTextLightSolid: 'var(--white)',
      zIndexPopup: 10000000,
      borderRadius: 4,
      borderRadiusLG: 4,
      borderRadiusSM: 4,
      borderRadiusOuter: 4,
      borderRadiusXS: 4,
      fontFamily: 'Satoshi',
      sizePopupArrow: 16,
      fontSize: 12,
      lineHeight: 1.5,
    },
  },
  token: {
    borderRadius: 4,
    boxShadow: 'none',
    colorBorder: 'var(--primary-border)',
    fontFamily: 'Satoshi',
  },
};
export const AntdConfigProvider = ({
  children,
  popupContainer,
}: HasChildren & { popupContainer?: HTMLElement }) => (
  <ConfigProvider
    getPopupContainer={popupContainer && (() => popupContainer)}
    theme={defaultThemeConfig}
  >
    {children}
  </ConfigProvider>
);
