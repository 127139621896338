import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { readFilterFromQueryProps as WebsiteProps } from '../AtomicFiltersImplementation/Website/WebsiteFilterValue';
import { tagsFilterParams } from '../AtomicFiltersImplementation/TagsUploadHistory/TagsFilterValue';
import { Filter, FilterNamespace } from '..';
import { DisplayingFilterValue } from '../AtomicFilters/DisplayingFilterValue';
import { FiltersContainer } from '../MoleculesFilter/MolecileFilter';
import { DateFilterQueryValue } from '../AtomicFiltersImplementation/Date/DateFilter';
import { WebsiteFilterQueryValue } from '../AtomicFiltersImplementation/Website/WebsiteFilter';
import { TagsUploadHistoryFilterQueryValue } from '../AtomicFiltersImplementation/TagsUploadHistory/TagsFilter';
import { UploadTypeFilterQueryValue } from '../AtomicFiltersImplementation/UploadType/UploadTypeFilter';
import { UploadStatusFilterQueryValue } from '../AtomicFiltersImplementation/UploadStatus/UploadStatusFilter';
import { TableParamsQueryValue } from '../AtomicFiltersImplementation/TableParams/TableParamsFilter';
import { SearchQueryValue } from '../AtomicFiltersImplementation/Search/Search';

export interface ReadFromQueryParamsUploadHistory extends WebsiteProps {
  tags: tagsFilterParams;
}

export interface UploadHistoryQueryValue
  extends QueryValue,
    DateFilterQueryValue,
    WebsiteFilterQueryValue,
    TagsUploadHistoryFilterQueryValue,
    UploadTypeFilterQueryValue,
    UploadStatusFilterQueryValue,
    TableParamsQueryValue,
    SearchQueryValue {}

export interface UploadHistoryFiltersModelParams {
  currentFilters?: Array<FilterNamespace.Filter>;
  uuid?: string;
}

export class UploadHistoryFiltersModel implements FiltersContainer {
  uuid: string;

  currentFilters: Array<FilterNamespace.Filter>;

  constructor(params?: UploadHistoryFiltersModelParams) {
    this.uuid = params?.uuid ?? uuid();
    this.currentFilters = params?.currentFilters || this.createEmptyFilters();
  }

  get queryParams(): UploadHistoryQueryValue {
    return {
      ...(this.currentFilters[0] as Filter.Date).queryFilterValue,
      ...(this.currentFilters[1] as Filter.Website).queryFilterValue,
      ...(this.currentFilters[2] as Filter.TagsUploadHistory).queryFilterValue,
      ...(this.currentFilters[3] as Filter.UploadType).queryFilterValue,
      ...(this.currentFilters[4] as Filter.UploadStatus).queryFilterValue,
      ...(this.currentFilters[5] as Filter.TableParams).queryFilterValue,
      ...(this.currentFilters[6] as Filter.Search).queryFilterValue,
    };
  }

  get queryParamsAsString(): QueryValue {
    // todo: read actual values from filters and return type with exact keys
    return JSON.stringify(this.queryParams);
  }

  get displayingFilters(): Array<DisplayingFilterValue> {
    return this.currentFilters
      .map((filter) => filter.displayingFilterValue)
      .flat(1);
  }

  removeFilterValue(filterToDelete: DisplayingFilterValue) {
    return new UploadHistoryFiltersModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (filter.uuid === filterToDelete.uuid) {
            filter.removeFilterValue(filterToDelete as DisplayingFilterValue);
          }
          return filter;
        },
      ),
    });
  }

  updateFilterValue(filterToUpdate: FilterNamespace.Filter) {
    return new UploadHistoryFiltersModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (filter.uuid === filterToUpdate.uuid) {
            return filterToUpdate;
          }
          return filter;
        },
      ),
    });
  }

  createEmptyFilters() {
    return [
      new Filter.Date(),
      new Filter.Website(),
      new Filter.TagsUploadHistory(),
      new Filter.UploadType(),
      new Filter.UploadStatus(),
      new Filter.TableParams(),
      new Filter.Search(),
    ];
  }

  createFiltersFromQuery(props: ReadFromQueryParamsUploadHistory) {
    return [
      Filter.Date.readFilterFromQuery(),
      Filter.Website.readFilterFromQuery(props),
      Filter.TagsUploadHistory.readFilterFromQuery(props?.tags),
      Filter.UploadType.readFilterFromQuery(),
      Filter.UploadStatus.readFilterFromQuery(),
      Filter.TableParams.readFilterFromQuery(),
      Filter.Search.readFilterFromQuery(),
    ];
  }

  get dateFilter() {
    return this.currentFilters[0];
  }

  get searchFilter(): Filter.Search {
    return this.currentFilters[6] as Filter.Search;
  }

  resetFilters() {
    this.currentFilters = this.createEmptyFilters();
    return new UploadHistoryFiltersModel(this);
  }

  readFilterFromQuery(props: ReadFromQueryParamsUploadHistory) {
    return new UploadHistoryFiltersModel({
      uuid: this.uuid,
      currentFilters: this.createFiltersFromQuery(props),
    });
  }
}
