import React from 'react';
import { ReactComponent as MarketplaceIcon } from 'product-ui/src/icons/navee-icons/marketplace.svg';
import { ReactComponent as SocialMediaIcon } from 'product-ui/src/icons/navee-icons/socialMedia.svg';
import { ReactComponent as EcommerceIcon } from 'product-ui/src/icons/navee-icons/ecommerceIcon.svg';
import { ReactComponent as NewsAndBlogsIcon } from 'product-ui/src/icons/navee-icons/newsAndBlogsIcon.svg';
import { ReactComponent as CompanyIcon } from 'product-ui/src/icons/navee-icons/companyIcon.svg';
import { ReactComponent as HostingIcon } from 'product-ui/src/icons/navee-icons/hostingIcon.svg';
import { ReactComponent as PriceAgregatorIcon } from 'product-ui/src/icons/navee-icons/priceWebsiteAgregatorIcon.svg';
import { ReactComponent as OtherIcon } from 'product-ui/src/icons/navee-icons/otherIcon.svg';
import { ReactComponent as RedirectIcon } from 'product-ui/src/icons/navee-icons/redirecticon.svg';
import { ReactComponent as DeadIcon } from 'product-ui/src/icons/navee-icons/deadIcon.svg';

export const getWebsiteCategoryIcon = (categoryName) => {
  switch (categoryName) {
    case 'Marketplace':
      return <MarketplaceIcon />;
    case 'Social Media':
      return <SocialMediaIcon />;
    case 'Ecommerce':
      return <EcommerceIcon />;
    case 'News & Blogs':
      return <NewsAndBlogsIcon />;
    case 'Company Website':
      return <CompanyIcon />;
    case 'Hosting':
      return <HostingIcon />;
    case 'Price/Website Aggregator':
      return <PriceAgregatorIcon />;
    case 'Other':
      return <OtherIcon />;
    case 'Redirect':
      return <RedirectIcon />;
    case 'Dead':
      return <DeadIcon />;
    default:
      return null;
  }
};
