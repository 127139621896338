import React, { useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Flex, Divider } from 'antd';
import { Sorting } from 'types/src/common/Sorting/Sorting';
import { TableParams } from 'types/src/common/TableParams/TableParams';
import { Pagination } from 'types/src/common/Pagination/Pagination';
import Typography from 'ui/src/components/atoms/Typography';
import NaveeIcon from 'ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Logo } from 'components/Logo/Logo';
import {
  setClustersFilterAction,
  setFeedFilterAction,
  setUploadHistoryFiltersAction,
} from 'layout/FiltersBar/actions';
import { ChipStatus } from 'ui/src/components/atoms/ChipStatus';
import useNavigation from '../../hooks/useNavigation';
import { TableParamsFilter } from '../../types/filters/AtomicFiltersImplementation/TableParams/TableParamsFilter';
import { AppState } from '../../store/storeAccess';
import { NaveeSidebar } from './styled';
import NotificationMenuItem from './Navigation/NotificationNavigation';
import UserMenu from './Navigation/UserMenu/UserMenu';
import { UploadMenuItem } from './Navigation/UploadMenuItem';

export function Sidebar() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: AppState) => state.loginPage);
  const { navigate, calcLink } = useNavigation();
  const tableParamsFeed = useSelector((state: AppState) =>
    state.filters_bar.feed.currentFilters.find(
      (filter) => filter instanceof TableParamsFilter,
    ),
  );
  const tableParamsCluster = useSelector((state: AppState) =>
    state.filters_bar.clusters.currentFilters.find(
      (filter) => filter instanceof TableParamsFilter,
    ),
  );
  const tableParamsUploadHistory = useSelector((state: AppState) =>
    state.filters_bar.uploadHistory.currentFilters.find(
      (filter) => filter instanceof TableParamsFilter,
    ),
  );

  const resetPagination = (newRoute: string) => {
    switch (newRoute) {
      case '/upload/history': {
        dispatch(
          setUploadHistoryFiltersAction(
            new TableParamsFilter({
              ...tableParamsUploadHistory,
              value: new TableParams({
                pagination: new Pagination({
                  offset: 0,
                  page: 1,
                  perpage: 30,
                }),
                sorting: Sorting.getValueByPathname(newRoute),
              }),
            }),
          ),
        );
        break;
      }
      case '/cluster': {
        dispatch(
          setClustersFilterAction(
            new TableParamsFilter({
              ...tableParamsCluster,
              value: new TableParams({
                pagination: new Pagination({
                  offset: 0,
                  page: 1,
                  perpage: 30,
                }),
                sorting: Sorting.getValueByPathname(newRoute),
              }),
            }),
          ),
        );
        break;
      }
      default: {
        dispatch(
          setFeedFilterAction(
            new TableParamsFilter({
              ...tableParamsFeed,
              value: new TableParams({
                pagination: new Pagination({
                  offset: 0,
                  page: 1,
                  perpage: 30,
                }),
                sorting: Sorting.getValueByPathname(newRoute),
              }),
            }),
          ),
        );
      }
    }
  };

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prevState) => !prevState);
  }, [collapsed]);

  const handleChageLocationWithKey = useCallback(
    ({ key }) => {
      resetPagination(calcLink(key));
      navigate(calcLink(key));
    },
    [calcLink],
  );

  const defaultSelectedKey = useMemo(
    () => [`/${pathname.split('/')[2] || ''}`],
    [pathname],
  );

  const demo = (style: React.CSSProperties) => (
    <div
      style={{
        display: 'inline-flex',
        ...style,
      }}
    >
      <ChipStatus
        label="DEMO"
        variant="active"
        style={{
          padding: '1px 8px',
        }}
      />
    </div>
  );

  return (
    <NaveeSidebar trigger={null} collapsed={collapsed}>
      <Menu
        defaultSelectedKeys={defaultSelectedKey}
        selectedKeys={defaultSelectedKey}
        mode="inline"
      >
        <Menu.Item className="logo-block" key="/" disabled>
          <Flex vertical gap="4px">
            <Flex align="center">
              <NaveeIcon.Menu
                className="navee_svg menu"
                onClick={toggleCollapsed}
              />
              {!collapsed && <Logo />}
            </Flex>
            {!collapsed &&
              currentUser.data &&
              !currentUser.data.organisation?.isProduction &&
              demo({ padding: '0 2rem' })}
            {collapsed &&
              currentUser.data &&
              !currentUser.data.organisation?.isProduction &&
              demo({ marginLeft: '-0.5rem' })}
          </Flex>
        </Menu.Item>
        {(currentUser.data?.role?.canAccessDashboard ?? true) && (
          <Menu.Item
            key="/dashboard"
            onClick={handleChageLocationWithKey}
            title="Dashboard"
          >
            <NaveeIcon.Dashboard className="navee_svg" />
            <Typography
              textTransform="uppercase"
              variant="xsmall"
              hidden={collapsed}
              fontWeight={pathname.includes('dashboard') ? 'bold' : 'medium'}
              color={
                pathname.includes('dashboard')
                  ? 'var(--primary-black)'
                  : 'var(--neutral-grey-500)'
              }
            >
              Dashboard
            </Typography>
          </Menu.Item>
        )}
        <Menu.Item
          key="/image"
          onClick={handleChageLocationWithKey}
          title="Image View"
        >
          <NaveeIcon.ImageView className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            hidden={collapsed}
            fontWeight={pathname.includes('image') ? 'bold' : 'medium'}
            color={
              pathname.includes('image')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Image View
          </Typography>
        </Menu.Item>
        <Menu.Item
          key="/post"
          onClick={handleChageLocationWithKey}
          title="Post View"
        >
          <NaveeIcon.Post className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            fontWeight={pathname.includes('post') ? 'bold' : 'medium'}
            hidden={collapsed}
            color={
              pathname.includes('post')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Post View
          </Typography>
        </Menu.Item>
        <Menu.Item
          key="/account"
          onClick={handleChageLocationWithKey}
          title="Account View"
        >
          <NaveeIcon.Account className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            hidden={collapsed}
            fontWeight={pathname.includes('account') ? 'bold' : 'medium'}
            color={
              pathname.includes('account')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Account View
          </Typography>
        </Menu.Item>
        <Menu.Item
          key="/website"
          onClick={handleChageLocationWithKey}
          title="Website View"
        >
          <NaveeIcon.Website className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            hidden={collapsed}
            fontWeight={pathname.includes('website') ? 'bold' : 'medium'}
            color={
              pathname.includes('website')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Website View
          </Typography>
        </Menu.Item>
        <Menu.Item
          key="/cluster"
          onClick={handleChageLocationWithKey}
          title="Cluster View"
        >
          <NaveeIcon.Vendor className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            fontWeight={pathname.includes('cluster') ? 'bold' : 'medium'}
            hidden={collapsed}
            color={
              pathname.includes('cluster')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Cluster View
          </Typography>
        </Menu.Item>
        <Menu.Item
          title="Upload"
          disabled
          style={{
            marginTop: 'auto',
          }}
          key="/upload"
        >
          <UploadMenuItem
            collapsed={collapsed}
            updateLocation={handleChageLocationWithKey}
          />
        </Menu.Item>
        <Divider style={{ margin: '12px 0' }} />
        <Menu.Item key="/notifications" onClick={handleChageLocationWithKey}>
          <NotificationMenuItem collapsed={collapsed} />
        </Menu.Item>
        <Menu.Item className="user-menu-block" disabled key="/settings">
          <UserMenu collapsed={collapsed} />
        </Menu.Item>
      </Menu>
    </NaveeSidebar>
  );
}
