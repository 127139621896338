/* eslint-disable no-unused-vars */
import React, { Key, useCallback, useMemo, useState } from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Flex, TreeDataNode } from 'antd';
import Typography from 'product-ui/src/components/atoms/Typography';

import { Insight } from 'product-types/src/domain/insight/insight';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/storeAccess';
import { InsightFilter } from '../../../types/filters/AtomicFiltersImplementation/Insight/InsightFilter';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import { TreeStyled } from '../NewProductFilter/style';

export interface InsightFilterProps extends FilterParams {
  value: InsightFilter;
  onChange: (v: Filter) => void;
}

export const NewInsightFilter = (props: InsightFilterProps) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const insights = useSelector(
    (state: AppState) =>
      state.filters_bar.optionsLoadedData.insightOptions?.data ?? [],
  );
  const onCheck = useCallback(
    (labelType: string[]) => {
      props.onChange(
        new InsightFilter({
          ...props.value,
          value: props.value.value.setInsight(
            labelType
              .map((newInsight) => {
                const nodes = insights.slice();
                while (nodes.length > 0) {
                  const node = nodes.pop()!;
                  if (node.value === newInsight) {
                    return node;
                  }
                  nodes.push(...node.children);
                }
                return null;
              })
              .filter((v) => !!v) as Domain.Insight.Insight[],
          ),
        }),
      );
    },
    [insights, props.onChange, props.value],
  );
  const treeData = useMemo(() => {
    const loop = (data: Array<Insight>): Array<TreeDataNode> =>
      data
        .map((item) => {
          const title = (
            <Typography
              tag="span"
              variant="small"
              color="var(--neutral-grey-800)"
            >
              {item.label}
            </Typography>
          );
          return {
            title,
            label: item.label,
            key: item.value,
            selectable: false,
            children: loop(item.children ?? []),
          };
        })
        .sort((a, b) => {
          if (a.children.length !== b.children.length) {
            return b.children.length - a.children.length;
          }
          return a.label
            .toLocaleLowerCase()
            .localeCompare(b.label.toLocaleLowerCase());
        });

    return loop(insights ?? []);
  }, [insights]);

  const onExpand = (newExpandedKeys: Key[]) => {
    setExpandedKeys(newExpandedKeys);
  };

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          padding: '0.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 275,
          minHeight: 60,
          maxHeight: '50vh',
          overflowY: 'auto',
        }}
      >
        <TreeStyled
          checkable
          blockNode
          multiple
          showLine
          defaultExpandAll
          treeData={treeData}
          checkedKeys={props.value.value.selectedInsightsWithChildren.map(
            (c) => c.value!,
          )}
          expandedKeys={expandedKeys}
          onCheck={onCheck}
          onExpand={setExpandedKeys}
          switcherIcon={({ expanded }) =>
            expanded ? (
              <NaveeIcon.MinusOutlined width={16} height={16} />
            ) : (
              <NaveeIcon.PlusOutlined width={16} height={16} />
            )
          }
        />
      </Flex>
    ),
    [
      treeData,
      props.value.value.selectedInsightsWithChildren,
      onCheck,
      expandedKeys,
      onExpand,
    ],
  );
  return (
    <FilterWithMenuWrapper
      text="Insight"
      renderer={renderer}
      count={props.value.displayingFilterValue.length}
    />
  );
};
