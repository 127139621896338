import {
  OrganisationModel,
  OrganisationRawModel,
} from '../organisation/Organisation';
import { RoleModel, RoleRaw } from '../role/Role';

export interface UserRawModel {
  email: string;
  email_recap_frequency: string;
  email_when_new_counterfeit: boolean;
  id: number;
  name: string;
  organisation: OrganisationRawModel;
  profile_picture_link: string;
  role: RoleRaw;
  tutorial_mode: boolean;
}

export class UserModel {
  email: string;

  emailRecapFrequency: string;

  emailWhenNewCounterfeit: boolean;

  id: number;

  name: string;

  organisation: OrganisationModel;

  profilePictureLink: string;

  role: RoleModel;

  tutorialMode: boolean;

  constructor(
    prop: Pick<
      UserModel,
      | 'email'
      | 'emailRecapFrequency'
      | 'emailWhenNewCounterfeit'
      | 'id'
      | 'name'
      | 'organisation'
      | 'profilePictureLink'
      | 'role'
      | 'tutorialMode'
    >,
  ) {
    this.email = prop.email;
    this.emailRecapFrequency = prop.emailRecapFrequency;
    this.id = prop.id;
    this.emailWhenNewCounterfeit = prop.emailWhenNewCounterfeit;
    this.name = prop.name;
    this.organisation = prop.organisation;
    this.profilePictureLink = prop.profilePictureLink;
    this.role = prop.role;
    this.tutorialMode = prop.tutorialMode;
  }

  static createFromRaw(prop: UserRawModel) {
    return new UserModel({
      email: prop.email,
      emailRecapFrequency: prop.email_recap_frequency,
      emailWhenNewCounterfeit: prop.email_when_new_counterfeit,
      id: prop.id,
      name: prop.name,
      organisation: prop.organisation
        ? OrganisationModel.createFromRawModel(prop.organisation)
        : null!,
      profilePictureLink: prop.profile_picture_link,
      role: prop.role ? RoleModel.createFromRoleRaw(prop.role) : null!,
      tutorialMode: prop.tutorial_mode,
    });
  }
}
