import { UserModel } from '../../domain/user/User';

export class Moderatable {
  canEditModeration: boolean;

  canEditQaCheck: boolean;

  canEditReasons: boolean;

  canChangeCategory: boolean = false;

  canEditValidation: boolean;

  canViewQaCheck: boolean;

  canViewValidation: boolean;

  moderation: string | null;

  moderationMethod: string | null;

  moderationColor: string | null;

  moderatedBy?: UserModel | null | undefined;

  moderatedDate?: Date | null;

  qaChecked: boolean | null | undefined;

  qaCheckedDate?: Date | null;

  qaCheckedBy?: UserModel | null;

  validated: boolean | null;

  validatedDate?: Date | null;

  validatedBy?: UserModel | null;

  constructor(
    props: Pick<
      Moderatable,
      | 'moderationMethod'
      | 'canEditModeration'
      | 'canEditQaCheck'
      | 'canEditReasons'
      | 'canEditValidation'
      | 'canViewQaCheck'
      | 'canViewValidation'
      | 'moderation'
      | 'moderationColor'
      | 'qaChecked'
      | 'validated'
    > &
      Partial<{
        canChangeCategory: boolean;
        moderatedBy: UserModel;
        qaCheckedBy: UserModel;
        validatedBy: UserModel;
        qaCheckedDate: Date;
        moderatedDate: Date;
        validatedDate: Date;
      }>,
  ) {
    this.canEditModeration = props.canEditModeration || false;
    this.canEditQaCheck = props.canEditQaCheck || false;
    this.canEditReasons = props.canEditReasons || false;
    this.canViewQaCheck = props.canViewQaCheck || false;
    this.canViewValidation = props.canViewValidation || false;
    this.canChangeCategory = props.canChangeCategory || false;
    this.canEditValidation = props.canEditValidation || false;
    this.moderation = props.moderation || null;
    this.moderationColor = props.moderationColor || null;
    this.qaChecked = props.qaChecked || false;
    this.validated = props.validated || false;
    this.moderatedBy = props.moderatedBy;
    this.qaCheckedBy = props.qaCheckedBy;
    this.validatedBy = props.validatedBy;
    this.moderatedDate = props.moderatedDate;
    this.qaCheckedDate = props.qaCheckedDate;
    this.validatedDate = props.validatedDate;
    this.moderationMethod = props.moderationMethod;
  }

  get moderatedByLabel() {
    if (this.validatedBy) {
      return this.validatedBy.name ?? 'Validated';
    }
    if (this.qaCheckedBy) {
      return this.qaCheckedBy.name ?? 'Checked';
    }
    if (this.moderationMethod === 'indirect_moderation_through_rule') {
      return 'an automated rule';
    }
    if (this.moderatedBy) {
      return this.moderatedBy.name ?? 'Moderated';
    }
    return '';
  }

  static get default() {
    return new Moderatable({
      canEditModeration: false,
      canEditQaCheck: false,
      canEditReasons: false,
      canEditValidation: false,
      canChangeCategory: false,
      canViewQaCheck: false,
      canViewValidation: false,
      moderation: null,
      moderationColor: null,
      qaChecked: null,
      moderationMethod: null,
      validated: null,
    });
  }
}

export interface CanBeModeratable {
  moderation: Moderatable;
}
