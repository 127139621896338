import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface SaveFilterRequest extends EndpointRequests {}

interface SaveFilterResponse {}

const saveFilter = new Endpoint<SaveFilterRequest, SaveFilterResponse>({
  url: '/api/me/filter_presets',
  method: 'POST',
  headers: {},
});

export default saveFilter;
