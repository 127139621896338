import { SelectProps } from 'antd';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import Button from 'product-ui/src/components/atoms/Button/Button';
import React from 'react';
import { Select } from 'product-ui/src/components/atoms/Select';
import { StyledWrapper } from './styled';

interface WebsiteCategoryTabProps {
  onSelectCategory: (
    operation: 'include' | 'exclude',
    categories: Array<WebsiteCategory>,
  ) => void;
  onClearCategories: (operation: 'include' | 'exclude') => void;
  websiteCategoryInclude: Array<WebsiteCategory>;
  websiteCategoryExclude: Array<WebsiteCategory>;
  applyFilters: () => void;
  categories: WebsiteCategory[];
  showApplyButton?: boolean;
  maxTagCount: SelectProps['maxTagCount'];
  maxTagPlaceholder: SelectProps['maxTagPlaceholder'];
}
export const WebsiteCategoryTab = ({
  onSelectCategory,
  applyFilters,
  onClearCategories,
  websiteCategoryInclude,
  websiteCategoryExclude,
  categories,
  showApplyButton,
  maxTagCount,
  maxTagPlaceholder,
}: WebsiteCategoryTabProps) => (
  <StyledWrapper vertical gap="1rem">
    <Select
      fieldNames={{ value: 'id', label: 'name' }}
      value={websiteCategoryInclude.map((c) => ({
        label: c.name,
        value: c.id,
      }))}
      optionFilterProp="name"
      onSelect={(_, ctgs: WebsiteCategory) => {
        onSelectCategory(
          'include',
          [ctgs].map((c) => ({
            id: c.id,
            name: c.name,
            category_rank: c.category_rank,
          })),
        );
      }}
      onClear={() => onClearCategories('include')}
      placeholder="Included Websites Categories..."
      notFoundContent="No options"
      mode="multiple"
      options={(categories || []).filter(
        (c) =>
          websiteCategoryExclude.find((w) => w.id === c.id) === undefined &&
          websiteCategoryInclude.find((w) => w.id === c.id) === undefined,
      )}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={maxTagPlaceholder}
    />
    <Select
      fieldNames={{ value: 'id', label: 'name' }}
      value={websiteCategoryExclude.map((c) => ({
        label: c.name,
        value: c.id,
      }))}
      onSelect={(_, ctgs: WebsiteCategory) => {
        onSelectCategory(
          'exclude',
          [ctgs].map((c) => ({
            id: c.id,
            name: c.name,
            category_rank: c.category_rank,
          })),
        );
      }}
      optionFilterProp="name"
      onClear={() => onClearCategories('exclude')}
      placeholder="Excluded Websites Categories..."
      notFoundContent="No options"
      mode="multiple"
      options={(categories || []).filter(
        (c) =>
          websiteCategoryExclude.find((w) => w.id === c.id) === undefined &&
          websiteCategoryInclude.find((w) => w.id === c.id) === undefined,
      )}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={maxTagPlaceholder}
    />
    {showApplyButton && applyFilters ? (
      <Button onClick={applyFilters} size="Small" label="Apply" />
    ) : null}
  </StyledWrapper>
);
