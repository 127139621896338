import {
  GetTakeModerationStatusFilterOptionByValue,
  ModerationStatus,
  ModerationStatusEnum,
} from 'product-types/src/domain/ModrationStatus/ModrationStatus';
import {
  GetModerationMethodStatusFilterOptionByValue,
  ModerationMethod,
  ModerationMethodDictionary,
  ModerationMethodEnum,
} from 'product-types/src/domain/moderationMethod/ModerationMethod';
import { FilterValue } from '../../AtomicFilters/FilterValue';
export class ModerationFilterValue implements FilterValue {
  post: Array<ModerationStatus>;

  image: Array<ModerationStatus>;

  account: Array<ModerationStatus>;

  method: ModerationMethod;

  constructor(
    params?: Pick<
      ModerationFilterValue,
      'post' | 'image' | 'account' | 'method'
    >,
  ) {
    this.post = params?.post || [];
    this.image = params?.image || [];
    this.account = params?.account || [];
    this.method = params?.method || ModerationMethodDictionary.all;
  }

  static get defaultValue(): ModerationFilterValue {
    return new ModerationFilterValue();
  }

  get smallestModerationStatus() {
    const set = new Set();
    this.post.forEach((post) => set.add(post.value));
    this.image.forEach((image) => set.add(image.value));
    this.account.forEach((account) => set.add(account.value));
    if (set.has(ModerationStatusEnum.moderated)) {
      return ModerationStatusEnum.moderated;
    }
    if (set.has(ModerationStatusEnum.checked)) {
      return ModerationStatusEnum.checked;
    }
    if (set.has(ModerationStatusEnum.validated)) {
      return ModerationStatusEnum.validated;
    }
    return null;
  }

  addPost(value: ModerationStatus[]) {
    return new ModerationFilterValue({
      post: value,
      image: this.image,
      account: this.account,
      method: this.method,
    });
  }

  removePost(removePost: ModerationStatus) {
    return new ModerationFilterValue({
      post: this.post.filter((post) => post.value !== removePost.value),
      image: this.image,
      account: this.account,
      method: this.method,
    });
  }

  addImage(value: ModerationStatus[]) {
    return new ModerationFilterValue({
      post: this.post,
      image: value,
      account: this.account,
      method: this.method,
    });
  }

  removeImage(removeImage: ModerationStatus) {
    return new ModerationFilterValue({
      post: this.post,
      image: this.image.filter((post) => post.value !== removeImage.value),
      account: this.account,
      method: this.method,
    });
  }

  addAccount(value: ModerationStatus[]) {
    return new ModerationFilterValue({
      post: this.post,
      image: this.image,
      account: value,
      method: this.method,
    });
  }

  removeAccount(removeAccount: ModerationStatus) {
    return new ModerationFilterValue({
      post: this.post,
      image: this.image,
      account: this.account.filter(
        (post) => post.value !== removeAccount.value,
      ),
      method: this.method,
    });
  }

  removeMethod() {
    return new ModerationFilterValue({
      post: this.post,
      image: this.image,
      account: this.account,
      method: ModerationMethodDictionary.all,
    });
  }

  changeMethod(method: ModerationMethodEnum) {
    return new ModerationFilterValue({
      post: this.post,
      image: this.image,
      account: this.account,
      method:
        GetModerationMethodStatusFilterOptionByValue(method) ||
        ModerationMethodDictionary.all,
    });
  }

  static readFilterFromQuery(): ModerationFilterValue {
    const urlParams = new URLSearchParams(window.location.search);
    const accounts = urlParams.getAll('account_moderation_status');
    const images = urlParams.getAll('image_moderation_status');
    const posts = urlParams.getAll('post_moderation_status');
    const moderationMethodQuery = new URLSearchParams(
      window.location.search,
    ).get('moderation_method');
    const uploadType = GetModerationMethodStatusFilterOptionByValue(
      moderationMethodQuery,
    );
    return new ModerationFilterValue({
      post: posts
        .map(GetTakeModerationStatusFilterOptionByValue)
        .filter((v) => v !== undefined) as Array<ModerationStatus>,
      image: images
        .map(GetTakeModerationStatusFilterOptionByValue)
        .filter((v) => v !== undefined) as Array<ModerationStatus>,
      account: accounts
        .map(GetTakeModerationStatusFilterOptionByValue)
        .filter((v) => v !== undefined) as Array<ModerationStatus>,
      method: uploadType || ModerationMethodDictionary.all,
    });
  }

  static readFromSavedFilter(props: any): ModerationFilterValue {
    const accounts = props.account_moderation_status || [];
    const images = props.image_moderation_status || [];
    const posts = props.post_moderation_status || [];
    const moderationMethodQuery = props.moderation_method;
    const uploadType = GetModerationMethodStatusFilterOptionByValue(
      moderationMethodQuery,
    );
    return new ModerationFilterValue({
      post: posts
        .map(GetTakeModerationStatusFilterOptionByValue)
        .filter((v) => v !== undefined) as Array<ModerationStatus>,
      image: images
        .map(GetTakeModerationStatusFilterOptionByValue)
        .filter((v) => v !== undefined) as Array<ModerationStatus>,
      account: accounts
        .map(GetTakeModerationStatusFilterOptionByValue)
        .filter((v) => v !== undefined) as Array<ModerationStatus>,
      method: uploadType || ModerationMethodDictionary.all,
    });
  }
}
