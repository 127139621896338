import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { NewProductFilter } from 'components/Filters/NewProductFilter/index';
import { NewProductFilterValue } from 'components/Filters/NewProductFilter/NewProductFilterValue';
import * as Domain from 'product-types/src/domain/Domain';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import {
  NonCircularCategory,
  ProductFilterValue,
  readFilterFromQueryProps,
} from './ProductValue';

export interface ProductFilterQueryValue extends QueryValue {
  categoryId: Array<NonCircularCategory>;
}

export class ProductFilter implements Filter {
  uuid: string;

  label: string;

  value: ProductFilterValue;

  constructor(
    params?: Partial<Pick<ProductFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || ProductFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return this.value.displayCategories.map((productCategory) => ({
      name: FilterTypeEnum.categoryId,
      value: productCategory,
      uuid: this.uuid,
      key: this.uuid + FilterTypeEnum.categoryId + productCategory.id,
    }));
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.categoryId) {
      this.value = this.value.removeCategory(
        removingFilter.value as Domain.ProductCategory.ProductCategory,
      );
    }
    return new ProductFilter(this);
  }

  get component() {
    return NewProductFilter;
  }

  get displayFilterComponent() {
    return NewProductFilterValue;
  }

  get queryFilterValue(): ProductFilterQueryValue {
    return {
      categoryId: this.value.categories,
    };
  }

  static readFilterFromQuery(props: readFilterFromQueryProps): ProductFilter {
    return new ProductFilter({
      value: ProductFilterValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: any,
    props: readFilterFromQueryProps,
  ): ProductFilter {
    return new ProductFilter({
      value: ProductFilterValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
