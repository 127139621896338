import { MeRaw } from 'product-types/src/domain/me/me';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UserRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
  params: {
    timezone_offset: number;
    start_date: string;
    end_date: string;
  };
}

const getUser = new Endpoint<UserRequestModel, MeRaw>({
  url: '/api/users/:id',
  method: 'GET',
  headers: {},
});

export default getUser;
