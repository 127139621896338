/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { produce } from 'immer';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { PostFeedDataModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import {
  LOAD_POST_DATA_SUCCESS,
  RESET_STATE,
  UPDATE_POSTS_COUNT,
  UPDATE_POST_MODERATION_STATE,
} from './constants';

export const createInitialState = () => ({
  postsData: FetchableData.default as FetchableData<PostFeedDataModel>,
  postsCount: FetchableData.default as FetchableData<number>,
  postsModerationState: FetchableDataState.NOT_LOADED,
});

export type PostFeedState = ReturnType<typeof createInitialState>;

const postViewReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case LOAD_POST_DATA_SUCCESS:
        draft.postsData = action.postsData;
        break;
      case UPDATE_POSTS_COUNT:
        draft.postsCount = action.postsCount;
        break;
      case UPDATE_POST_MODERATION_STATE:
        draft.postsModerationState = action.state;
        break;
    }
  });

export default postViewReducer;
