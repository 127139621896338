/* eslint-disable default-case, no-param-reassign, no-case-declarations */
import { produce } from 'immer';
import * as Domain from 'product-types/src/domain/Domain';
import { DisplayingFilterValue } from 'types/filters/AtomicFilters/DisplayingFilterValue';
import { FiltersContainer } from 'types/filters/MoleculesFilter/MolecileFilter';
import { Filter } from 'types/filters/AtomicFilters/Filter';
import { DateFilterValue } from 'types/filters/AtomicFiltersImplementation/Date/Value';
import { DateFilterValueEnum } from 'product-types/src/domain/date/Date';
import { DashboardFiltersModel } from 'types/filters/MoleculesFilterImplementation/DashboardFilterModel';
import { VendorFilterModel } from 'types/filters/MoleculesFilterImplementation/VendorFilterModel';
import { FeedFilterModel } from 'types/filters/MoleculesFilterImplementation/FeedFilterModel';
import { UploadHistoryFiltersModel } from 'types/filters/MoleculesFilterImplementation/UploadHistoryFiltersModel';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { CrawlingDateFilterValue } from 'types/filters/AtomicFiltersImplementation/CrawlingDate/CrawlingDateFilterValue';
import { SearchFilter } from 'types/filters/AtomicFiltersImplementation/Search/Search';
import { AdvancedStatistics } from 'product-types/src/domain/advancedStats/AdvancedStats';
import {
  FINISH_MODERATION,
  START_MODERATION,
} from 'containers/ModerationPage/constants';
import {
  ADD_LOADED_WEBSITES,
  ADD_UPLOADED_IMAGE_FILTERS_BAR,
  LOAD_FILTERS_SUCCESS,
  READ_CLUSTERS_FILTERS,
  READ_DASHBOARD_FILTERS,
  READ_FEED_FILTERS,
  READ_UPLOAD_HISTORY_FILTERS,
  REMOVE_CLUSTERS_FILTER,
  REMOVE_DASHBOARD_FILTER,
  REMOVE_FEED_FILTER,
  REMOVE_UPLOAD_HISTORY_FILTERS,
  REPLACE_FEED_FILTER,
  RESET_ALL_FILTERS,
  RESET_ALL_FILTERS_EXCEPT_ONE,
  RESET_CLUSTERS_FILTER,
  RESET_FEED_FILTER,
  RESET_STATE,
  RESET_UPLOAD_HISTORY_FILTERS,
  SELECT_FILTER,
  SET_CLUSTERS_FILTER,
  SET_DASHBOARD_FILTER,
  SET_DEFAULT_DATE,
  SET_FEED_FILTERS,
  SET_IMAGE_LOADING,
  SET_ORGANISATION_STATUSES,
  SET_SHOW_ALL_POSTS_RESERVED_MODAL,
  SET_UPLOAD_HISTORY_FILTERS,
  UPDATE_ADVANCED_STATISTICS,
  UPDATE_CURRENCIES,
  UPDATE_FILTER_BAR_CATEGORIES,
  UPDATE_FILTERS_BAR_ACCOUNT_LABELS,
  UPDATE_FILTERS_BAR_AVAILABLE_CONTACT_TYPES,
  UPDATE_FILTERS_BAR_FEATURE_LABELS,
  UPDATE_FILTERS_BAR_GEOGRAPHY,
  UPDATE_FILTERS_BAR_IMAGE_LABELS,
  UPDATE_FILTERS_BAR_POST_LABELS,
  UPDATE_FILTERS_BAR_TAGS,
  UPDATE_FILTERS_BAR_WEBSITE_CATEGORIES,
  UPDATE_FILTERS_BAR_WEBSITES,
  UPDATE_HIDE_MODERATION_STATISTICS,
  UPDATE_IMAGE_FOR_SEARCH,
  UPDATE_INSIGHTS,
  UPDATE_ROLES,
  UPDATE_USERS,
} from './constants';
import {
  createDashboardFiltersInitialState,
  createFeedFiltersInitialState,
  createLoadedDataInitialState,
  createUploadHistoryFiltersInitialState,
  createVendorsFiltersInitialState,
  LoadedDataState,
  SearchBarState,
} from './filtersInitialStates';
import { LoadImageForSearchProps } from './actions';
import { DuplicatedGroupSearchItemModelAdditionalInfo } from '../../types/filters/AtomicFiltersImplementation/Search/SearchItem';

// todo: check this parameters
export const createInitialFilterState = () => ({
  advancedStatistics:
    FetchableData.default as FetchableData<AdvancedStatistics>,
  reducerMounted: true,
  show_filters: true,
  hideAdvancedStatistics: true,
  not_found_images: [],
  searching_images: [],
  found_images: [],

  // TODO - UNkNOWN/UNUSED PROPERTIES WILL BE REMOVED AFTER CHECKINGS
  label_type: [],
  displayModal: false,

  // TODO - SAVED FILTERS PROPERTIES
  filters: {
    image: [],
    post: [],
    account: [],
  },
  // TODO - SHOULDN'T HAVE ANY RELATION TO FILTERS
  isModeration: false,
});

type initialState = typeof createInitialFilterState;
export interface FiltersBarState extends initialState {
  reducerMounted: boolean;
  advancedStatistics: FetchableData<AdvancedStatistics>;
  dashboard: DashboardFiltersModel;
  clusters: VendorFilterModel;
  feed: FeedFilterModel;
  uploadHistory: UploadHistoryFiltersModel;
  optionsLoadedData: LoadedDataState;
  globalData: FetchableData<Domain.GlobalData>;
  search: SearchBarState;
  show_filters: boolean;
  filters: {
    image: [];
    post: [];
    account: [];
  };
  not_found_images: [];
  searching_images: [];
  found_images: [];
  hideAdvancedStatistics: boolean;
  isModeration: boolean;
}

export const createInitialState = (): FiltersBarState =>
  ({
    ...createInitialFilterState(),
    dashboard: createDashboardFiltersInitialState(),
    clusters: createVendorsFiltersInitialState(),
    feed: createFeedFiltersInitialState(),
    uploadHistory: createUploadHistoryFiltersInitialState(),
    optionsLoadedData: createLoadedDataInitialState(),
    search: {
      post_url: [],
      post_id: [],
    } as any,
  }) as any;

/* eslint-disable default-case, no-param-reassign */
const filtersBarReducer = (state = createInitialState(), action) =>
  produce(state, (draft: FiltersBarState) => {
    switch (action.type) {
      // THIS LINE OF ACTIONS ALSO SHOULD BE SEPARATED IN NEXT COMMITS
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case UPDATE_HIDE_MODERATION_STATISTICS: {
        draft.hideAdvancedStatistics = action.value;
        break;
      }
      case SELECT_FILTER:
        // Force the (re)rendering of the resulting posts
        draft[action.field] = Array.isArray(action.value)
          ? [...new Set(action.value)]
          : action.value;
        break;
      case SET_IMAGE_LOADING:
        draft.searching_images = [...draft.searching_images, action.id];
        break;
      case ADD_UPLOADED_IMAGE_FILTERS_BAR:
        const {
          image: { image_found, image_link, fuid },
        } = action;
        if (image_found) {
          draft.found_images = [...draft.found_images, action.image];
        } else {
          draft.not_found_images = [...draft.not_found_images, image_link];
        }

        const searching_images = [...draft.search.searching_images];
        draft.search.searching_images = searching_images.filter(
          (uid) => uid !== fuid,
        );
        break;
      case SET_DEFAULT_DATE:
        const { interval } = action;
        DateFilterValue.setDefaultDate(interval as DateFilterValueEnum);
        CrawlingDateFilterValue.setDefaultDate(interval as DateFilterValueEnum);
        break;
      case RESET_ALL_FILTERS: {
        draft.uploadHistory = draft.uploadHistory.resetFilters();
        draft.feed = draft.feed.resetFilters();
        draft.dashboard = draft.dashboard.resetFilters();
        draft.clusters = draft.clusters.resetFilters();
        break;
      }
      case RESET_ALL_FILTERS_EXCEPT_ONE: {
        const { except } = action;
        switch (except) {
          case 'feed':
            draft.uploadHistory = draft.uploadHistory.resetFilters();
            draft.dashboard = draft.dashboard.resetFilters();
            draft.clusters = draft.clusters.resetFilters();
            break;
          case 'uploadHistory':
            draft.feed = draft.feed.resetFilters();
            draft.dashboard = draft.dashboard.resetFilters();
            draft.clusters = draft.clusters.resetFilters();
            break;
          case 'dashboard':
            draft.uploadHistory = draft.uploadHistory.resetFilters();
            draft.feed = draft.feed.resetFilters();
            draft.clusters = draft.clusters.resetFilters();
            break;
          case 'clusters':
            draft.uploadHistory = draft.uploadHistory.resetFilters();
            draft.feed = draft.feed.resetFilters();
            draft.dashboard = draft.dashboard.resetFilters();
            break;
        }
        break;
      }
      case UPDATE_ADVANCED_STATISTICS: {
        draft.advancedStatistics = action.advancedStatistics;
        break;
      }
      case START_MODERATION:
        draft.isModeration = true;
        break;
      case FINISH_MODERATION:
        draft.isModeration = false;
        break;
      case SET_SHOW_ALL_POSTS_RESERVED_MODAL:
        if (action.show === true) {
          draft.displayModal = true;
          draft.modal_text = action.modal_text
            ? action.modal_text
            : 'All posts are reserved';
          draft.modal_title = action.modal_title;
        } else if (action.show === false) {
          draft.displayModal = false;
          draft.modal_text = '';
        }
        break;
      case UPDATE_FILTERS_BAR_TAGS: {
        draft.optionsLoadedData.tags = {
          ...draft.optionsLoadedData.tags,
          [action.tagType]: action.tags,
        };
        break;
      }
      case UPDATE_FILTER_BAR_CATEGORIES:
        draft.optionsLoadedData.availableCategories = action.categories;
        break;
      case UPDATE_FILTERS_BAR_GEOGRAPHY: {
        draft.optionsLoadedData.geographySelectOptions =
          action.geographySelectOptions;
        break;
      }
      case UPDATE_FILTERS_BAR_POST_LABELS:
        draft.optionsLoadedData.availableLabels = action.labels;
        break;
      case UPDATE_FILTERS_BAR_IMAGE_LABELS:
        draft.optionsLoadedData.availableImageLabels = action.imageLabels;
        break;
      case UPDATE_INSIGHTS:
        draft.optionsLoadedData.insightOptions = action.insightOptions;
        break;
      case UPDATE_FILTERS_BAR_WEBSITES:
        draft.optionsLoadedData.websites = action.websites;
        break;
      case SET_ORGANISATION_STATUSES:
        draft.optionsLoadedData.organisationStatuses = action.organisationStatuses;
        break;
      case UPDATE_FILTERS_BAR_AVAILABLE_CONTACT_TYPES:
        draft.optionsLoadedData.availableContactTypes =
          action.availableContactTypes;
        break;
      case UPDATE_FILTERS_BAR_FEATURE_LABELS: {
        draft.optionsLoadedData.featureLabels = action.featureLabels;
        break;
      }
      case UPDATE_FILTERS_BAR_WEBSITE_CATEGORIES: {
        draft.optionsLoadedData.availableWebsiteCategories =
          action.availableWebsiteCategories;
        break;
      }
      case UPDATE_USERS: {
        draft.optionsLoadedData.users = action.users;
        break;
      }
      case UPDATE_ROLES: {
        draft.optionsLoadedData.roles = action.roles;
        break;
      }
      case UPDATE_CURRENCIES: {
        draft.optionsLoadedData.currencies = action.currencies;
        break;
      }
      case ADD_LOADED_WEBSITES:
        const map = new Map();
        const newWebsiteArray = (
          draft.optionsLoadedData.websites.data ?? []
        ).concat(...action.websites);
        newWebsiteArray.forEach((website: Domain.Website.WebsiteSuggestion) => {
          map.set(website.id, website);
        });
        draft.optionsLoadedData.websites = new FetchableData<
          Array<WebsiteSuggestion>
        >({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: [...map.values()],
          error: null,
        });
        break;
      case UPDATE_FILTERS_BAR_ACCOUNT_LABELS:
        draft.optionsLoadedData.accountLabels = action.accountLabels;
        break;
      case LOAD_FILTERS_SUCCESS:
        draft.filters = action.filters;
        break;
      case SET_DASHBOARD_FILTER: {
        const { updates }: { updates: Filter } = action;
        draft.dashboard = draft.dashboard.updateFilterValue(updates);
        break;
      }
      case REMOVE_DASHBOARD_FILTER: {
        const { updates }: { updates: DisplayingFilterValue } = action;
        draft.dashboard = draft.dashboard.removeFilterValue(updates);
        break;
      }
      case READ_DASHBOARD_FILTERS: {
        draft.dashboard = draft.dashboard.readFilterFromQuery({
          availableCategories:
            state.optionsLoadedData.availableCategories.data ?? [],
          availableWebsiteCategories:
            state.optionsLoadedData.availableWebsiteCategories.data ?? [],
          websites: state.optionsLoadedData.websites.data ?? [],
          geos: state.optionsLoadedData.geographySelectOptions.data ?? {
            countries: [],
            zones: [],
          },
          users: state.optionsLoadedData.users.data || [],
          tags: {
            account:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.account]
                .data ?? [],
            post:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.post].data ??
              [],
            duplicatedGroup:
              state.optionsLoadedData.tags[
                Domain.Tag.TagTypeEnum.duplicatedGroup
              ].data ?? [],
            vendor:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.vendor]
                .data ?? [],
          },
          labels: {
            account: state.optionsLoadedData.accountLabels.data ?? [],
            post: state.optionsLoadedData.availableLabels.data ?? [],
            image: state.optionsLoadedData.availableImageLabels.data ?? [],
          },
          reasons: state.optionsLoadedData.featureLabels.data || [],
        });
        break;
      }
      case SET_CLUSTERS_FILTER: {
        const { updates }: { updates: Filter } = action;
        draft.clusters = draft.clusters.updateFilterValue(updates);
        break;
      }
      case REMOVE_CLUSTERS_FILTER: {
        const { updates }: { updates: DisplayingFilterValue } = action;
        draft.clusters = draft.clusters.removeFilterValue(updates);
        break;
      }
      case RESET_CLUSTERS_FILTER: {
        draft.clusters = draft.clusters.resetFilters();
        break;
      }
      case READ_CLUSTERS_FILTERS: {
        draft.clusters = draft.clusters.readFilterFromQuery({
          availableWebsiteCategories:
            state.optionsLoadedData.availableWebsiteCategories.data ?? [],
          websites: state.optionsLoadedData.websites.data ?? [],
          geos: state.optionsLoadedData.geographySelectOptions.data ?? {
            countries: [],
            zones: [],
          },
          users: state.optionsLoadedData.users.data || [],
          tags: {
            account:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.account]
                .data ?? [],
            post:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.post].data ??
              [],
            duplicatedGroup:
              state.optionsLoadedData.tags[
                Domain.Tag.TagTypeEnum.duplicatedGroup
              ].data ?? [],
            vendor:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.vendor]
                .data ?? [],
          },
        });
        break;
      }
      // HERE IS SEPARATE HANDLERS FOR FEED FILTERS
      case SET_FEED_FILTERS: {
        const { updates }: { updates: Filter } = action;
        draft.feed = draft.feed.updateFilterValue(updates);
        break;
      }
      case REMOVE_FEED_FILTER: {
        const { updates }: { updates: DisplayingFilterValue } = action;
        draft.feed = draft.feed.removeFilterValue(updates);
        break;
      }
      case REPLACE_FEED_FILTER: {
        const { updates }: { updates: FiltersContainer } = action;
        draft.feed = updates.feed;
        break;
      }
      case RESET_FEED_FILTER: {
        draft.feed = draft.feed.resetFilters();
        break;
      }
      case READ_FEED_FILTERS: {
        draft.feed = draft.feed.readFilterFromQuery({
          reasons: state.optionsLoadedData.featureLabels.data || [],
          availableWebsiteCategories:
            state.optionsLoadedData.availableWebsiteCategories.data ?? [],
          websites: state.optionsLoadedData.websites.data ?? [],
          users: state.optionsLoadedData.users.data || [],
          geos: state.optionsLoadedData.geographySelectOptions.data ?? {
            countries: [],
            zones: [],
          },
          contactTypes:
            state.optionsLoadedData.availableContactTypes.data || [],
          insightOptions: state.optionsLoadedData.insightOptions.data ?? [],
          availableCategories:
            state.optionsLoadedData.availableCategories.data ?? [],
          labels: {
            account: state.optionsLoadedData.accountLabels.data ?? [],
            post: state.optionsLoadedData.availableLabels.data ?? [],
            image: state.optionsLoadedData.availableImageLabels.data ?? [],
          },
          tags: {
            account:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.account]
                .data ?? [],
            post:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.post].data ??
              [],
            duplicatedGroup:
              state.optionsLoadedData.tags[
                Domain.Tag.TagTypeEnum.duplicatedGroup
              ].data ?? [],
            vendor:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.vendor]
                .data ?? [],
          },
        });
        break;
      }
      // HERE IS SEPARATE HANDLERS FOR UPLOADHISTORY FILTERS
      case SET_UPLOAD_HISTORY_FILTERS: {
        const { updates }: { updates: Filter } = action;
        draft.uploadHistory = draft.uploadHistory.updateFilterValue(updates);
        break;
      }
      case REMOVE_UPLOAD_HISTORY_FILTERS: {
        const { updates }: { updates: DisplayingFilterValue } = action;
        draft.uploadHistory = draft.uploadHistory.removeFilterValue(updates);
        break;
      }
      case RESET_UPLOAD_HISTORY_FILTERS: {
        draft.uploadHistory = draft.uploadHistory.resetFilters();
        break;
      }
      case READ_UPLOAD_HISTORY_FILTERS: {
        draft.uploadHistory = draft.uploadHistory.readFilterFromQuery({
          availableWebsiteCategories:
            state.optionsLoadedData.availableWebsiteCategories.data ?? [],
          tags: {
            uploadHistory:
              state.optionsLoadedData.tags[Domain.Tag.TagTypeEnum.uploadHistory]
                .data ?? [],
          },
          websites: state.optionsLoadedData.websites.data ?? [],
        });
        break;
      }
      case UPDATE_IMAGE_FOR_SEARCH: {
        const { searchFilterUUID, searchItem } =
          action as LoadImageForSearchProps;
        const additionalInfo =
          searchItem.additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo;
        switch (searchFilterUUID) {
          case draft.feed.uuid: {
            const { searchFilter } = draft.feed;
            draft.feed = draft.feed.updateFilterValue(
              new SearchFilter({
                ...searchFilter,
                value:
                  additionalInfo.status === FetchableDataState.NOT_FOUND
                    ? searchFilter.value.remove(searchItem)
                    : searchFilter.value.updateValue(searchItem),
              }),
            );
            break;
          }
          case draft.clusters.uuid: {
            const { searchFilter } = draft.clusters;
            draft.clusters = draft.clusters.updateFilterValue(
              new SearchFilter({
                ...searchFilter,
                value: searchFilter.value.updateValue(searchItem),
              }),
            );
            break;
          }
          case draft.uploadHistory.uuid: {
            const { searchFilter } = draft.uploadHistory;
            draft.uploadHistory = draft.uploadHistory.updateFilterValue(
              new SearchFilter({
                ...searchFilter,
                value: searchFilter.value.updateValue(searchItem),
              }),
            );
            break;
          }
          default: {
            throw new Error('Unknown filter type');
          }
        }
      }
    }
  });

export default filtersBarReducer;
