import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import moderationReason from './moderationReason/index';
import organisation from './organisation/index';
import geography from './geography/index';
import geographyZones from './geographyZones/index';
import topWebsites from './topWebsites/index';
import websiteNames from './websiteNames/index';
import categories from './categories/index';
import productCategories from './productCategories';
import postLabels from './postLabels/index';
import imageLabels from './imageLabels/index';
import accountLabels from './accountLabels/index';
import deleteFilter from './deleteFilter/index';
import deleteGeography from './deleteGeography/index';
import addGeography from './addGeography/index';
import websiteSearch from './websiteSearch/index';
import advancedStats from './advancedStats/index';
import saveFilter from './saveFilter/index';
import filterPresets from './filterPresets/index';
import saveGoals from './saveGoals/index';
import getMe from './me/index';
import updateMe from './updateMe/index';
import goals from './goals/index';
import globalData from './globalData';

export interface ClusterEndpoints extends EndpointContainer {
  getMe: typeof getMe;
  globalData: typeof globalData;
  updateMe: typeof updateMe;
  moderationReason: typeof moderationReason;
  organisation: typeof organisation;
  geography: typeof geography;
  geographyZones: typeof geographyZones;
  topWebsites: typeof topWebsites;
  websiteNames: typeof websiteNames;
  websiteSearch: typeof websiteSearch;
  categories: typeof categories;
  productCategories: typeof productCategories;
  accountLabels: typeof accountLabels;
  postLabels: typeof postLabels;
  imageLabels: typeof imageLabels;
  deleteFilter: typeof deleteFilter;
  saveFilter: typeof saveFilter;
  deleteGeography: typeof deleteGeography;
  addGeography: typeof addGeography;
  advancedStats: typeof advancedStats;
  filterPresets: typeof filterPresets;
  saveGoals: typeof saveGoals;
  goals: typeof goals;
}

const me: ClusterEndpoints = {
  getMe,
  globalData,
  updateMe,
  moderationReason,
  organisation,
  accountLabels,
  geography,
  geographyZones,
  categories,
  productCategories,
  topWebsites,
  websiteNames,
  websiteSearch,
  imageLabels,
  postLabels,
  deleteFilter,
  deleteGeography,
  addGeography,
  advancedStats,
  saveFilter,
  filterPresets,
  saveGoals,
  goals,
  injectHTTPClient(client: HttpClient) {
    getMe.injectHTTPClient(client);
    globalData.injectHTTPClient(client);
    updateMe.injectHTTPClient(client);
    moderationReason.injectHTTPClient(client);
    accountLabels.injectHTTPClient(client);
    organisation.injectHTTPClient(client);
    imageLabels.injectHTTPClient(client);
    categories.injectHTTPClient(client);
    productCategories.injectHTTPClient(client);
    geography.injectHTTPClient(client);
    geographyZones.injectHTTPClient(client);
    topWebsites.injectHTTPClient(client);
    websiteNames.injectHTTPClient(client);
    websiteSearch.injectHTTPClient(client);
    postLabels.injectHTTPClient(client);
    deleteFilter.injectHTTPClient(client);
    deleteGeography.injectHTTPClient(client);
    addGeography.injectHTTPClient(client);
    advancedStats.injectHTTPClient(client);
    saveFilter.injectHTTPClient(client);
    filterPresets.injectHTTPClient(client);
    saveGoals.injectHTTPClient(client);
    goals.injectHTTPClient(client);
  },
};

export default me;
