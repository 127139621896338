import {
  CanBeModeratable,
  Moderatable,
} from 'product-types/src/common/Moderatable/Moderatable';
import { HasModerationPage } from 'product-types/src/common/HasModerationPage/HasModerationPage';
import {
  AccountDescriptionModel,
  AccountDescriptionRaw,
} from 'product-types/src/domain/account/AccountDescription';
import { DomainModel } from 'product-types/src/domain/domain/Domain';
import { Category } from 'product-types/src/domain/category/Category';
import { UserModel, UserRawModel } from 'product-types/src/domain/user/User';
import { TagModel, TagRaw } from 'product-types/src/domain/tag/Tag';
import { GeoReasonModel } from 'product-types/src/domain/geo/Geo';
import { Contact, ContactRaw } from 'product-types/src/domain/contact/Contact';
import { Cluster } from 'product-types/src/domain/cluster/Cluster';
import { TakedownModel } from 'product-types/src/domain/takedownStatus/TakedownModel';
import dayjs, { Dayjs } from 'dayjs';
import { Magnifiable } from '../../common/Magnifiable/Magnifiable';
import { TakenDownable } from '../../common/TakenDownable/TakenDownable';

export interface AccountModerationRawModel {
  account_contacts: Array<ContactRaw>;
  account_description: Array<AccountDescriptionRaw>;
  account_external_link: string;
  account_image_link: string;
  consecutive_down_results: number | null;
  last_takedown_check_date: string | null;
  account_infringing_percentage: number;
  archive_link: string | null;
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_edit_reasons: boolean;
  can_edit_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  checked_by: UserRawModel | null;
  cluster: null | any;
  crawling_date: string;
  is_recrawlable: boolean;
  display_color: null | string;
  domain_name: string;
  domain_url: string;
  followers_count: number | null;
  geo: string;
  geo_country: string | null;
  id: string;
  infringement_type: string;
  is_desc_counterfeit: null | boolean;
  is_for_sale: null | boolean;
  median_price: number;
  moderated_by: UserRawModel | null;
  moderation_date: string;
  moderation_method: string;
  post_moderation_percentage: number;
  poster_id: number;
  poster_name: string;
  qa_check_date: null | Date;
  qa_checked: boolean;
  rank: number;
  tags: Array<TagRaw>;
  taken_down?: boolean | null;
  total_images_moderated: number;
  total_images_unmoderated: number;
  total_posts: number;
  total_posts_moderated: number;
  total_posts_unmoderated: number;
  validated: boolean;
  validated_by: UserRawModel | null;
  validation_date: string;
  website_category: Category;
  website_id: number;
}

export class AccountModerationModel
  implements CanBeModeratable, HasModerationPage, Magnifiable, TakenDownable
{
  moderation: Moderatable;

  _id: string;

  tags: Array<TagModel>;

  cluster: Cluster | null;

  description: Array<AccountDescriptionModel>;

  archiveLink: string | null;

  accountInfringingPercentage: number;

  imageLink: string;

  externalLink: string;

  isRecrawlable: boolean;

  domain: DomainModel;

  contacts: Array<Contact>;

  crawlingDate?: dayjs.Dayjs;

  name: string;

  postModerationPercentage: number;

  followersCount: number | null;

  takenDown?: boolean | null;

  geo: string;

  geoCountry: Array<GeoReasonModel>;

  totalPosts: number;

  totalPostsModerated: number;

  totalPostsUnmoderated: number;

  totalImagesModerated: number;

  isForSale: boolean | null;

  takeDown: TakedownModel;

  isDescCounterfeit: boolean | null;

  websiteCategory: Category;

  totalImagesUnmoderated: number;

  constructor(
    prop: Pick<
      AccountModerationModel,
      | 'crawlingDate'
      | 'takeDown'
      | 'followersCount'
      | 'geoCountry'
      | 'geo'
      | 'archiveLink'
      | 'contacts'
      | 'isForSale'
      | 'isDescCounterfeit'
      | 'domain'
      | 'takenDown'
      | 'isRecrawlable'
      | 'websiteCategory'
      | 'name'
      | 'imageLink'
      | 'externalLink'
      | 'description'
      | 'postModerationPercentage'
      | 'totalPostsModerated'
      | 'totalPostsUnmoderated'
      | 'totalPosts'
      | 'totalImagesModerated'
      | 'totalImagesUnmoderated'
      | 'accountInfringingPercentage'
      | 'tags'
      | 'cluster'
      | 'moderation'
      | '_id'
    >,
  ) {
    this.moderation = prop.moderation;
    this._id = prop._id;
    this.cluster = prop.cluster;
    this.tags = prop.tags;
    this.name = prop.name;
    this.takeDown = prop.takeDown;
    this.archiveLink = prop.archiveLink;
    this.takenDown = prop.takenDown;
    this.isRecrawlable = prop.isRecrawlable;
    this.geo = prop.geo;
    this.geoCountry = prop.geoCountry;
    this.followersCount = prop.followersCount;
    this.description = prop.description;
    this.domain = prop.domain;
    this.contacts = prop.contacts;
    this.isForSale = prop.isForSale;
    this.isDescCounterfeit = prop.isDescCounterfeit;
    this.websiteCategory = prop.websiteCategory;
    this.imageLink = prop.imageLink;
    this.externalLink = prop.externalLink;
    this.accountInfringingPercentage = prop.accountInfringingPercentage;
    this.postModerationPercentage = prop.postModerationPercentage;
    this.totalPostsModerated = prop.totalPostsModerated;
    this.totalPostsUnmoderated = prop.totalPostsUnmoderated;
    this.totalImagesModerated = prop.totalImagesModerated;
    this.totalImagesUnmoderated = prop.totalImagesUnmoderated;
    this.totalPosts = prop.totalPosts;
    this.crawlingDate = prop.crawlingDate;
  }

  get midSizeImage() {
    return this.imageLink;
  }

  get id() {
    return (this._id || '').slice(3);
  }

  get isPoster() {
    return true;
  }

  get isWebsite() {
    return false;
  }

  get title() {
    return `Account #${this.id}`;
  }

  get formattedName() {
    return `AC#${this.id}`;
  }

  get linkToModerationPage() {
    return `/account/${this.id}`;
  }

  get linkToAssociatedImagesFeedPage() {
    return `/image?date_filter_type=crawling_date&interval=until_today&account_poster_id=${this.id}`;
  }

  get linkToRelatedPostsFeedPage() {
    return `/post?date_filter_type=crawling_date&interval=until_today&account_poster_id=${this.id}`;
  }

  get linkToRelatedAccountsFeedPage() {
    return `/account?date_filter_type=crawling_date&interval=until_today&related_to_account_id=${this.id}`;
  }

  private static getFollowerCount(
    prop: AccountModerationRawModel,
  ): number | null {
    let result = prop.followers_count;
    if (!result) {
      result =
        prop.account_description &&
        prop.account_description[0] &&
        prop.account_description[0].followers_count;
    }

    return result;
  }

  static createFromRawModel(prop: AccountModerationRawModel) {
    return new AccountModerationModel({
      name: prop.poster_name,
      isRecrawlable: prop.is_recrawlable,
      geo: prop.geo,
      takeDown: new TakedownModel({
        consecutiveDownResults: prop.consecutive_down_results ?? 0,
        lastTakeDownCheckAt:
          (dayjs(prop.last_takedown_check_date).isValid() &&
            dayjs(prop.last_takedown_check_date)) ||
          null,
        takenDown: prop.taken_down,
      }),
      followersCount: AccountModerationModel.getFollowerCount(prop),
      geoCountry:
        (GeoReasonModel.checkIfJsonValid(prop.geo_country) &&
          GeoReasonModel.createFromRawString(prop.geo_country as string)) ||
        [],
      isForSale: prop.is_for_sale,
      archiveLink: prop.archive_link,
      contacts: (prop.account_contacts || []).map(Contact.createFromRawModel),
      isDescCounterfeit: prop.is_desc_counterfeit,
      takenDown: prop.taken_down,
      domain: new DomainModel({
        name: prop.domain_name,
        url: prop.domain_url,
      }),
      crawlingDate:
        (prop.crawling_date && dayjs(prop.crawling_date)) || undefined,
      websiteCategory: prop.website_category,
      imageLink: prop.account_image_link,
      externalLink: prop.account_external_link,
      description: prop.account_description.map(
        AccountDescriptionModel.createFromRawModel,
      ),
      accountInfringingPercentage: prop.account_infringing_percentage,
      postModerationPercentage: prop.post_moderation_percentage,
      totalImagesModerated: prop.total_images_moderated,
      totalImagesUnmoderated: prop.total_images_unmoderated,
      totalPostsModerated: prop.total_posts_moderated,
      totalPostsUnmoderated: prop.total_posts_unmoderated,
      totalPosts: prop.total_posts,
      _id: prop.id,
      cluster: prop.cluster
        ? new Cluster({
            contactInfo: prop.cluster.contact_info,
            description: prop.cluster.description,
            estimatedGeo: prop.cluster.estimated_geo,
            id: prop.cluster.id,
            imageLink: prop.cluster.image_link,
            infringementPercentage: prop.cluster.infringement_percentage,
            moderationPercentage: prop.cluster.moderation_percentage,
            name: prop.cluster.name,
            rank: prop.cluster.rank,
            totalPosts: prop.cluster.total_posts,
          })
        : null,
      tags: (prop.tags || []).map(TagModel.createFromRawModel),
      moderation: new Moderatable({
        canEditModeration: prop.can_edit_moderation,
        canEditQaCheck: prop.can_edit_qa_check,
        canEditReasons: prop.can_edit_reasons,
        canEditValidation: prop.can_edit_validation,
        canViewQaCheck: prop.can_view_qa_check,
        canViewValidation: prop.can_view_validation,
        moderation: prop.infringement_type,
        moderationColor: 'transparent',
        moderationMethod: prop.moderation_method,
        qaChecked: prop.qa_checked,
        validated: prop.validated,
        qaCheckedDate:
          (prop.qa_check_date &&
            (
              (dayjs as any).utc(prop.qa_check_date).local() as Dayjs
            ).toDate()) ||
          undefined,
        moderatedDate:
          (prop.moderation_date &&
            (
              (dayjs as any).utc(prop.moderation_date).local() as Dayjs
            ).toDate()) ||
          undefined,
        validatedDate:
          (prop.validation_date &&
            (
              (dayjs as any).utc(prop.validation_date).local() as Dayjs
            ).toDate()) ||
          undefined,
        moderatedBy:
          (prop.moderated_by && UserModel.createFromRaw(prop.moderated_by)) ||
          undefined,
        qaCheckedBy:
          (prop.checked_by && UserModel.createFromRaw(prop.checked_by)) ||
          undefined,
        validatedBy:
          (prop.validated_by && UserModel.createFromRaw(prop.validated_by)) ||
          undefined,
      }),
    });
  }
}
