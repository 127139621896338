import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { ContactMainInfo } from 'product-types/src/domain/contact/Contact';
import { AccountInformationFilter as AccountInformationFilterComponent } from 'components/Filters/AccountInformationFilter/index';
import { AccountInformationFilterValue } from 'components/Filters/AccountInformationFilter/AccountInformationFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import {
  AccountInformationValue,
  AllContactTypesOption,
} from './AccountInformationValue';

export interface AccountInformationQueryValue extends QueryValue {
  minimum_followers_count: number | null;
  maximum_followers_count: number | null;
  contact_types: Array<ContactMainInfo>;
}

export class AccountInformationFilter implements Filter {
  uuid: string;

  label: string;

  value: AccountInformationValue;

  constructor(
    params?: Partial<
      Pick<AccountInformationFilter, 'uuid' | 'label' | 'value'>
    >,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || AccountInformationValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    const contactTypeResults = this.value.hasAllContactTypes()
      ? [
        {
          name: FilterTypeEnum.contactTypes,
          value: AllContactTypesOption,
          uuid: this.uuid,
          key: this.uuid + AllContactTypesOption.value,
        },
      ]
      : this.value.contactTypes.map((contactType) => ({
        name: FilterTypeEnum.contactTypes,
        value: contactType,
        uuid: this.uuid,
        key: this.uuid + contactType.value,
      }));

    return [
      {
        name: FilterTypeEnum.minimumFollowersCount,
        value: this.value.followers.min as number,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.minimumFollowersCount + this.value,
      },
      {
        name: FilterTypeEnum.maximumFollowersCount,
        value: this.value.followers.max as number,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.maximumFollowersCount + this.value,
      },
      ...contactTypeResults,
    ].filter(
      ({ value, name }) =>
        (typeof value === 'number' && !Number.isNaN(value)) ||
        name === FilterTypeEnum.contactTypes,
    );
  }

  get component() {
    return AccountInformationFilterComponent;
  }

  get displayFilterComponent() {
    return AccountInformationFilterValue;
  }

  get queryFilterValue(): AccountInformationQueryValue {
    return {
      maximum_followers_count:
        this.value.followers.max !== null &&
          !Number.isNaN(this.value.followers.max)
          ? this.value.followers.max
          : null,
      minimum_followers_count:
        this.value.followers.min && !Number.isNaN(this.value.followers.min)
          ? this.value.followers.min
          : null,
      contact_types: this.value.hasAllContactTypes()
        ? [AllContactTypesOption]
        : this.value.contactTypes,
    };
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.contactTypes) {
      if (removingFilter.value.value === AllContactTypesOption.value) {
        this.value.contactTypes = [];
      } else {
        this.value.contactTypes = this.value.contactTypes.filter(
          (contactType) =>
            contactType.value !==
            (removingFilter.value as ContactMainInfo).value,
        );
      }
    } else if (removingFilter.name === FilterTypeEnum.minimumFollowersCount) {
      this.value.followers.min = null;
    } else if (removingFilter.name === FilterTypeEnum.maximumFollowersCount) {
      this.value.followers.max = null;
    }
    return new AccountInformationFilter(this);
  }

  static readFilterFromQuery(props: any): AccountInformationFilter {
    return new AccountInformationFilter({
      value: AccountInformationValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: any,
    props: any,
  ): AccountInformationFilter {
    return new AccountInformationFilter({
      value: AccountInformationValue.readFromSavedFilter(savedFilter, props),
    });
  }
}
