const isFunction = (value: any) => {
  return typeof value === 'function';
};

const isObject = (value: any) => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

const isString = (value: any) => {
  return typeof value === 'string';
};

export { isFunction, isObject, isString };
