import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { ProductInformationFilter as ProductInformationFilterComponent } from 'components/Filters/ProductInformationFilter/index';
import { ProductInformationFilterValue } from 'components/Filters/ProductInformationFilter/ProductInformationFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { ProductInformationValue } from './ProductInformationValue';

export interface ProductInformationQueryValue extends QueryValue {
  minimum_price: number | null;
  maximum_price: number | null;
  minimum_stock: number | null;
  maximum_stock: number | null;
}

export class ProductInformationFilter implements Filter {
  uuid: string;

  label: string;

  value: ProductInformationValue;

  constructor(
    params?: Partial<
      Pick<ProductInformationFilter, 'uuid' | 'label' | 'value'>
    >,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || ProductInformationValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [
      {
        name: FilterTypeEnum.minimumPrice,
        value: this.value.price.min as number,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.minimumPrice + this.value,
      },
      {
        name: FilterTypeEnum.maximumPrice,
        value: this.value.price.max as number,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.maximumPrice + this.value,
      },
      {
        name: FilterTypeEnum.minimumStock,
        value: this.value.stock.min as number,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.minimumStock + this.value,
      },
      {
        name: FilterTypeEnum.maximumStock,
        value: this.value.stock.max as number,
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.maximumStock + this.value,
      },
    ].filter(({ value }) => typeof value === 'number' && !Number.isNaN(value));
  }

  get component() {
    return ProductInformationFilterComponent;
  }

  get displayFilterComponent() {
    return ProductInformationFilterValue;
  }

  get queryFilterValue(): ProductInformationQueryValue {
    return {
      maximum_stock:
        this.value.stock.max !== null && !Number.isNaN(this.value.stock.max)
          ? this.value.stock.max
          : null,

      maximum_price:
        this.value.price.max !== null && !Number.isNaN(this.value.price.max)
          ? this.value.price.max
          : null,
      minimum_stock:
        this.value.stock.min && !Number.isNaN(this.value.stock.min)
          ? this.value.stock.min
          : null,
      minimum_price:
        this.value.price.min && !Number.isNaN(this.value.price.min)
          ? this.value.price.min
          : null,
    };
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.minimumPrice) {
      this.value.price.min = null;
    } else if (removingFilter.name === FilterTypeEnum.maximumPrice) {
      this.value.price.max = null;
    } else if (removingFilter.name === FilterTypeEnum.maximumStock) {
      this.value.stock.max = null;
    } else if (removingFilter.name === FilterTypeEnum.minimumStock) {
      this.value.stock.min = null;
    }
    return new ProductInformationFilter(this);
  }

  static readFilterFromQuery(): ProductInformationFilter {
    return new ProductInformationFilter({
      value: ProductInformationValue.readFilterFromQuery,
    });
  }

  static readFilterFromSavedFitler(props: any): ProductInformationFilter {
    return new ProductInformationFilter({
      value: ProductInformationValue.readFromSavedFilter(props),
    });
  }
}
