import { transformUrl } from 'product-utils/src/urls/urls';

export interface ProductCategoryRaw {
  id: number | string;
  name: string;
  display_name: string | null;
  is_selected: boolean;
  is_specific: boolean;
  is_configurable: boolean;
  alert_price: number | null;
  min_price: number | null;
  mean_price: number | null;
  categories: Array<ProductCategoryRaw>;
  picture_url: string | null;
}

export class ProductCategory {
  id: number | string;

  name: string;

  displayName: string | null;

  isSelected: boolean;

  isSpecific: boolean;

  isConfigurable: boolean;

  minPrice: number | null;

  alertPrice: number | null;

  meanPrice: number | null;

  pictureUrl: string | null;

  parent: ProductCategory | null;

  depth: number;

  children: Array<ProductCategory> = [];

  childrenMap: Map<number | string, ProductCategory> = null!;

  original: ProductCategory;

  constructor(
    prop: Pick<
      ProductCategory,
      | 'id'
      | 'name'
      | 'displayName'
      | 'isSelected'
      | 'isSpecific'
      | 'isConfigurable'
      | 'minPrice'
      | 'alertPrice'
      | 'meanPrice'
      | 'parent'
      | 'depth'
      | 'original'
      | 'pictureUrl'
    > & { children: Array<ProductCategory> },
  ) {
    this.id = prop.id;
    this.name = prop.name;
    this.displayName = prop.displayName;
    if (prop.displayName === null && prop.isSelected) {
      this.displayName = prop.name;
    }
    this.isSelected =
      prop.name?.toLowerCase() === 'other' ? true : prop.isSelected;
    this.isSpecific = prop.isSpecific;
    this.isConfigurable = prop.isConfigurable;
    this.minPrice = prop.minPrice;
    this.alertPrice = prop.alertPrice;
    this.meanPrice = prop.meanPrice;
    this.parent = prop.parent;
    this.depth = prop.depth;
    this.pictureUrl = transformUrl(prop.pictureUrl ?? '');
    this.children = prop.children ?? [];
    this.childrenMap = new Map(
      this.children.map((category) => [category.id, category]),
    );
    this.original = prop.original;
  }

  selectedKeys() {
    let selectedKeys: Array<number | string> = this.children
      .filter((category) => category.isSelected && !category.indeterminate)
      .map((category) => category.id);

    this.children.forEach((category) => {
      selectedKeys = [...selectedKeys, ...category.selectedKeys()];
    });

    return selectedKeys;
  }

  resetChildren() {
    this.children = [];
    this.childrenMap = new Map();
  }

  get siblings() {
    return (
      this.parent?.children?.filter((sibling) => sibling.id !== this.id) ?? []
    );
  }

  get indeterminate() {
    return (
      (this.children.some((c) => c.isSelected) &&
        this.children.some((c) => !c.isSelected)) ||
      this.children.some((c) => c.indeterminate)
    );
  }

  get isLeaf() {
    return this.children.length === 0;
  }

  get isOther() {
    return this.displayName === 'Other';
  }

  toRawModel(): ProductCategoryRaw {
    return {
      id: this.id,
      name: this.name,
      display_name:
        this.isSelected || this.indeterminate ? this.displayName : null,
      is_selected: this.isSelected || this.indeterminate,
      is_specific: this.isSpecific,
      is_configurable: this.isConfigurable,
      min_price: this.minPrice,
      alert_price: this.alertPrice,
      mean_price: this.meanPrice,
      categories: this.children.map((category) => category.toRawModel()),
      picture_url: this.pictureUrl,
    };
  }

  static path(row: ProductCategory) {
    const path = [row];
    let current = row;
    while (current.parent) {
      path.unshift(current.parent);
      current = current.parent;
    }
    return path;
  }

  static createFromRawModel(
    prop: ProductCategoryRaw,
    parent: ProductCategory | null = null,
    depth = 0,
  ) {
    const current = new ProductCategory({
      id: prop.id,
      name: prop.name,
      displayName: prop.display_name,
      isSelected: prop.is_selected,
      isConfigurable: prop.is_configurable,
      isSpecific: prop.is_specific,
      minPrice: prop.min_price,
      alertPrice: prop.alert_price,
      meanPrice: prop.mean_price,
      parent,
      depth,
      pictureUrl: prop.picture_url,
      original: new ProductCategory({
        id: prop.id,
        name: prop.name,
        displayName: prop.display_name,
        isSelected: prop.is_selected,
        isSpecific: prop.is_specific,
        isConfigurable: prop.is_configurable,
        minPrice: prop.min_price,
        alertPrice: prop.alert_price,
        meanPrice: prop.mean_price,
        parent,
        depth,
        pictureUrl: prop.picture_url,
        original: null!,
        children: [],
      }),
      children: [],
    });
    current.children = (prop.categories ?? [])
      .map((category) =>
        ProductCategory.createFromRawModel(category, current, depth + 1),
      )
      .sort(ProductCategory.sortingComparator);
    current.childrenMap = new Map(
      current.children.map((category) => [category.id, category]),
    );
    return current;
  }

  updateChildrenMap(id: number, productCategory: ProductCategory) {
    this.childrenMap.set(id, productCategory);
    this.children = Array.from(this.childrenMap.values());
  }

  static calculateCategoryName(category: ProductCategory): string {
    if (category.isSelected || category.indeterminate) {
      return category.displayName ?? category.name;
    }
    if (category.parent?.isSelected || category.parent?.indeterminate) {
      return `${ProductCategory.calculateCategoryName(
        category.parent,
      )} - Other`;
    }
    if (category.parent) {
      return category.name;
    }
    return 'Other';
  }

  static sortingComparator(a: ProductCategory, b: ProductCategory) {
    return a.name.localeCompare(b.name);
  }
}
