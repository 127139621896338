import React, { useCallback, useMemo, useState } from 'react';
import { Flex, Form, FormInstance, Modal, Upload } from 'antd';
import Button, {
  ButtonSize,
  // ButtonType,
  ButtonVariant,
} from 'ui/src/components/atoms/Button/Button';
import Typography from 'ui/src/components/atoms/Typography';
// import styled from 'styled-components';
import { PictureUpload } from 'ui/src/components/atoms/PictureUpload';
// import Link from 'product-ui/src/components/atoms/Link/Link';
import { Input } from 'ui/src/components/atoms/Input/Input';
import NaveeIcon from 'ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Select } from 'ui/src/components/atoms/Select/Select';
import orgLogoPlaceholder from 'assets/images/org-logo-placeholder.jpg';
import { useDispatch, useSelector } from 'react-redux';
import ProductMonitor from 'types/network/Http/productMonitor';
import { InputWrapper } from './styled';
import { createOrganisation } from '../../../Navbar/actions';
import { CreateNewOrganizationSuccess } from './CreateNewOrganizationSuccess';
import { AppState } from '../../../../store/storeAccess';

interface SubmitButtonProps {
  form: FormInstance;
  label: string;
  disabled: boolean;
}

const SubmitButton = ({ form, label, disabled }: SubmitButtonProps) => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Button
      style={{ width: '100%' }}
      variant="Secondary"
      size="Small"
      onClick={() => form.submit()}
      label={label}
      disabled={!submittable || disabled}
    />
  );
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const CreateNewOrganizationModal = ({ open, setOpen }: Props) => {
  const createOrganizationState = useSelector(
    (state: AppState) => state.navbar.createOrganisation,
  );
  const organisationStatuses = useSelector(
    (state: AppState) =>
      state.filters_bar.optionsLoadedData.organisationStatuses?.data ?? [],
  );
  console.log(organisationStatuses);
  const currencyList = useSelector(
    (state: AppState) => state.filters_bar.optionsLoadedData.currencies,
  );
  const [refresh, setRefresh] = useState({});
  const [success, setSuccess] = useState(false);
  // const [showUrlInput, setShowUrlInput] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleCancel = () => {
    setOpen(false);
  };

  // const handleUrlUploadClick = () => {
  //   setShowUrlInput((prevState) => !prevState);
  // };

  const handleSubmit = useCallback(
    (values: {
      organizationName: string;
      legalName: string;
      status: string;
      currency: string;
      logo: string;
      organisation_status: string;
    }) => {
      console.log(values);
      dispatch(
        createOrganisation({
          ...values,
          successCb: () => setSuccess(true),
        }),
      );
    },
    [],
  );

  const handleUploadImage = ({
    file: logo,
    onSuccess,
    onError,
  }: {
    file: File;
    onSuccess: (body: any) => void;
    onError: (event: Error, body?: any) => void;
  }) => {
    const formLogo = new FormData();
    formLogo.append('logo', logo);
    ProductMonitor.endpoints.organisation.uploadNewLogo
      .call({
        data: formLogo,
      })
      .then((response) => {
        onSuccess(response.object.logo_url);
      })
      .catch(onError);
  };

  const footerRender = useCallback(
    () => (
      <Flex
        style={{
          height: 80,
          marginTop: '-12px',
          padding: '1.25rem 1.5rem',
          backgroundColor: 'var(--neutral-grey-100)',
          borderBottomRightRadius: '16px',
          borderBottomLeftRadius: '16px',
        }}
        align="center"
        gap="1rem"
      >
        {success ? (
          <Button
            style={{ width: '100%' }}
            buttonType="button"
            size={ButtonSize.Small}
            variant={ButtonVariant.Secondary}
            onClick={() => setOpen(false)}
            label="OK"
          />
        ) : (
          <>
            <Form.Item style={{ flex: 1 }}>
              <Button
                style={{ width: '100%' }}
                buttonType="button"
                key="back"
                size={ButtonSize.Small}
                variant={ButtonVariant.Outline}
                label="CANCEL"
                disabled={createOrganizationState.isLoading}
                onClick={handleCancel}
              />
            </Form.Item>
            <Form.Item shouldUpdate style={{ flex: 1 }}>
              {() => (
                <SubmitButton
                  form={form}
                  disabled={createOrganizationState.isLoading}
                  label="CREATE"
                />
              )}
            </Form.Item>
          </>
        )}
      </Flex>
    ),
    [open, createOrganizationState.isLoading, success, refresh],
  );

  const backgroundImage = useMemo(
    () => `url(${form.getFieldValue('logo') || orgLogoPlaceholder})`,
    [form.getFieldValue('logo'), refresh],
  );

  return (
    <Modal
      destroyOnClose
      closable={false}
      open={open}
      footer={footerRender}
      width={400}
      styles={{
        content: {
          borderRadius: '16px',
          padding: 0,
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ currency: 'EUR' }}
        requiredMark={false}
        disabled={createOrganizationState.isLoading}
        onFinish={handleSubmit}
        validateTrigger="onBlur"
      >
        <Flex vertical gap="1rem" style={{ padding: '1.5rem' }}>
          {success ? (
            <CreateNewOrganizationSuccess />
          ) : (
            <>
              <Typography variant="h7" fontWeight="bold">
                Create New Organization
              </Typography>

              <Flex vertical gap="1.5rem" align="flex-start">
                <Flex vertical gap="0.5rem" style={{ width: '100%' }}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please upload the logo',
                      },
                    ]}
                    style={{ width: 160 }}
                    name="logo"
                  >
                    <PictureUpload loading={false} hasPhoto={false}>
                      <div
                        className="pic-container"
                        style={{
                          backgroundImage,
                        }}
                      />
                      <Upload
                        id="upload-profile-picture"
                        listType="picture"
                        customRequest={handleUploadImage}
                        onChange={(resp) => {
                          form.setFieldValue('logo', resp.file.response);
                          form.validateFields(['logo']);
                          setRefresh({});
                        }}
                        disabled={createOrganizationState.isLoading}
                      >
                        <Button
                          style={{ letterSpacing: 0 }}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          disabled={createOrganizationState.isLoading}
                          label="Upload Logo"
                        />
                      </Upload>
                    </PictureUpload>
                  </Form.Item>
                  {/* <Link
                    variant="small"
                    style={{ padding: '4px 0' }}
                    onClick={handleUrlUploadClick}
                    disabled={createOrganizationState.isLoading}
                  >
                    {showUrlInput
                      ? 'Hide upload logo via URL link'
                      : 'Upload logo via URL link'}
                  </Link>
                  {showUrlInput && (
                    <InputWrapper>
                      <Form.Item
                        name="imageUrl"
                        rules={[
                          {
                            type: 'url',
                            message: 'Please check the url format',
                          },
                        ]}
                        label="IMAGE URL"
                      >
                        <Flex gap="0.5rem">
                          <Input placeholder="http://" />
                          <Form.Item shouldUpdate style={{ flex: 1 }}>
                            {() => (
                              <Button
                                disabled={
                                  !form.getFieldValue('imageUrl') ||
                                  !!form.getFieldError('imageUrl').length
                                }
                                type={ButtonType.IconOnly}
                                variant="Secondary"
                                icon={<NaveeIcon.UploadCloud stroke="black" />}
                              />
                            )}
                          </Form.Item>
                        </Flex>
                      </Form.Item>
                    </InputWrapper>
                  )} */}
                </Flex>
                <Flex vertical gap="1rem" style={{ width: '100%' }}>
                  <InputWrapper>
                    <Form.Item
                      name="organizationName"
                      label="ORGANIZATION NAME"
                      rules={[
                        {
                          required: true,
                          message: 'Please fill the organization name',
                        },
                      ]}
                    >
                      <Input placeholder="Organization name" />
                    </Form.Item>
                    <Form.Item
                      name="legalName"
                      label="LEGAL NAME"
                      rules={[
                        {
                          required: true,
                          message: 'Please fill the legal name',
                        },
                      ]}
                      tooltip={{
                        icon: (
                          <NaveeIcon.InfoIconWithQuestion
                            width={12}
                            height={12}
                          />
                        ),
                        overlayStyle: { width: 193 },
                        overlayInnerStyle: {
                          textAlign: 'center',
                          fontWeight: 500,
                          padding: 10,
                        },
                        title: 'Registered legal name for IP and trademarks',
                      }}
                    >
                      <Input placeholder="Legal name" />
                    </Form.Item>
                    <Form.Item
                      name="organisation_status"
                      label="STATUS"
                      rules={[
                        {
                          required: true,
                          message: 'Please fill the status',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Choose status"
                        options={organisationStatuses}
                      />
                    </Form.Item>
                    <Form.Item
                      name="currency"
                      label="DEFAULT CURRENCY"
                      rules={[
                        {
                          required: true,
                          message: 'Please fill the default currency',
                        },
                      ]}
                      tooltip={{
                        icon: (
                          <NaveeIcon.InfoIconWithQuestion
                            width={12}
                            height={12}
                          />
                        ),
                        overlayStyle: { width: 193 },
                        overlayInnerStyle: {
                          textAlign: 'center',
                          fontWeight: 500,
                          padding: 10,
                        },
                        title: 'Registered legal name for IP and trademarks',
                      }}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          (option.label as string)
                            .toLowerCase()
                            .includes((input || '')?.toLowerCase())
                        }
                        options={(currencyList.data ?? []).map((currency) => ({
                          label: `(${currency.code}) ${currency.label}`,
                          value: currency.code,
                        }))}
                        labelRender={(option) => option.label}
                      />
                    </Form.Item>
                  </InputWrapper>
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
      </Form>
    </Modal>
  );
};
