import React, { useCallback, useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as Domain from 'product-types/src/domain/Domain';
import { DatePicker, Flex } from 'antd';
import Button, {
  ButtonSize,
} from 'product-ui/src/components/atoms/Button/Button';
import { Select } from 'ui/src/components/atoms/Select';
import Typography from 'product-ui/src/components/atoms/Typography';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import { DateFilterValue } from '../../../types/filters/AtomicFiltersImplementation/Date/Value';
import { DateFilter } from '../../../types/filters/AtomicFiltersImplementation/Date/DateFilter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import {
  ctrlKey,
  enterKey,
  useKeyboardInteraction,
} from '../../../hooks/keyboardActions/useKeyboardInteraction';

export interface DateFilterProps extends FilterParams {
  value: DateFilter;
  onChange: (v: Filter) => void;
}

const styles = {
  width: 198,
};
export const NewDateFilter = (props: DateFilterProps) => {
  const [forceClose, updateForceClose] = useState({});
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);

  const formatedStartDate: dayjs.Dayjs = useMemo(
    () =>
      props.value.value.startDate.isValid()
        ? (props.value.value.startDate as any)
        : dayjs(),
    [props.value.value.startDate],
  );

  const formatedEndDate = useMemo(
    () =>
      props.value.value.endDate.isValid()
        ? (props.value.value.endDate as any)
        : dayjs(),
    [props.value.value.endDate],
  );

  const handleIntervalChange = useCallback(
    (_, interval) => {
      props.onChange(
        new DateFilter({
          ...props.value,
          value: new DateFilterValue({
            interval,
          }),
        }),
      );
    },
    [props.value, props.onChange],
  );

  const onStartDateChange = useCallback(
    (startDate) => {
      props.onChange(
        new DateFilter({
          ...props.value,
          value: new DateFilterValue({
            ...props.value.value,
            startDate,
          }),
        }),
      );
    },
    [props.onChange, props.value],
  );

  const onEndDateChange = useCallback(
    (endDate) => {
      props.onChange(
        new DateFilter({
          ...props.value,
          value: new DateFilterValue({
            ...props.value.value,
            endDate,
          }),
        }),
      );
    },
    [props.onChange, props.value],
  );

  const applyFilters = useCallback(() => {
    updateForceClose(() => ({}));
    context.applyFilters?.();
  }, [context.applyFilters]);

  useKeyboardInteraction({
    observable: [context.applyFilters],
    subscriptions: [
      {
        conditions: [ctrlKey, enterKey],
        callback: () => {
          if (context.applyFilters) {
            updateForceClose(() => ({}));
          }
        },
      },
    ],
  });

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          width: 244,
          padding: '1.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <Select
          dataTestId="crawling-duration"
          listHeight={350}
          value={props.value.value.interval}
          options={Domain.Date.DateFilterOptions}
          onChange={handleIntervalChange}
        />
        {props.value.value.interval.value ===
          Domain.Date.DateFilterValueEnum.exactDates && (
          <Flex vertical align="stretch" gap="0.5rem">
            <Typography variant="medium" fontWeight="medium">
              Exact Dates
            </Typography>
            <DatePicker
              onChange={onStartDateChange}
              placeholder="Start Date"
              disabledDate={(current) =>
                current.isAfter(formatedEndDate.endOf('day'))
              }
              allowClear={false}
              value={formatedStartDate}
              format="YYYY-MM-DD"
              style={{
                padding: '7px 11px',
                ...styles,
              }}
            />
            <DatePicker
              onChange={onEndDateChange}
              placeholder="End Date"
              value={formatedEndDate}
              disabledDate={(current) =>
                current.isBefore(formatedStartDate.startOf('day'))
              }
              allowClear={false}
              format="YYYY-MM-DD"
              style={{
                padding: '7px 11px',
                ...styles,
              }}
            />
          </Flex>
        )}
        {context.applyFilters && context.showApplyButton ? (
          <Button
            style={{
              alignSelf: 'flex-start',
            }}
            onClick={applyFilters}
            size={ButtonSize.Small}
            label="Apply"
          />
        ) : null}
      </Flex>
    ),
    [
      props.value.value.interval,
      formatedStartDate,
      formatedEndDate,
      context.applyFilters,
      context.showApplyButton,
    ],
  );

  return (
    <FilterWithMenuWrapper
      text="Date"
      dataTestId="crawling"
      count={1}
      forceClose={forceClose}
      renderer={renderer}
    />
  );
};
