const bucketUrlToTransform =
  'https://s3-eu-west-1.amazonaws.com/counterfeit-platform-post-images/';
const bucketUrl = 'https://dd6gwn8wk279r.cloudfront.net/';
export const transformUrl = (url: string) =>
  url.replace(bucketUrlToTransform, bucketUrl);

export function getDomainFromUrl(url) {
  try {
    const domain = new URL(url).hostname;
    return domain;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}
