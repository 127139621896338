import dayjs from 'dayjs';
import {
  DateFilterOption,
  DateFilterValueEnum,
  GetDateFilterOptionByValue,
} from 'product-types/src/domain/date/Date';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export class DateFilterValue implements FilterValue {
  startDate: dayjs.Dayjs;

  endDate: dayjs.Dayjs;

  interval: DateFilterOption;

  static DefaultDate: DateFilterValueEnum = DateFilterValueEnum.untilToday;

  constructor(
    params: Partial<
      Pick<DateFilterValue, 'endDate' | 'interval' | 'startDate'>
    >,
  ) {
    this.startDate = params?.startDate || dayjs();
    this.endDate = params?.endDate || dayjs();
    this.interval =
      params?.interval ||
      (GetDateFilterOptionByValue(
        DateFilterValue.DefaultDate,
      ) as DateFilterOption);
  }

  static setDefaultDate(date: DateFilterValueEnum) {
    DateFilterValue.DefaultDate = date;
  }

  static get defaultValue(): DateFilterValue {
    return new DateFilterValue({
      startDate: dayjs(),
      endDate: dayjs(),
      interval: GetDateFilterOptionByValue(DateFilterValue.DefaultDate),
    });
  }

  static readFromSavedFilter(props: any): DateFilterValue {
    const intervalQuery = props.interval;
    const startDate = props.start_date;
    const endDate = props.end_date;
    const interval = GetDateFilterOptionByValue(intervalQuery);
    if (interval) {
      return new DateFilterValue({
        startDate: dayjs(startDate),
        endDate: dayjs(endDate),
        interval,
      });
    }
    return new DateFilterValue({
      startDate: dayjs(),
      endDate: dayjs(),
      interval: GetDateFilterOptionByValue(DateFilterValue.DefaultDate),
    });
  }

  static get readFilterFromQuery(): DateFilterValue {
    const intervalQuery = new URLSearchParams(window.location.search).get(
      'interval',
    );
    const startDate = new URLSearchParams(window.location.search).get(
      'start_date',
    );
    const endDate = new URLSearchParams(window.location.search).get('end_date');
    const interval = GetDateFilterOptionByValue(intervalQuery);
    if (interval) {
      return new DateFilterValue({
        startDate:
          interval.value === DateFilterValueEnum.exactDates
            ? dayjs(startDate)
            : dayjs(),
        endDate:
          interval.value === DateFilterValueEnum.exactDates
            ? dayjs(endDate)
            : dayjs(),
        interval,
      });
    }
    return new DateFilterValue({
      startDate: dayjs(),
      endDate: dayjs(),
      interval: GetDateFilterOptionByValue(DateFilterValue.DefaultDate),
    });
  }
}
