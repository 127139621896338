/*
 *
 * UploadHistory reducer
 *
 */
import { produce } from 'immer';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { UploadHistoryResponseModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { RESET_STATE, LOAD_UPLOAD_HISTORY_DATA_SUCCESS } from './constants';

// todo: refactor this state. use FetchableData here.
export interface UploadHistoryPageState {
  uploadHistory: FetchableData<UploadHistoryResponseModel>;
}

export const createInitialState = (): UploadHistoryPageState => ({
  uploadHistory:
    FetchableData.default as FetchableData<UploadHistoryResponseModel>,
});

/* eslint-disable default-case, no-param-reassign */
const uploadHistoryPageReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case LOAD_UPLOAD_HISTORY_DATA_SUCCESS:
        draft.uploadHistory = action.uploadHistory;
        break;
    }
  });

export default uploadHistoryPageReducer;
