import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ProductCategoryRaw } from 'types/src/domain/productCategory';

export interface UpdateProductCategoriesRequest extends EndpointRequests {
  data: {
    ProductCategories: Array<ProductCategoryRaw>;
  };
}

export interface UpdateProductCategoriesResponse {
  message: string;
}

const updateProductCategories = new Endpoint<
  UpdateProductCategoriesRequest,
  UpdateProductCategoriesResponse
>({
  url: '/api/me/categories/product',
  method: 'POST',
  headers: {},
});

export default updateProductCategories;
