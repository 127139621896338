import { MeRaw } from 'product-types/src/domain/me/me';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UpdateUserRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
  data: {
    name: string;
    role_id: number;
  };
}

export interface UpdateUserResponse {
  message: string;
  object: MeRaw;
}

const updateUser = new Endpoint<UpdateUserRequestModel, UpdateUserResponse>({
  url: '/api/users/:id',
  method: 'PATCH',
  headers: {},
});

export default updateUser;
