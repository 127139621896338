export const RESET_STATE = 'app/containers/Uploads/Post/RESET_STATE';
export const SEARCH_IMAGE_BY_URL =
  'app/containers/Uploads/Post/SEARCH_IMAGE_BY_URL';
export const UPDATE_LOADING_POST =
  'app/containers/Uploads/Post/UPDATE_LOADING_POST';
export const UPDATE_POST_LIST = 'app/containers/Uploads/Post/UPDATE_POST_LIST';
export const UPDATE_POSTS_TAGS_LIST =
  'app/containers/Uploads/Post/UPDATE_POSTS_TAGS_LIST';
export const UPLOAD_POSTS_TO_NAVEE =
  'app/containers/Uploads/Post/UPLOAD_POSTS_TO_NAVEE';
export const UPDATE_MANUAL_UPLOAD =
  'app/containers/Uploads/Post/UPDATE_MANUAL_UPLOAD';
export const UPLOAD_IMAGES_TO_S3_AND_ATTACH_TO_POST =
  'app/containers/Uploads/Post/UPLOAD_IMAGES_TO_S3_AND_ATTACH_TO_POST';
export const UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_POST =
  'app/containers/Uploads/Post/UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_POST';
export const UPLOAD_POST_XLSX = 'app/containers/Uploads/Post/UPLOAD_POST_XLSX';
export const UPDATE_EXISTING_ITEMS_POSTS =
  'app/containers/Uploads/Post/UPDATE_EXISTING_ITEMS_POSTS';
export const UPDATE_GLOBAL_POST_LABEL =
  'app/containers/Uploads/Post/UPDATE_GLOBAL_POST_LABEL';
