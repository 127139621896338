import {
  SELECT_ALL_TRACKED_WEBSITES,
  SELECT_ALL_UNTRACKED_WEBSITES,
} from 'store/constants';
import * as Domain from 'product-types/src/domain/Domain';
import dayjs from 'dayjs';
import { UploadHistoryQueryValue } from 'types/filters/MoleculesFilterImplementation/UploadHistoryFiltersModel';

export function getRequestParameter(action: UploadHistoryQueryValue) {
  const websiteValuesToExclude = [
    SELECT_ALL_TRACKED_WEBSITES,
    SELECT_ALL_UNTRACKED_WEBSITES,
  ];

  const includeTracked =
    (action.website_id || []).filter(
      (value) => value === SELECT_ALL_TRACKED_WEBSITES,
    ).length === 1;

  const includeUntracked =
    (action.website_id || []).filter(
      (value) => value === SELECT_ALL_UNTRACKED_WEBSITES,
    ).length === 1;

  const website_id = (
    (action.website_id || []) as Domain.Website.WebsiteSuggestion[]
  )
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes((value.id || '').toString()),
    )
    .map((val) => val.id)
    .filter((value: any) => value !== 'NaN');

  const website_id_to_exclude = (
    (action.website_id_to_exclude || []) as Domain.Website.WebsiteSuggestion[]
  )
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes((value.id || '').toString()),
    )
    .map((val) => val.id);

  const website_category_id = (action.website_category_id || []).map(
    (val) => val.id,
  );
  const website_category_id_to_exclude = (
    action.website_category_id_to_exclude || []
  ).map((val) => val.id);

  const obj = {
    perpage: action.perpage ?? 10,
    offset: action.offset || 0,
    start_date:
      action.start_date && dayjs(action.start_date).isValid()
        ? dayjs(action.start_date).format('YYYY-MM-DD')
        : null,
    end_date:
      action.end_date && dayjs(action.end_date).isValid()
        ? dayjs(action.end_date).format('YYYY-MM-DD')
        : null,
    timezone_offset: new Date().getTimezoneOffset(),
    interval: action.interval,
    sort_by: action.sort_by || 'upload_date',
    reverse: action.reverse,
    website_id,
    post_id: action.post_id,
    account_website_id: action.account_website_id,
    account_poster_id: action.account_poster_id,
    website_id_to_exclude,
    cluster_id: action.cluster_id,
    post_url: action.post_url,
    vendor_names: action.vendor_names,
    domain_names: action.domain_names,
    poster_names: action.poster_names,
    poster_urls: action.poster_urls,
    keywords_to_include: action.keywords_to_include,
    keywords_to_exclude: action.keywords_to_exclude,
    comments_to_include: action.comments_to_include,
    contact_info_to_include: action.contact_info_to_include,
    comments_to_exclude: action.comments_to_exclude,
    duplicated_group_id: action.duplicated_group_id,
    website_category_id,
    website_category_id_to_exclude,
    upload_tags_to_include: (
      (action.upload_tags_to_include || []) as Domain.Tag.Tag[]
    ).map(({ id }) => id),
    upload_status: (action.upload_status || []).map(({ value }) => value),
    upload_type: action.upload_type ? action.upload_type.split(',') : [],
  };
  if (includeTracked) {
    obj.all_tracked_websites = 'true';
  }
  if (includeUntracked) {
    obj.all_untracked_websites = 'true';
  }
  return obj;
}
