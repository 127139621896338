/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Flex, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as Domain from 'product-types/src/domain/Domain';
import Button, {
  ButtonVariant,
} from 'product-ui/src/components/atoms/Button/Button';
import { Input } from 'product-ui/src/components/atoms/Input/Input';
import { WebsiteSuggestion } from 'types/src/domain/website/Website';
import { AppState } from 'store/storeAccess';
import {
  deleteFiltersAction,
  saveFilterAction,
  replaceFeedFilter,
  appendNonTrackedWebsites,
} from 'layout/FiltersBar/actions';
import { removeEmptyProperties } from 'product-utils/src/object';
import Typography from 'product-ui/src/components/atoms/Typography';
import { SavedFilter } from '../../../types/filters/AtomicFiltersImplementation';
import Network from '../../../types/network';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import { FilterParams } from '../../../types/filters/AtomicFilters/Filter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';

import { StyledModalFilters } from '../../Modals/styled-components';
import ProductMonitor from '../../../types/network/Http/productMonitor';
import { StyledSelect } from './styled';

export interface SavedFiltersProps extends FilterParams {
  value: SavedFilter;
  setSavedFilterOpen: (open: boolean) => void;
  savedFilterOpen: boolean;
}

const SuggestionPortal = (props: SavedFiltersProps) => {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const currentUser = useSelector(
    (state: AppState) => state.loginPage.currentUser,
  );
  const feed = useSelector((state: AppState) => state.filters_bar?.feed);
  const clusters = useSelector(
    (state: AppState) => state.filters_bar?.clusters,
  );
  const [search, setSearch] = useState('');
  const {
    availableLabels,
    availableImageLabels,
    accountLabels,
    availableCategories,
    availableContactTypes,
    availableWebsiteCategories,
    featureLabels,
    users,
    insightOptions,
    geographySelectOptions,
    tags,
    websites,
  } = useSelector((state: AppState) => state.filters_bar?.optionsLoadedData);
  const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState('');
  const filters = context.savedFilters;
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
  const [saveFiltername, setSaveFiltername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const current_screen = url.pathname.split('/')[2];
  const screenName = current_screen === 'website' ? 'account' : current_screen;

  const fetchNonTrackedWebsites = useCallback(
    async (websiteIds: Array<string>) => {
      const nonTrackedWebsiteIds = websiteIds.filter(
        (websiteId) =>
          !(websites.data ?? []).some(({ id }) => id === websiteId),
      );
      let nonTrackedWebsites: Array<WebsiteSuggestion> = [];
      if (nonTrackedWebsiteIds.length) {
        try {
          const { results } =
            await ProductMonitor.endpoints.me.websiteNames.call({
              params: { ids: nonTrackedWebsiteIds },
            });
          nonTrackedWebsites = results.map((website) => ({
            label: website.label,
            id: website.value as string,
          }));
          dispatch(appendNonTrackedWebsites({ websites: nonTrackedWebsites }));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error loading non tracked websites', e);
        }
      }
      return nonTrackedWebsites;
    },
    [websites],
  );

  const handleChangeFilter = useCallback(
    async (value, newVal) => {
      if (!props.value.filters?.restoreFromSavedFilters) {
        throw new Error(
          `restoreFromSavedFilters is not provided for ${props.value.filters}`,
        );
      }

      const filter = filters?.find((f) => f.id === newVal.value);
      if (!filter) {
        console.error('Filter with index', newVal, 'not found');
        throw new Error(`Filter with index ${newVal.value} not found`);
      }

      const nonTrackedWebsites = await fetchNonTrackedWebsites(
        filter.website_id ?? [],
      );

      dispatch(
        replaceFeedFilter({
          feed: props.value.filters.restoreFromSavedFilters(filter, {
            availableWebsiteCategories: availableWebsiteCategories.data ?? [],
            reasons: featureLabels.data || [],
            websites: [...(websites.data ?? []), ...nonTrackedWebsites],
            users: users.data ?? [],
            geos: geographySelectOptions.data ?? {
              countries: [],
              zones: [],
            },
            contactTypes: availableContactTypes.data ?? [],
            insightOptions: insightOptions.data ?? [],
            availableCategories: availableCategories.data ?? [],
            labels: {
              account: accountLabels.data ?? [],
              post: availableLabels.data ?? [],
              image: availableImageLabels.data ?? [],
            },
            tags: {
              account: tags[Domain.Tag.TagTypeEnum.account].data ?? [],
              post: tags[Domain.Tag.TagTypeEnum.post].data ?? [],
              duplicatedGroup:
                tags[Domain.Tag.TagTypeEnum.duplicatedGroup].data ?? [],
              vendor: tags[Domain.Tag.TagTypeEnum.vendor].data ?? [],
            },
          }),
        }),
      );
    },
    [
      props.value,
      filters,
      fetchNonTrackedWebsites,
      availableWebsiteCategories,
      featureLabels,
      websites,
      users,
      geographySelectOptions,
      insightOptions,
      availableCategories,
      accountLabels,
      availableLabels,
      availableImageLabels,
      tags,
    ],
  );

  const deleteFilterHandler = useCallback(() => {
    dispatch(deleteFiltersAction({ name: filterToDelete }, screenName));
    setShowDeleteFilterModal(false);
    props.setSavedFilterOpen(false);
  }, [filterToDelete, screenName]);

  const saveFilterHandler = useCallback(() => {
    let requestParameters = {};
    switch (current_screen) {
      case 'post':
        requestParameters = Network.Post.getRequestParameter({
          ...feed.queryParams,
          followers_count: [
            feed.queryParams.minimum_followers_count,
            feed.queryParams.maximum_followers_count,
          ],
        });
        break;
      case 'image':
        requestParameters = Network.Feed.getRequestParameter({
          ...feed.queryParams,
          followers_count: [
            feed.queryParams.minimum_followers_count,
            feed.queryParams.maximum_followers_count,
          ],
        });
        break;
      case 'account':
      case 'website':
        requestParameters = Network.Account.getRequestParameter({
          ...feed.queryParams,
          followers_count: [
            feed.queryParams.minimum_followers_count,
            feed.queryParams.maximum_followers_count,
          ],
          isAccountView: current_screen === 'account',
        });
        break;
      case 'cluster':
        requestParameters = Network.Vendors.getRequestParameter({
          ...clusters.queryParams,
        });
        break;
      default:
        throw new Error('unknown page name');
    }
    dispatch(
      saveFilterAction(
        {
          name: saveFiltername,
          ...removeEmptyProperties(requestParameters),
          filter_type: screenName,
        },
        setErrorMessage,
        setShowSaveFilterModal,
        screenName,
      ),
    );
  }, [current_screen, feed, screenName, saveFiltername, clusters]);

  const options = [
    ...(filters ?? [])
      .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      .map((item) => ({
        value: item.id,
        label: (
          <Tooltip title={item.name} trigger="hover">
            <Flex
              justify="space-between"
              gap="0.25rem"
              style={{
                position: 'relative',
              }}
            >
              <Typography
                variant="small"
                fontWeight="regular"
                style={{
                  flexShrink: 10,
                  maxWidth: 'calc(100% - 1rem - 0.25rem )',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {item.name}
              </Typography>
              {currentUser?.data?.role?.canModifyFilters && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                <div
                  role="link"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setFilterToDelete(item.name);
                    setShowDeleteFilterModal(true);
                    return false;
                  }}
                  style={{
                    flexShrink: 0,
                  }}
                >
                  X
                </div>
              )}
            </Flex>
          </Tooltip>
        ),
      })),
  ];

  const handleChangeDropdownVisible = useCallback(
    (open) => {
      props.setSavedFilterOpen(
        open || showSaveFilterModal || showDeleteFilterModal,
      );
    },
    [props, showSaveFilterModal, showDeleteFilterModal],
  );

  useEffect(() => {
    if (!props.savedFilterOpen) {
      props.setSavedFilterOpen(showSaveFilterModal || showDeleteFilterModal);
    }
  }, [showSaveFilterModal, showDeleteFilterModal]);

  return (
    <div
      id="suggestionPortal"
      style={{
        width: 244,
        minHeight: 80,
      }}
    >
      <Flex vertical gap="0.5rem" align="stretch">
        <StyledSelect
          showSearch
          onSearch={setSearch}
          searchValue={search}
          filterOption={false}
          options={options}
          onDropdownVisibleChange={handleChangeDropdownVisible}
          style={{ height: 48 }}
          size="large"
          placeholder="Select..."
          value={null}
          onChange={handleChangeFilter}
        />
        {currentUser?.data?.role?.canModifyFilters && (
          <Button
            label="Save This Filter"
            onClick={() => {
              setShowSaveFilterModal(true);
            }}
          />
        )}
        <StyledModalFilters
          title="Are you sure you want to save this filter configuration?"
          open={showSaveFilterModal}
          destroyOnClose
          wrapProps={{
            style: {
              zIndex: 1150,
            },
          }}
          styles={{
            mask: {
              zIndex: 1100,
            },
          }}
          onCancel={() => {
            setShowSaveFilterModal(false);
          }}
          footer={
            <Flex justify="flex-end" gap="0.5em">
              <Button
                size="Small"
                label="Cancel"
                variant={ButtonVariant.Outline}
                onClick={() => {
                  setShowSaveFilterModal(false);
                }}
              />
              <Button
                label="Save"
                size="Small"
                variant={ButtonVariant.Primary}
                disabled={saveFiltername === ''}
                onClick={saveFilterHandler}
              />
            </Flex>
          }
        >
          <Flex vertical align="stretch" gap="0.25rem">
            <label htmlFor="filterName">
              <Typography
                variant="xsmall"
                color="var(--neutral-70)"
                textTransform="uppercase"
              >
                Filter name
              </Typography>
            </label>
            <Input
              id="filterName"
              placeholder="Please enter filter configuration name here"
              value={saveFiltername}
              onChange={(e) => {
                setSaveFiltername(e.target.value);
              }}
            />
            {errorMessage && (
              <Alert
                style={{ marginTop: 10, textAlign: 'center' }}
                message={errorMessage}
                type="error"
              />
            )}
          </Flex>
        </StyledModalFilters>
        <StyledModalFilters
          title="Are you sure want to permanently delete this filter configuration?"
          open={showDeleteFilterModal}
          destroyOnClose
          wrapProps={{
            style: {
              zIndex: 1150,
            },
          }}
          styles={{
            mask: {
              zIndex: 1100,
            },
          }}
          onCancel={() => {
            setShowDeleteFilterModal(false);
          }}
          footer={
            <Flex justify="flex-end" gap="0.5em">
              <Button
                label="Cancel"
                variant={ButtonVariant.Outline}
                style={{ flex: 1 }}
                onClick={() => {
                  setShowDeleteFilterModal(false);
                }}
              />
              <Button
                label="Delete"
                variant={ButtonVariant.Primary}
                style={{ flex: 1 }}
                onClick={deleteFilterHandler}
              />
            </Flex>
          }
        >
          <div>
            <div
              style={{
                minHeight: 60,
                borderRadius: 7,
                border: 'solid 2px #ebebf2',
                fontSize: 19,
                fontWeight: 300,
                color: 'var(--dusk)',
                padding: 19,
                textAlign: 'center',
              }}
            >
              {filterToDelete}
            </div>
          </div>
        </StyledModalFilters>
      </Flex>
    </div>
  );
};

function SavedFiltersFilter(props: SavedFiltersProps) {
  const [savedFilterOpen, setSavedFilterOpen] = useState(false);

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          padding: '1.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <SuggestionPortal
          {...props}
          setSavedFilterOpen={setSavedFilterOpen}
          savedFilterOpen={savedFilterOpen}
        />
      </Flex>
    ),
    [props.value, props.onChange],
  );
  return (
    <FilterWithMenuWrapper
      text="Saved Filters"
      renderer={renderer}
      preventClosing={savedFilterOpen}
    />
  );
}

export default SavedFiltersFilter;
